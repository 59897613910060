import './BottomPane.scss';

import React, { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { LogsPane } from './bottom_pane/LogsPane'
import { bottomPaneTabAtom, loggedInUserAtom, openModalAtom, selectedEmptyPaneAtom, userLiveDocAtom } from '../../types/global_types'
import { CodeConsole } from '../strategies/CodeConsole';

export const BottomPane = () => {
  const [user ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [bottomPaneTab, setBottomPaneTab] = useAtom(bottomPaneTabAtom)

  if (!user) return null

  return (
    <div className='bottom-pane-outer'>
      <div className='handle' />
      <div className='bottom-pane-container'>
        <div className='bottom-pane-header'>
          <div className='row'>
            <div 
              className={bottomPaneTab === 'logs' ? 'header-tab selected' : 'header-tab'}
              onClick={() => setBottomPaneTab('logs')}
            >
              Logs
            </div>
            <div 
              className={bottomPaneTab === 'console' ? 'header-tab selected' : 'header-tab'}
              onClick={() => setBottomPaneTab('console')}
            >
              Console output
            </div>
          </div>
        </div>
        {bottomPaneTab === 'logs' ? <LogsPane /> : <CodeConsole />}
      </div>
      
    </div>
  )
}

