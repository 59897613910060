/*
INPUT

    - type: string
      - text
      - range
      - date
      - datetime-local
      - email
      - password
    - label: string
    - placeholder: string
    - value: string
    - initialValue: string
    - onBlur: (value) => void
    - isError: function, takes value, returns true if error on blur. Corrects on keydown.


*/


import React from 'react'
import './Input.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

export class Input extends React.Component {
  constructor(props) {
    super(props)
    this.className = this.props.className
    this.type = this.props.type || 'text'
    this.label = this.props.label
    this.placeholder = this.props.placeholder
    this.initialValue = this.props.initialValue
    this.onChange = this.props.onChange
    this.onBlur = this.props.onBlur
    this.isError = this.props.isError
    this.hoverText = this.props.hoverText
    this.autoFocus = this.props.autoFocus
    this.maxLength = this.props.maxLength
    this.variant = this.props.variant
    this.noLabel = this.props.noLabel
    this.max = this.props.max
    this.min = this.props.min
    this.maxDate = this.props.maxDate
    this.minDate = this.props.minDate

    this.inputRef = React.createRef()
    this.style = this.props.style
  }

  state = {
    errorMessage: '',
    value: ''
  }

  componentDidMount() {
    this.setState({ value: this.props.initialValue })
    if (this.type === 'date') {
      this.setDateRestrictions()
    }

    // Auto-focus the input element if the autoFocus prop is set to true
    if (this.autoFocus) {
      this.inputRef.current.focus()
    }
  }

  setDateRestrictions() {
    if (this.maxDate) {
      this.inputRef.current.max = this.formatDate(this.maxDate)
    }
    if (this.minDate) {
      this.inputRef.current.min = this.formatDate(this.minDate)
    }
  }

  formatDate(date) {
    if (date instanceof Date) {
      return date.toISOString().split('T')[0]
    }
    return date // Assume it's already in YYYY-MM-DD format if it's not a Date object
  }

  handleChange = (e) => {
    let newValue = e.target.value

    // If the input type is 'date', limit the input to 10 characters (YYYY-MM-DD)
    if (this.type === 'date') {
      if (newValue.length > 10) {
        newValue = newValue.slice(0, 10)
      }
      // Optional: You can add more validation for the date format here
    }

    if (this.onChange) {
      const updatedValue = this.onChange(newValue)
      if (typeof updatedValue === 'string') {
        this.setState({ value: updatedValue }, () => {
          if (this.state.errorMessage) {
            this.checkError()
          }
        })
        return
      }
    }

    this.setState({ value: newValue }, () => {
      if (this.state.errorMessage) {
        this.checkError()
      }
    })
  }

  handleBlur = (e) => {
    if (this.onBlur) {
      this.onBlur(e.target.value)
    }
    if (this.isError) {
      this.checkError()
    }
  }

  render() {
    let classList = 'input-component'
    if (this.className) {
      classList += ' ' + this.className
    }

    return (
      <div className={classList} style={this.style}>
        {this.getLabel()}
        <input
          type={this.type}
          className={''}
          placeholder={this.placeholder}
          value={this.state.value}
          ref={this.inputRef}
          spellCheck={false}
          autoFocus={this.autoFocus}
          maxLength={this.type !== 'date' ? this.maxLength : undefined} // Remove maxLength for date
          max={this.max}
          min={this.min}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />
        {this.state.errorMessage ? (
          <div className='error-message'>
            {this.state.errorMessage}
          </div>
        ) : null}
      </div>
    )
  }

  clear() {
    this.setState({ value: '' })
  }

  checkError() {
    const errorMessage = this.isError(this.inputRef.current.value)
    this.setState({ errorMessage })
    this.inputRef.current.classList.remove('error')
    if (errorMessage) {
      this.inputRef.current.classList.add('error')
    }
  }

  getLabel() {
    let label
    let infoIcon
    if (this.label) {
      label = (
        <div className={'label ' + (this.variant || '')}>{this.label}</div>
      )
      if (this.hoverText) {
        infoIcon = (
          <FontAwesomeIcon
            className={'info-btn'}
            icon={faInfoCircle}
            title={this.hoverText}
          />
        )
      }
    }

    let labelStyle
    if (this.noLabel) {
      labelStyle = { display: 'none' }
    }

    return (
      <div className={'label-row'} style={labelStyle}>
        {label}
        {infoIcon}
      </div>
    )
  }
}
