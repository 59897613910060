import axios  from 'axios'
import { useAtom } from 'jotai'
import {
  Input,
  PhoneInput,
  Button,
  showModal,
} from '../reusable'
import { currentUser } from '../../firebase'
import React, { useState, useEffect } from 'react'
import { getConfig } from '../../config'
import { loggedInUserAtom, openModalAtom } from '../../types/global_types'
import { ErrorModal } from '../modals/ErrorModal'
import { CreatePlaidLinkButtonModal } from '../modals/CreatePlaidLinkButtonModal'
import { DeleteAchRelationshipModal } from '../modals/DeleteAchRelationshipModal'
import { TrustedContactModal } from '../modals/TrustedContactModal'
import { BACKGROUND_HOVER, BACKGROUND_HOVER_ALT, ORANGE } from '../../logic/u'

const config = getConfig() as any

export function SecurityTab() {
  const [loggedInUser] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)

  if (!loggedInUser) { return null }

  return (
    <div className='tab-contents has-subtab'>

      {/* Basic info */}
      <div className='bold header-text-size margin-bottom-5 margin-top-5'>Security</div>
      {/* Trusted contact */}
      <div className='generic-content-container'>
        <div className='generic-title'>Trusted contact</div>
        <div className='generic-content-column wider'>
          <div className='generic-content-item'>
            <div>first_name:</div>
            <div>{loggedInUser?.alpacaObject?.trusted_contact?.given_name || ''}</div>
          </div>
          <div className='generic-content-item'>
            <div>last_name:</div>
            <div>{loggedInUser?.alpacaObject?.trusted_contact?.family_name || ''}</div>
          </div>
          <div className='generic-content-item'>
            <div>email_address:</div>
            <div>{loggedInUser?.alpacaObject?.trusted_contact?.email_address || ''}</div>
          </div>
          <div className='generic-content-item'>
            <div>phone_number:</div>
            <div>{loggedInUser?.alpacaObject?.trusted_contact?.phone_number?.replace(/\+(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4') || ''}</div>
          </div>
          <div
            style={{marginTop: 10, width: 90}}  
          >
            <Button
              display='Change'
              style={{                
                width: '100%',
                // backgroundColor: ORANGE,
                // color: BACKGROUND_HOVER,
              }}
              onClick={(e) => {
                setOpenModal(<TrustedContactModal />)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}