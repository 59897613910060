import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import axios from 'axios';
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
} from '../../types/global_types';
import { getConfig } from '../../config';
import { TickerModalActionBtns } from './ticker_modal_components/TickerModalActionBtns';
import { TickerModalMarketSummary } from './ticker_modal_components/TickerModalMarketSummary';
import { TickerModalPositionSummary } from './ticker_modal_components/TickerModalPositionSummary';
import { TickerModalWatchlistsSummary } from './ticker_modal_components/TickerModalWatchlistsSummary';
import { Minichart } from '../minichart/Minichart';
import { OrdersTable } from '../tables/OrdersTable';
import { AddTickersModal } from './AddTickersModal';
import { Icon } from '../reusable';
import { currentUser } from '../../firebase';
import { TEXT_GRAY_LIGHT } from '../../logic/u';

const config = getConfig() as any;

interface TickerModalProps {
  symbol: string;
  orderId?: string;
  goBackToAddTickersModal?: boolean;
}

export const TickerModal = (props: TickerModalProps) => {
  const [user] = useAtom(loggedInUserAtom);
  const [uld] = useAtom(userLiveDocAtom);
  const [, setOpenModal] = useAtom(openModalAtom);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [symbolData, setSymbolData] = useState<any>(null);

  const { symbol, orderId, goBackToAddTickersModal } = props;

  if (!user || !uld) return null;

  const isPosition = Object.keys(uld?.liveData.POSITIONS).includes(symbol)

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if data is available in uld.liveData
        if (uld.liveData[symbol]) {
          setSymbolData(uld.liveData[symbol]);
        } else {
          // Fetch data from the endpoint
          const token = await currentUser()?.getIdToken();
          const endpoint = `${config.api_root_url}getLiveDataFE?tickers=${symbol}`;
          const response = await axios.get(endpoint, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });

          if (response.data && response.data[symbol]) {
            setSymbolData(response.data[symbol]);
          } else {
            console.error(`Data for symbol ${symbol} not found in response.`);
          }
        }
      } catch (error) {
        console.error('Error fetching symbol data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [symbol, uld]);

  return (
    <>
      <div
        className='modal-scrim'
        onClick={() => {
          setOpenModal(null)
        }}
      />
      <div
        className='modal'
        style={{
          height: 650,
          width: 1000,
          top: 60,
          left: 'calc(50vw - 500px)'
        }}
      >
        <div className='top' style={{height: '100%'}}>
          <div className='title-row'>
            <div className='row'>
              {goBackToAddTickersModal && <Icon
                icon='arrow-left'
                set='sharp-solid'
                size={16}
                style={{marginTop: 2}}
                className='margin-right-10'
                onClick={() => {
                  setOpenModal(<AddTickersModal />)
                }}
              />}
              <div>{symbol}</div>
              <div
                className='row font-size-10'
                style={{
                  marginTop: 6.5,
                  marginLeft: 25,
                  color: TEXT_GRAY_LIGHT
                }}
              >
                <div>
                  {uld.liveData[symbol]?.price} current_price
                </div>
                {isPosition && <>
                  <div style={{marginLeft: 5, marginRight: 5}} >{symbolData?.price > symbolData?.avg_entry_price ? '>' : '<'}</div>
                  <div>
                    {symbolData?.avg_entry_price} avg_entry_price
                  </div>
                </>}
              </div>
            </div>
            <TickerModalActionBtns
              symbol={symbol}
              orderId={orderId}
            />
          </div>
          <div className='modal-body' style={{height: 'calc(100% - 61px)'}}>
            <div className='row width-full' style={{gap: 10, height: '100%'}}>
              {/* <div className='column' style={{flex: 1}}> */}
              <div className='column' style={{width: 710}}>
                <div style={{height: 362}}>
                  <Minichart ticker={symbol} />
                </div>
                <div style={{flex: 1}}>
                  <OrdersTable
                    paginate={false}
                    symbol={symbol}
                    orderId={orderId}
                  />
                </div>
              </div>
              <div className='column' style={{width: 250, height: '100%'}}>
                <div className='white-text bold margin-bottom-10'>Overview</div>
                <div className='column' style={{width: '100%', gap: 10, height: '100%'}}>
                  <TickerModalMarketSummary
                    symbol={symbol}
                    symbolData={symbolData}
                  />
                  <TickerModalPositionSummary
                    symbol={symbol}
                  />
                  <TickerModalWatchlistsSummary
                    symbol={symbol}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}