import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  deploymentsAtom,
  strategiesAtom,
} from '../../types/global_types';
import { getConfig } from '../../config';
import { EELog } from '../../types/user_types';
import { Backtest } from '../../types/backtest_types';

const config = getConfig() as any;

interface ModalCodeTabProps {
  log?: EELog;
  backtest?: Backtest | null;
}

export const ModalCodeTab = (props: ModalCodeTabProps) => {
  const [, setOpenModal] = useAtom(openModalAtom);
  const [user] = useAtom(loggedInUserAtom);
  const [uld] = useAtom(userLiveDocAtom);

  const { log, backtest } = props;

  if (!user || !uld) return null;

  let code = log?._code

  // Backtest: get code from strategy object
  if (backtest) {
    code = props.backtest?.strategyObj?.code
  }

  let func_def_line_numbers: number[] | null = []
  if (log?.function_name) {
    func_def_line_numbers = get_func_def_line_numbers(log.function_name, code || '');
  }

  // NOTE: consider going on code from deployment_id

  let genericContainerWidth = 'calc(100% - 24px)'
  if (backtest) {
    genericContainerWidth = 'calc(100% - 4px'
  }

  if (code) {
    return (
      <div
        className="generic-content-container modal-version"
        style={{
          overflowY: 'scroll',
          margin: '0px !important',
          padding: '0px',
          paddingTop: 10,
          paddingBottom: 10,
          width: genericContainerWidth
        }}
      >
        <SyntaxHighlighter
          language="javascript"
          style={oneDark}
          wrapLines
          showLineNumbers={true}
          lineProps={(lineNumber) => {
            if (func_def_line_numbers?.includes(lineNumber)) {
              return ({style: {
                background: '#FF8F0E33',
                // margin: -5
              }})
            }
            return {}
          }}
        >
          {code}
        </SyntaxHighlighter>
      </div>
    );
  } else {
    return (
      <div className="generic-content-container modal-version" style={{width: genericContainerWidth}}>
        <pre style={{marginTop: 0,}}>N/A</pre>
      </div>
    )
  }
};

const get_func_def_line_numbers = (function_name: string, code: string): number[] | null => {
  const lines = code.split('\n');
  const patterns = [
    new RegExp(`strategy\\.on_trigger\\s*\\(\\s*['"\`]${function_name}['"\`]\\s*,`),
    new RegExp(`strategy\\.on_interval\\s*\\(\\s*['"\`]${function_name}['"\`]\\s*,`),
    new RegExp(`function\\s+${function_name}\\s*\\(`),
    new RegExp(`${function_name}\\s*=\\s*\\(`),
    new RegExp(`${function_name}\\s*=\\s*function\\s*\\(`),
    new RegExp(`${function_name}\\s*=\\s*async\\s*\\(`),
    new RegExp(`${function_name}\\s*=\\s*async\\s*function\\s*\\(`),
    new RegExp(`${function_name}\\s*=\\s*\\(.*\\)\\s*=>`),
  ];

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    for (const pattern of patterns) {
      if (pattern.test(line)) {
        const start_line = i;
        let brace_count = 0;
        let found_opening_brace = false;
        for (let j = i; j < lines.length; j++) {
          const current_line = lines[j];
          // Remove strings and comments to avoid counting braces inside them
          const lineWithoutStringsAndComments = current_line
            .replace(/(["'`]).*?\1/g, '')
            .replace(/\/\*.*?\*\//g, '')
            .replace(/\/\/.*$/, '');

          for (const char of lineWithoutStringsAndComments) {
            if (char === '{') {
              brace_count++;
              found_opening_brace = true;
            } else if (char === '}') {
              brace_count--;
            }
          }

          if (found_opening_brace && brace_count === 0) {
            const end_line = j;
            const line_numbers: number[] = [];
            for (let k = start_line + 1; k <= end_line + 1; k++) {
              line_numbers.push(k);
            }
            return line_numbers;
          }

          // Handle arrow functions without braces
          if (!found_opening_brace && lineWithoutStringsAndComments.includes(';')) {
            const end_line = j;
            const line_numbers: number[] = [];
            for (let k = start_line + 1; k <= end_line + 1; k++) {
              line_numbers.push(k);
            }
            return line_numbers;
          }
        }
        // If we reach here, we didn't find the closing brace
        return null;
      }
    }
  }
  return null;
};
