import React, { useState, useEffect } from 'react'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  ordersAtom,
} from '../../types/global_types'
import { useAtom } from 'jotai'
import { BORDER_INPUT, SECOND_SURFACE, TEXT_GRAY_LIGHT, formatDateForTable, formatNumberForTable, handleMUISorting, tableSX, valueForTable, roundToSigFigs } from '../../logic/u'
import { EELog } from '../../types/user_types'
import { LogModalConsoleOutputTab } from './log_modal_components/LogModalConsoleOutputTab'
import { ModalCodeTab } from '../tabs/ModalCodeTab'
import { PositionsTable } from '../tables/PositionsTable'
import { BalancesTable } from '../tables/BalancesTable'
import { FlatWatchlistTable } from '../tables/FlatWatchListTable'
import { StateTable } from '../tables/StateTable'
import { db } from '../../firebase'
import { Backtest } from '../../types/backtest_types'
import { Icon } from '../reusable'
import { BacktestModal } from './BacktestModal'

const TOP_SUBTABS = [
  {display: 'Console output', value: 'console_output'},
  {display: 'Code', value: 'code'},
]
// TOP_SUBTABS.push({display: 'Code', value: 'code'})

const SUBTABS = [
  {display: 'Positions', value: 'positions'},
  {display: 'Balances', value: 'balances'},
  {display: 'State', value: 'state'},
  {display: 'Watchlists', value: 'watchlists'},
]

interface LogModalProps {
  log: EELog
  backtest?: Backtest
}

export const LogModal = (props: LogModalProps) => {
  const [ user ] = useAtom(loggedInUserAtom)
  const [ uld ] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [ topSubTab, setTopSubTab ] = useState('console_output')
  const [ subTab, setSubTab ] = useState('positions')

  const _log = props.log
  const [ log, setLog ] = useState(_log)


  // If all we have is the cached log, we need to fetch the full log
  useEffect(() => {
    if (props.backtest) return

    if (!log?._liveData) {
      db.collection('users')
        .doc(user?.uid)
        .collection('EELogs')
        .doc(log?.id)
        .get().then((doc) => {
          const fullLog = doc.data() as EELog
          setLog(fullLog)
      })
    }
  }, [log])


  let topSubTabContent: any = null
  switch (topSubTab) {
    case 'console_output':
      topSubTabContent = <LogModalConsoleOutputTab log={log} />
      break
    case 'code':
      topSubTabContent = <ModalCodeTab log={log} backtest={props.backtest} />
      break
  }

  let subTabContent: any = null
  switch (subTab) {
    case 'positions':
      subTabContent = <PositionsTable log={log} />
      break
    case 'balances':
      subTabContent = <BalancesTable log={log} />
      break
    case 'state':
      subTabContent = <StateTable log={log} />
      break
    case 'watchlists':
      subTabContent = <FlatWatchlistTable log={log} />
      break
  }

  if (!user || !uld || !log) return null

  const date = new Date(log.time_iso)

  return (
    <>
      <div
        className='modal-scrim'
        onClick={() => {
          setOpenModal(null)
        }}
      />
      <div
        className='modal'
        style={{
          height: 650,
          width: 1000,
          top: 60,
          left: 'calc(50vw - 500px)'
        }}
      >
        <div className='top' style={{height: '100%'}}>
          <div className='title-row'>
            <div className='row'>
              {props.backtest && <Icon
                icon='arrow-left'
                set='sharp-solid'
                size={16}
                style={{marginTop: 2}}
                className='margin-right-10'
                onClick={() => {
                  if (props.backtest) {
                    setOpenModal(<BacktestModal backtest={props.backtest} />)
                  }
                }}
              />}
              <div>{'Log'}</div>
              <div
                className='row font-size-10'
                style={{
                  marginTop: 6.5,
                  marginLeft: 25,
                  color: TEXT_GRAY_LIGHT
                }}
              >
                <div>
                  {formatDateForTable(date)}
                </div>
              </div>
            </div>
          </div>
          <div className='modal-body' style={{height: 'calc(100% - 61px'}}>
            <div className='row width-full' style={{height: 340, gap: 10}}>
              <div className='row width-full'>
                <div className='column width-full'>
                  <div className='modal-tab-container top-section'>
                    {TOP_SUBTABS.map((s) => {
                      let className = 'modal-tab'
                      if (s.value === topSubTab) {
                        className += ' selected'
                      }
                      return (
                        <div
                          className={className}
                          onClick={() => setTopSubTab(s.value)}
                        >
                          {s.display}
                        </div>
                      )
                    })}
                  </div>
                  {topSubTabContent}
                </div>
              </div>
            </div>
            <div className='column'>
              <div className='modal-tab-container'>
                {SUBTABS.map((s) => {
                  let className = 'modal-tab'
                  if (s.value === subTab) {
                    className += ' selected'
                  }
                  return (
                    <div
                      className={className}
                      onClick={() => setSubTab(s.value)}
                    >
                      {s.display}
                    </div>
                  )
                })}
              </div>
              <div className='column' style={{height: 197}}>
                {subTabContent}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}