import '../RightPane.scss'
import { useAtom } from 'jotai'
import React, { useRef, useState } from 'react';
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  streamingQuotesAtom,
} from '../../../types/global_types'
import {
  formatPercent,
  roundToSigFigs,
  formatNumberForTable,
  returnPaneContextObj,
  valueForTable,
  getMostRecentCloseTime,
  isMarketOpen,
} from '../../../logic/u'
import { DropdownSelect, Icon } from '../../reusable'
import { TickerDropdown } from '../../dropdowns/TickerDropdown';
import { TickerModal } from '../../modals/TickerModal';
import { PositionsPaneSortDropdown } from '../../dropdowns/PositionsPaneSortDropdown';

export const PositionsPane = () => {
  const [, setOpenModal] = useAtom(openModalAtom)
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [ streamingQuotes ] = useAtom(streamingQuotesAtom)
  const [ openDropdown, setOpenDropdown ] = useState<any>(null)
  const [ indicatorState, setIndicatorState ] = useState<number>(0)
  const [ sortKey, setSortKey ] = useState<string>('mkt_value')
  const sortDropdownRef = useRef<HTMLDivElement>(null)

  const getPositionWithLatestPrice = (position) => {
    let price = position.current_price

    // Defaults, calculated on server
    let mkt_value = position.mkt_value
    let unrealized_pl = position.unrealized_pl
    let unrealized_pl_pc = position.unrealized_pl_pc
    let day_unrealized_pl = position.day_unrealized_pl
    let day_unrealized_pl_pc = position.day_unrealized_pl_pc

    if (streamingQuotes[position.symbol]?.price) {

      price = streamingQuotes[position.symbol].price

      // If this data is more than two minutes old, skip it
      const modified_ts = new Date(streamingQuotes[position.symbol].timestamp).getTime()
      const mins_old = (new Date().getTime() - modified_ts) / (1000 * 60)
      if (mins_old <= 2) {

        // If position was opened/added to since last_close_time, we skip live calculation
        let last_close_time = getMostRecentCloseTime()
        let modified_today = false
        uld?.orders.forEach((order) => {
          if (order.symbol === position.symbol) {
            if (order.created_at > last_close_time) {
              // last_close_time = order.created_at
              modified_today = true
            }
          }
        })

        // Calculate unrealized based on streaming price
        if (!modified_today) {
          mkt_value = position.qty * price
          unrealized_pl = mkt_value - (position.qty * position.avg_entry_price)
          unrealized_pl_pc = (unrealized_pl / (position.qty * position.avg_entry_price)) * 100
          const prevClose = position.last_price
          day_unrealized_pl = position.qty * (price - prevClose)
          day_unrealized_pl_pc = (day_unrealized_pl / (position.qty * prevClose)) * 100
        }
      }
    }


    return {
      ...position,
      current_price: price,
      mkt_value,
      unrealized_pl,
      unrealized_pl_pc,
      day_unrealized_pl,
      day_unrealized_pl_pc
    }
  }

  const getIndicatorStateVal = (position: any) => {
    const indicatorStateArr = [
      {
        title: 'day_unrealized_pl',
        value: valueForTable('day_unrealized_pl', position.day_unrealized_pl),
        greenDriver: position.day_unrealized_pl,
      },
      {
        title: 'day_unrealized_pl_pc',
        value: valueForTable('day_unrealized_pl_pc', position.day_unrealized_pl_pc),
        greenDriver: position.day_unrealized_pl,
      },
      {
        title: 'unrealized_pl',
        value: valueForTable('unrealized_pl', position.unrealized_pl),
        greenDriver: position.unrealized_pl,
      },
      {
        title: 'unrealized_pl_pc',
        value: valueForTable('unrealized_pl_pc', position.unrealized_pl_pc),
        greenDriver: position.unrealized_pl,
      },
    ]
    return {...indicatorStateArr[indicatorState], length: indicatorStateArr.length}
  }

  const sortPositions = (a: any, b: any) => {
    const valueA = a[sortKey]
    const valueB = b[sortKey]

    if (valueA < valueB) return 1
    if (valueA > valueB) return -1
    return 0
  }

  if (!user) return null
  if (!uld) return null

  const positions = Object.values(uld.liveData?.POSITIONS || {})
    .map(position => getPositionWithLatestPrice(position))

  if (Object.keys(uld.liveData?.POSITIONS).length) {
    return (
      <div className='sect-body'>
        <div className='sect-body-table-header'>
          <div
            ref={sortDropdownRef}
            className='sect-btn'
            onClick={() => {
              if (sortDropdownRef.current) {
                const rect = sortDropdownRef.current?.getBoundingClientRect()
                setOpenDropdown(
                  <PositionsPaneSortDropdown
                    left={rect.left}
                    top={rect.bottom}
                    onClose={() => setOpenDropdown(null)}
                    sortKey={sortKey}
                    setSortKey={setSortKey}
                    width={200}
                  />
                )
              }
            }}
          >
            <div>sort: </div>
            <Icon
              icon='sort'
              size={7}
              className='sect-icn'
              style={{marginLeft: 3}}
              onClick={() => {
                //
              }}
            />
          </div>
          <div
            className='sect-btn'
            onClick={(e) => {
              e.stopPropagation()
              setIndicatorState((prevState) => {
                const newState = prevState + 1
                if (newState > getIndicatorStateVal({}).length - 1) {
                  return 0
                }
                return newState
              })
            }}
          >
            tag: {getIndicatorStateVal({}).title}
          </div>
        </div>
        {openDropdown}
        {positions.slice().sort((a: any, b: any) => sortPositions(a, b)).map((position: any, i) => {
          const ticker = position.symbol
          const chartIcon = 'square-' + position.symbol.charAt(0)
          const rounded_qty = roundToSigFigs(position.qty, position.avg_entry_price)
          const paneContextObj = returnPaneContextObj(user, ticker)
          const paneThingIsVisible = paneContextObj?.paneThingIsVisible
          let divClass = 'entry'
          if (paneThingIsVisible) {
            divClass += ' selected'
          }

          return (
            <div
              key={i}
              className={divClass}
              onClick={() => {
                setOpenModal(<TickerModal symbol={position.symbol}  />)
              }}
              onContextMenu={(event) => {
                event.preventDefault()
                const width = 220
                const clickX = event.clientX - (width + 5)
                const clickY = event.clientY - 10
                setOpenDropdown(
                  <TickerDropdown
                    symbol={position.symbol}
                    left={clickX}
                    top={clickY}
                    width={width}
                    onClose={() => setOpenDropdown(null)}
                    fromRightPane={true}
                  />
                )
              }}
            >
              <div className='row space-between'>
                <div className='row'>
                  <Icon
                    icon={chartIcon}
                    set='sharp-solid'
                    size={12}
                    className={'key-icon'}
                    style={{marginTop: 2, marginRight: 10}}
                  />
                  <div className='orange-text'>{position.symbol}</div>
                </div>
                <div className='row'>
                  <div style={{marginTop: .75}} className={'orange-text'}>{valueForTable('mkt_value', position.mkt_value)}</div>
                  <div
                    title={getIndicatorStateVal(position).title}
                    className={getIndicatorStateVal(position).greenDriver >= 0 ? ' green-tag' : ' red-tag'}
                    style={{marginLeft: 10, width: 60, textAlign: 'right'}}
                    onClick={(e) => {
                      e.stopPropagation()
                      setIndicatorState((prevState) => {
                        const newState = prevState + 1
                        if (newState > getIndicatorStateVal(position).length - 1) {
                          return 0
                        }
                        return newState
                      })
                    }}
                  >
                    {getIndicatorStateVal(position).value}
                  </div>
                </div>
              </div>
              <div className='row space-between font-size-11'>
                <div className='orange-text'>{rounded_qty} @ {valueForTable('avg_entry_price', position.avg_entry_price)}</div>
                <div className='orange-text'>{valueForTable('price', position.current_price)}</div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
  return (
    <div className='sect-body'>
      <div
        style={{marginTop: 8, marginLeft: 10}}
        className='orange-text font-size-11'
      >
        No positions.
      </div>
    </div>
  )
}