import React, { useState, useEffect } from 'react'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  ordersAtom,
} from '../../types/global_types'
import { useAtom } from 'jotai'
import { BORDER_INPUT, SECOND_SURFACE, TEXT_GRAY_LIGHT, formatDateForTable, formatNumberForTable, handleMUISorting, tableSX, valueForTable, roundToSigFigs } from '../../logic/u'
import { EELog } from '../../types/user_types'
import { LogModalConsoleOutputTab } from './log_modal_components/LogModalConsoleOutputTab'
import { ModalCodeTab } from '../tabs/ModalCodeTab'
import { PositionsTable } from '../tables/PositionsTable'
import { BalancesTable } from '../tables/BalancesTable'
import { FlatWatchlistTable } from '../tables/FlatWatchListTable'
import { StateTable } from '../tables/StateTable'
import { db } from '../../firebase'
import { Backtest } from '../../types/backtest_types'
import { OrdersTable } from '../tables/OrdersTable'
import { TransfersTable } from '../tables/TransfersTable'
import { LogsTable } from '../tables/LogsTable'
import { BacktestMiniChart } from '../minichart/BacktestMiniChart'
import { Icon, ProgressBar } from '../reusable'
import { BacktestDropdown } from '../dropdowns/BacktestDropdown'

const TOP_SUBTABS = [
  {display: 'Charts', value: 'charts'},
  {display: 'Code', value: 'code'},
]
// TOP_SUBTABS.push({display: 'Code', value: 'code'})

const SUBTABS = [
  {display: 'Positions', value: 'positions'},
  {display: 'Orders', value: 'orders'},
  {display: 'Transfers', value: 'transfers'},
  {display: 'Balances', value: 'balances'},
  {display: 'State', value: 'state'},
  {display: 'Watchlists', value: 'watchlists'},
  {display: 'Logs', value: 'logs'},
]

interface BacktestModalProps {
  backtest: Backtest
}

export const BacktestModal = (props: BacktestModalProps) => {
  const [ user ] = useAtom(loggedInUserAtom)
  const [ uld ] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [ topSubTab, setTopSubTab ] = useState('charts')
  const [ subTab, setSubTab ] = useState('positions')
  const [ openDropdown, setOpenDropdown ] = useState<any>(null)


  const {backtest} = props

  let topSubTabContent: any = null
  switch (topSubTab) {
    case 'charts':
      topSubTabContent = <BacktestMiniChart backtest={backtest} />
      break
    case 'code':
      topSubTabContent = <ModalCodeTab backtest={backtest} />
      break
  }

  let subTabContent: any = null
  switch (subTab) {   
    case 'positions':
      subTabContent = <PositionsTable backtest={backtest} />
      break
    case 'orders':
      subTabContent = <OrdersTable backtest={backtest} paginate={true} />
      break
    case 'transfers':
      subTabContent = <TransfersTable backtest={backtest} />
      break
    case 'balances':
      subTabContent = <BalancesTable backtest={backtest} />
      break
    case 'state':
      subTabContent = <StateTable backtest={backtest} />
      break
    case 'watchlists':
      subTabContent = <FlatWatchlistTable backtest={backtest} />
      break
    case 'logs':
      subTabContent = <LogsTable backtest={backtest} />
      break
  }

  if (!user || !uld || !backtest) return null

  const startDate = formatDateForTable(backtest?.start_time_iso).split(' ')[0]
  const endDate = formatDateForTable(backtest?.end_time_iso).split(' ')[0]
  const dateString = startDate + ' - ' + endDate 

  return (
    <>
      <div
        className='modal-scrim'
        onClick={() => {
          setOpenModal(null)
        }}
      />
      <div
        className='modal'
        style={{
          height: 650,
          width: 1000,
          top: 60,
          left: 'calc(50vw - 500px)'
        }}
      >
        <div className='top' style={{height: '100%'}}>
          {openDropdown}
          <div className='title-row'>
            <div className='row'>
              <div>{'Backtest: ' + backtest.name}</div>
              <div
                className='row font-size-10'
                style={{
                  marginTop: 6.5,
                  marginLeft: 25,
                  color: TEXT_GRAY_LIGHT
                }}
              >
                <div>
                  {dateString}
                </div>
              </div>
            </div>
            <Icon
              icon='ellipsis'
              set='sharp-solid'
              size={14}
              style={{marginLeft: 12, marginTop: -.5}}
              onClick={(e) => {
                const rect = e.target.getBoundingClientRect()
                if (rect) {
                  const width= 175
                  const left = rect.left - width + 10
                  const top = rect.bottom
                  setOpenDropdown(<BacktestDropdown
                    left={left}
                    top={top}
                    backtestObj={backtest}
                    width={width}
                    onClose={() => setOpenDropdown(null)}
                  />)
                }
              }}
            />
          </div>
          <div className='modal-body' style={{height: 'calc(100% - 61px'}}>
            {backtest.status !== 'completed' ? <NoResultsComponent backtest={backtest} /> : <>
              <div className='row width-full' style={{height: 340, gap: 10}}>
                <div className='row width-full'>
                  <div className='column width-full'>
                    <div className='modal-tab-container top-section'>
                      {TOP_SUBTABS.map((s) => {
                        let className = 'modal-tab'
                        if (s.value === topSubTab) {
                          className += ' selected'
                        }
                        return (
                          <div
                            className={className}
                            onClick={() => setTopSubTab(s.value)}
                          >
                            {s.display}
                          </div>
                        )
                      })}
                    </div>
                    {topSubTabContent}
                  </div>
                </div>
              </div>
              <div className='column'>
                <div className='modal-tab-container'>
                  {SUBTABS.map((s) => {
                    let className = 'modal-tab'
                    if (s.value === subTab) {
                      className += ' selected'
                    }
                    return (
                      <div
                        className={className}
                        onClick={() => setSubTab(s.value)}
                      >
                        {s.display}
                      </div>
                    )
                  })}
                </div>
                <div className='column' style={{height: 197}}>
                  {subTabContent}
                </div>
              </div>
            </>}
          </div>
        </div>
      </div>
    </>
  )
}

interface NoResultsComponentProps {
  backtest: Backtest
}
const NoResultsComponent = (props: NoResultsComponentProps) => {
  let explanation = ''
  if (props.backtest.status === 'failed') {
    explanation = 'has failed.'
  } else {
    explanation = `is in progress.`
  }
  // "Backtest initializing..."

  const ticks_processed = props.backtest.ticks_processed || 0
  let ticks_total = props.backtest.ticks_total
  if (!ticks_total) ticks_total = 100
  const progress_pct = (ticks_processed / ticks_total) * 100

  return (
  <div className='tab-contents'>
    {props.backtest.status !== 'running' ? <div className='gray-text' style={{textAlign: 'center', marginTop: 100}}>
      No results. This backtest {explanation}
    </div> : null}

    {/* Running state */}
    {props.backtest.status === 'running' ? <div
      className='gray-text'
      style={{textAlign: 'center', marginTop: 100}}
    >
      {`Running backtest, ${props.backtest.ticks_processed} of ${props.backtest.ticks_total} ticks processed...`}
      <div className='row justify-center' style={{marginTop: 20}}>
        <ProgressBar
          progressPercent={progress_pct}
          width={250}
          style={{marginTop: 3}}
        />
      </div>
    </div>
    : null}
  </div>
  )
}
