import axios  from 'axios'
import { useAtom } from 'jotai'
import {
  Input,
  PhoneInput,
  Button,
  showModal,
  Switch,
} from '../reusable'
import { currentUser, db } from '../../firebase'
import React, { useState, useEffect } from 'react'
import { getConfig } from '../../config'
import { loggedInUserAtom, openModalAtom } from '../../types/global_types'
import { ErrorModal } from '../modals/ErrorModal'
import { CreatePlaidLinkButtonModal } from '../modals/CreatePlaidLinkButtonModal'
import { DeleteAchRelationshipModal } from '../modals/DeleteAchRelationshipModal'
import { TrustedContactModal } from '../modals/TrustedContactModal'
import { BACKGROUND_HOVER, BACKGROUND_HOVER_ALT, ORANGE } from '../../logic/u'

const config = getConfig() as any

export function PreferencesTab() {
  const [user] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [ checklistSliderVal, setChecklistSliderVal] = useState<boolean>(user ? user?.preferences?.hideOnboardingChecklist : false)
  const [ testFunctionSliderVal, setTestFunctionSliderVal] = useState<boolean>(user ? user.preferences?.skipTestFunctionModal : false)

  if (!user) { return null }

  return (
    <div className='tab-contents has-subtab'>

      {/* Basic info */}
      <div className='bold header-text-size margin-bottom-5 margin-top-5'>Preferences</div>
      {/* Trusted contact */}
      <div className='generic-content-container'>
        <div className='generic-title'>User experience</div>
        <div className='generic-content-column wider'>
          <div className='generic-content-item'>
            <div>Hide onboarding checklist:</div>
            <Switch 
              label=''
              value={checklistSliderVal}
              onChange={(val) => {
                setChecklistSliderVal(val)
                db.collection('users').doc(user.uid).update({ 'preferences.hideOnboardingChecklist': val })
              }}
            />
          </div>
          <div className='generic-content-item margin-top-10'>
            <div>Skip 'test function' modal:</div>
            <Switch 
              label=''
              value={testFunctionSliderVal}
              onChange={(val) => {
                setTestFunctionSliderVal(val)
                db.collection('users').doc(user.uid).update({ 'preferences.skipTestFunctionModal': val })
              }}
            />
          </div>          
        </div>
      </div>
    </div>
  )
}