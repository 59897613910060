

import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Input } from '../reusable/Input'
import { Checkbox, Modal } from '../reusable'
import { updateLiveData, uuid } from '../../logic/u'
import { DropdownSelect } from '../reusable/DropdownSelect'
import {
  consoleOutputAtom,
  functionExecutingAtom,
  strategiesWithUnsavedChangesAtom,
  loggedInUserAtom,
  openModalAtom,
  userLiveDocAtom,
  bottomPaneTabAtom
} from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { db } from '../../firebase'
import { run_function_once } from '../../logic/run_function_once'
import { Strategy } from '../../types/user_types'

interface TestFunctionModalProps {
  // functionObj: FunctionObj
  strategyObj: Strategy
  function_name: string
}

export const TestFunctionModal = (props: TestFunctionModalProps) => {
  const {strategyObj, function_name} = props
  const [ user ] = useAtom(loggedInUserAtom)
  const [ uld ] = useAtom(userLiveDocAtom)
  const [ consoleOutput, setConsoleOutput ] = useAtom(consoleOutputAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [ , setFunctionExecuting ] = useAtom(functionExecutingAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [strategiesWithUnsavedChanges, setstrategiesWithUnsavedChanges] = useAtom(strategiesWithUnsavedChangesAtom)
  const [, setBottomPaneTab] = useAtom(bottomPaneTabAtom)
  const [ testFunctionCheckboxVal, setTestFunctionCheckboxVal] = useState<boolean>(user ? user.preferences?.skipTestFunctionModal : false)



  if (!user || !strategyObj) return null

  return (
    <Modal
      title={`Test function once?`}
      style={{width: 440}}
      contents={[
        <div className='column width-full font-size-13 orange-text'>
          <div className=''>
            The function <span className='bold'>{function_name}</span> will be run once in test mode. This means:
            <br />
            <br />
            • Orders and transfers will only be simulated
            <br />
            • State variables will only be modified locally
            <br />
            • No permanent log will be created

          </div>
          {strategiesWithUnsavedChanges[strategyObj.id] ? <>
            <br />
            <br />
            <div style={{fontSize: 11, color: 'gray', fontStyle: 'italic'}}>
              Note: Unsaved changes will not be included.
            </div>
          </> : null}
          {<div className='row' style={{marginBottom: -10, marginTop: 15}}>
            <Checkbox 
              value={testFunctionCheckboxVal}
              onChange={(val) => {
                setTestFunctionCheckboxVal(val)                
              }}
              checkMarginTop={-2}
            />
            <div 
              className='font-size-11 bold white-text margin-left-5 pointer'
              onClick={() => {
                setTestFunctionCheckboxVal(!testFunctionCheckboxVal)                
              }}
            >
              Don't show me this again
            </div>
          </div>}
        </div>
      ]}
      yesButtonText={'Test'}
      isButtonLoading={buttonIsLoading}
      twoStepConfirmation={false}
      onYes={async () => {
        try {
          run_function_once({
            user,
            code: strategyObj.code,
            liveData: uld?.liveData,
            consoleOutput,
            setConsoleOutput,
            setFunctionExecuting,
            strategy_id: strategyObj.id,
            function_name: function_name,
            simulated_mode: true,
            cancelToken: null,
          })
          setBottomPaneTab('console')
          setOpenModal(null)
          db.collection('users')
            .doc(user?.uid)
            .update({
              bottomPaneClosed: false,
              skipTestFunctionModal: testFunctionCheckboxVal ? true : false,
              "onboardingChecklist.hasTestedStrategyOnce": true
            })          
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}      
    />
  )
}

