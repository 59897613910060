import React from 'react';
import { Modal } from '../reusable/Modal';

export const KeyboardShortcutsModal = () => {
  const isMac = navigator.platform.toLowerCase().includes('mac');

  const renderShortcutSection = (title, shortcuts) => (
    <div
      className="generic-content-container modal-version"
      style={{ width: 405, marginTop: title !== 'Global' ? 10 : 0 }}
    >
      <div className="generic-title">{title}</div>
      {shortcuts.map((shortcut, index) => (
        <div key={index} className="generic-content-item hoverable">
          <div>{shortcut.display}</div>
          <div>{isMac ? shortcut.mac : shortcut.windows}</div>
        </div>
      ))}
    </div>
  );

  const content = (
    <div>
      {renderShortcutSection('Global', KEYBOARD_SHORTCUTS.global)}
      {renderShortcutSection('Layout', KEYBOARD_SHORTCUTS.layout)}
      {renderShortcutSection('Strategy editor', KEYBOARD_SHORTCUTS.strategyEditor)}
    </div>
  );

  return (
    <Modal
      title="Keyboard shortcuts"
      contents={[content]}
      yesButtonText="Ok"
      style={{ width: 450 }}
      onYes={async () => {
        console.log('asd');
      }}
    />
  );
};

export const KEYBOARD_SHORTCUTS = {
  global: [
    { display: 'Search ticker', mac: 'cmd+K', windows: 'Ctrl+K' },
    { display: 'Search left pane', mac: 'shift+cmd+K', windows: 'Shift+Ctrl+K' },
    { display: 'New transfer', mac: 'shift+cmd+T', windows: 'Shift+Ctrl+T' },
    { display: 'New order', mac: 'shift+cmd+O', windows: 'Shift+Ctrl+O' },
    { display: 'Run some code', mac: 'shift+cmd+R', windows: 'Shift+Ctrl+R' },
    { display: 'Deploy', mac: 'shift+cmd+D', windows: 'Shift+Ctrl+D' },
    { display: 'Take down deployment', mac: 'shift+ctrl+cmd+D', windows: 'Shift+Ctrl+Alt+D' },
    { display: 'Show logs pane', mac: 'shift+cmd+L', windows: 'Shift+Ctrl+L' },
    { display: 'Show console output pane', mac: 'shift+cmd+C', windows: 'Shift+Ctrl+C' },
    { display: 'Open account modal', mac: 'opt+cmd+A', windows: 'Alt+Ctrl+A' },
    { display: 'Open documentation in a new tab', mac: 'shift+F1', windows: 'Shift+F1' },
    { display: 'Show this modal again', mac: 'shift+ctrl+cmd+K', windows: 'Shift+Ctrl+Alt+K' }
  ],
  layout: [
    { display: 'Show/hide left pane', mac: 'cmd+L', windows: 'Ctrl+L' },
    { display: 'Show/hide right pane', mac: 'cmd+R', windows: 'Ctrl+R' },
    { display: 'Show/hide bottom pane', mac: 'ctrl+B', windows: 'Ctrl+B' },
    { display: 'Show orders', mac: 'ctrl+cmd+9', windows: 'Ctrl+Alt+9' },
    { display: 'Show transfers', mac: 'ctrl+cmd+0', windows: 'Ctrl+Alt+0' }
  ],
  strategyEditor: [
    { display: 'Run current function', mac: 'ctrl+cmd+R', windows: 'Ctrl+Alt+R' },
    { display: 'Test current function', mac: 'ctrl+cmd+T', windows: 'Ctrl+Alt+T' },
    { display: 'Open backtest dropdown', mac: 'cmd+opt+B', windows: 'Ctrl+Alt+B' },
    { display: 'Clear console', mac: 'cmd+K', windows: 'Ctrl+K' }
  ]
};