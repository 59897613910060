import {
  Button,
  CustomNoRowsOverlay,
} from '../reusable'
import {
  tableSX,
} from '../../logic/u'
import React, { useState, useEffect } from 'react'
import { DataGridPro, GridOverlay } from '@mui/x-data-grid-pro'
import { getConfig } from '../../config'

const config = getConfig() as any


export function SubscriptionTab() {
  return (
    <div className='tab-contents has-subtab'>
      
      
      <div className='bold header-text-size margin-bottom-5 margin-top-5'>Subscription</div>
      <div className='generic-content-container'>
        <div className='generic-title'>Plan name</div>
        <div className='generic-content-column wider'>
          plan details here
        </div>
        <div
            style={{marginTop: 10, width: 90}}  
          >
            <Button
              display='Change'
              style={{                
                width: '100%',
                // backgroundColor: ORANGE,
                // color: BACKGROUND_HOVER,
              }}
              onClick={(e) => {
                //
              }}
            />
          </div>
      </div>


      <div className='bold header-text-size margin-bottom-5 margin-top-20'>Billing method</div>
      <div className='generic-content-container'>
        <div className='generic-title'>Card name</div>
        <div className='generic-content-column wider'>
          Used for your platform subscription; not used for transfers to/from your portfolio.
        </div>
        <div
            style={{marginTop: 10, width: 90}}  
          >
            <Button
              display='Change'
              style={{                
                width: '100%',
                // backgroundColor: ORANGE,
                // color: BACKGROUND_HOVER,
              }}
              onClick={(e) => {
                //
              }}
            />
          </div>
      </div>


      <div className='bold header-text-size margin-bottom-5 margin-top-20'>Invoices</div>
      <div className='table-container for-modal'>
        <DataGridPro
          rows={[]}
          columns={[
            { field: 'date', headerName: 'Date', flex: 1},
            { field: 'amount', headerName: 'Amount', flex: 1},
            { field: 'link', headerName: 'Link', flex: 1},
          ]}
          disableColumnResize
          disableColumnMenu
          disableColumnPinning
          disableSelectionOnClick
          density='compact'
          hideFooter
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          sx={tableSX}
        />
      </div>
    </div>
  )
}