import React, { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import {
  valueForTable,
  formatNumberForTable,
} from '../../logic/u';
import {
  loggedInUserAtom,
  userLiveDocAtom,
} from '../../types/global_types';
import { getConfig } from '../../config';
import { Backtest } from '../../types/backtest_types';
import { DropdownSelect } from '../reusable';
import { BacktestLineChart } from '../minichart/BacktestLineChart';
import { BacktestCandlestickChart } from '../minichart/BacktestCandlestickChart';
import { db } from '../../firebase';

const config = getConfig() as any;

const BUILTINS = [
  'cash',
  'cost_basis',
  'mkt_value',
  'equity',
  'pl',
  'pl_pc',
  'unrealized_pl',
  'unrealized_pl_pc',
  'realized_pl',
  'realized_pl_pc',
  'net_deposits',
];

interface BacktestMiniChartProps {
  backtest: Backtest | null;
}

export const BacktestMiniChart = (props: BacktestMiniChartProps) => {
  const [user] = useAtom(loggedInUserAtom);
  const [uld] = useAtom(userLiveDocAtom);
  const [selectedChart, setSelectedChart] = useState<string>('equity');
  const [chartData, setChartData] = useState<any>(null);

  const { backtest } = props;

  useEffect(() => {
    if (!backtest) return;
    load_chart_data(backtest, selectedChart, (d: any) => setChartData(d));
  }, [selectedChart, backtest]);

  if (!user || !uld || !backtest) return null;

  const LD_DATA_SOURCE = props.backtest ? props.backtest.liveData : uld.liveData;

  const CHART_OPTIONS: any = [];

  BUILTINS.forEach((symbol) => {
    CHART_OPTIONS.push({ display: symbol, value: symbol });
  });
  backtest?.symbols.forEach((symbol) => {
    CHART_OPTIONS.push({ display: symbol, value: symbol });
  });

  const is_line_chart = BUILTINS.includes(selectedChart);

  return (
    <div
      className='generic-content-container modal-version'
      style={{
        width: 'calc(100% - 24px)',
        marginBottom: 0,
      }}
    >
      <DropdownSelect
        style={{
          position: 'relative',
          top: 0,
          left: 0,
          zIndex: 1,
        }}
        width={200}
        menuHeight={500}
        options={CHART_OPTIONS}
        value={selectedChart}
        onChange={(v) => {
          setSelectedChart(v);
        }}
      />

      {chartData?.data ? (
        is_line_chart ? (
          <BacktestLineChart
            data={chartData.data}
            entries={chartData.entries}
            exits={chartData.exits}
          />
        ) : (
          <BacktestCandlestickChart
            data={chartData.data}
            entries={chartData.entries}
            exits={chartData.exits}
          />
        )
      ) : null}
    </div>
  );
};

const load_chart_data = async (
  backtest: Backtest,
  selectedChart: string,
  cb: any
) => {
  // Load seriesData from BacktestLogs
  if (BUILTINS.includes(selectedChart)) {
    const snapshot = await db
      .collection('users')
      .doc(backtest.userId)
      .collection('backtests')
      .doc(backtest.id)
      .collection('BacktestLogs')
      .get();
    const logs: any[] = [];
    snapshot.forEach((doc) => {
      const log_data = doc.data();
      log_data.ts = new Date(log_data.time_iso).getTime();
      logs.push(log_data);
    });

    // Sort by timestamp
    logs.sort((a, b) => a.ts - b.ts);

    const data = logs.map((log) => {
      return [log.ts, log._liveData?.BALANCES[selectedChart]];
    });
    cb({
      data,
      entries: [],
      exits: [],
    });
  }
  // Load bars from market_data
  else {
    const snapshot = await db
      .collection('users')
      .doc(backtest.userId)
      .collection('backtests')
      .doc(backtest.id)
      .collection('market_data')
      .doc(selectedChart)
      .get();

    const data = snapshot.data();
    const processed_bars: any[] = [];

    data?.bars.forEach((bar: any) => {
      processed_bars.push([
        new Date(bar.t).getTime(),
        bar.o,
        bar.h,
        bar.l,
        bar.c,
      ]);
    });

    // Place entries and exits
    const entries: any = [];
    const exits: any = [];
    for (const order of backtest.orders) {
      if (order.status === 'filled') {
        if (order.side === 'buy') {
          const entry = Number(order.filled_avg_price);
          const ts = new Date(order.created_at).getTime();
          entries.push([ts, entry]);
        } else if (order.side === 'sell') {
          const exit = Number(order.filled_avg_price);
          const ts = new Date(order.created_at).getTime();
          exits.push([ts, exit]);
        }
      }
    }
    cb({
      data: processed_bars,
      entries,
      exits,
    });
  }
};
