import React, { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import {
  tableSX,
  BACKGROUND,
  formatDateForTable,
  valueForTable,
  uuid,
  handleMUISorting,
  get_contextmenu_off,
} from '../../logic/u'
import {
  deploymentsAtom,
  loggedInUserAtom,
  openModalAtom,
  strategiesAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { CreateVariableModal } from '../modals/CreateVariableModal'
import { VariableDropdown } from '../dropdowns/VariableDropdown'
import { CustomGridOverlay } from '../reusable/CustomGridOverlay';
import { Backtest } from '../../types/backtest_types'
import { DeploymentDetailsModal } from '../modals/DeploymentDetailsModal'
import { TableDropdown } from '../dropdowns/TableDropdown'
import { Icon } from '../reusable'


interface DeploymentTableProps {
  width?: string
  height?: string
  paneIndex?: number

}

export const DeploymentsTable = (props: DeploymentTableProps) => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'createdAt',
      sort: 'desc' as GridSortDirection
    },
  ]);

  const [ openDropdown, setOpenDropdown ] = useState<any>(null)
  const [ strategies, ] = useAtom(strategiesAtom)
  const [ deployments, ] = useAtom(deploymentsAtom)


  if (!user || !uld) return null

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  }

  const deploymentRows = deployments || []

  let className = 'table-tab'

  return (
    <div className={className} style={{width: props.width, height: props.height, minHeight: 0, position: 'relative'}}>
      {openDropdown}
      <div className='tab-header'>
        <div>Deployments table</div>
        <Icon 
          icon='ellipsis'
          set='sharp-solid'
          size={14}
          style={{marginLeft: 12, marginTop: .5}}
          onClick={(e) => {      
            const rect = e.target.getBoundingClientRect()
            if (rect) {
              const width= 175
              const left = rect.left - width + 10
              const top = rect.bottom
              setOpenDropdown(<TableDropdown 
                left={left}
                top={top}
                width={width}
                onClose={() => setOpenDropdown(null)}
                paneIndex={props.paneIndex}
                table='deployments'
              />)
            }
          }}
        />
      </div>
      <div className='table-container'>
        <DataGridPro
          className='clickable-grid'
          rows={deploymentRows}
          // getRowHeight={() => 'auto'}
          // getEstimatedRowHeight={() => 36}
          columns={[
            {
              field: 'id',
              headerName: 'id',
              // flex: .5,
              width: 175,
              type: 'string',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}                  
                >
                  {params.row.id}
                </div>
              )
            },
            {
              field: 'name',
              headerName: 'name',
              width: 300,
              type: 'string',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}                  
                >
                  {params.row.name}
                </div>
              )
            },
            {
              field: 'strategies',
              headerName: 'strategies',
              flex: 1,
              type: 'string',
              sortComparator: handleMUISorting,
              renderCell: (params) => {
                const strategyIds = Object.keys(params.row.liveCode)
                const strategyStr = strategyIds.map(id => {
                  return strategies.find(s => s.id === id)?.name || `[deleted]`
                })
                return <div
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}                  
                >
                  {strategyStr.sort().join(', ')}
                </div>
              }
            },
            {
              field: 'createdAt',
              headerName: 'created_at',
              width: 155,
              headerAlign: 'right',
              align: 'right',
              type: 'date',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}                  
                >
                  {formatDateForTable(params.row.createdAt.toDate())}
                </div>
              )
            },
            
          ]}
          disableColumnResize
          disableColumnMenu
          disableColumnPinning
          disableSelectionOnClick
          density='compact'
          components={{
            NoRowsOverlay: () => (
              <CustomGridOverlay text={deploymentRows.length === 0 ? 'No deployments.' : 'Loading deployments...'} />
            ),
          }}
          sx={{
            ...tableSX,
          }}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          localeText={{
            footerTotalRows: 'Total deployments:',
          }}
          onRowClick={(params) => setOpenModal(<DeploymentDetailsModal deployment={params.row} />)}
          hideFooter={true}
        />
      </div>

    </div>
  )
}


