import { useAtom } from 'jotai'
import { db } from "../../../firebase"
import React, { useState, useEffect, useRef } from 'react';
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  streamingQuotesAtom,
  selectedEmptyPaneAtom,
  appTabAtom,
  showDiscardOrSaveModalAtom,
  strategiesWithUnsavedChangesAtom,
} from '../../../types/global_types'
import { valueForTable, GREEN_PARTIAL, RED_PARTIAL, returnPaneContextObj, set_open_pane, formatDateForTable, timeAgo, GREEN, get_contextmenu_off, ORANGE, TEXT_GRAY } from '../../../logic/u'
import { Icon2 } from '../../reusable/Icon2'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Chart, Variable, WatchlistObj } from '../../../types/user_types'
import { TickerDropdown } from '../../dropdowns/TickerDropdown';
import { TickersListSortDropdown } from '../../dropdowns/TickersListSortDropdown';

export const TickersList = () => {
  const [user] = useAtom(loggedInUserAtom);
  const [uld] = useAtom(userLiveDocAtom)
  const [selectedEmptyPane] = useAtom(selectedEmptyPaneAtom)
  const [tickers, setTickers] = useState<WatchlistObj['tickers']>([])
  const [validDrop, setValidDrop] = useState<boolean>(false)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [openDropdown, setOpenDropdown] = useState<any>(null)
  const tickerDropdownRef = useRef<HTMLDivElement>(null);
  const [ streamingQuotes ] = useAtom(streamingQuotesAtom)
  const [appTab, setAppTab] = useAtom(appTabAtom)
  const [ indicatorState, setIndicatorState ] = useState<number>(0)
  const [ sortKey, setSortKey ] = useState<string>('day_price_change')
  const sortDropdownRef = useRef<HTMLDivElement>(null)
  const [, setShowDiscardOrSaveModal] = useAtom(showDiscardOrSaveModalAtom)
  const [strategiesWithUnsavedChanges, setStrategiesWithUnsavedChanges] = useAtom(strategiesWithUnsavedChangesAtom)




  // Render tree
  useEffect(() => {
    setTickers(user?.watchlists.find(w => w.id === user.selectedWatchlistIdHomeTab)?.tickers || [])
    // debugger
  }, [user])

  const onDragUpdate = (update) => {
    const { source, destination } = update
    if (!destination) {
      setValidDrop(false)
      return
    }
    if (source.droppableId === destination.droppableId) {
      setValidDrop(true)
    }
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result

    // If no destination or destinations don't match then return
    if (!destination) {
      return
    }
    if (source.droppableId !== destination.droppableId) {
      return
    }

    // If we have a valid drop then reorder
    let tickersClone = Array.from(tickers)

    // Reorder
    const [reorderedItem] = tickersClone.splice(source.index, 1) // remove dragged item
    tickersClone.splice(destination.index, 0, reorderedItem) // add it back

    // Set the new custom result on the user
    setTickers(tickersClone) // update local state to smooth transition
    // debugger
    db.collection('users').doc(user?.uid).update({ watchlists: user?.watchlists.map(w =>
      w.id === user.selectedWatchlistIdHomeTab ? { ...w, tickers: tickersClone } : w
    )});
  }

  const getIndicatorStateVal = (mockTicker: any) => {
    const indicatorStateArr = [
      {
        title: 'day_price_change',
        value: valueForTable('day_price_change', mockTicker.day_price_change),
        greenDriver: mockTicker.day_price_change
      },
      {
        title: 'day_price_change_pc',
        value: valueForTable('day_price_change_pc', mockTicker.day_price_change_pc),
        greenDriver: mockTicker.day_price_change
      }
    ]
    return {...indicatorStateArr[indicatorState], length: indicatorStateArr.length}
  }

  const sortTickers = (a: any, b: any) => {
    const valueA = a?.[sortKey]
    const valueB = b?.[sortKey]

    if (valueA < valueB) return 1
    if (valueA > valueB) return -1
    return 0
  }

  if (!user) return null
  if (!uld || !uld.liveData) return null

  return <>
    {openDropdown}
    <div className='section-body'>
      <div className='sect-body-table-header'>
        <div
          ref={sortDropdownRef}
          className='sect-btn'
          onClick={() => {
            if (sortDropdownRef.current) {
              const rect = sortDropdownRef.current?.getBoundingClientRect()
              setOpenDropdown(
                <TickersListSortDropdown
                  left={rect.left}
                  top={rect.bottom}
                  onClose={() => setOpenDropdown(null)}
                  sortKey={sortKey}
                  setSortKey={setSortKey}
                  width={200}
                />
              )
            }
          }}
        >
          <div>sort: </div>
          <Icon2
            icon='sort'
            // set='sharp-solid'
            size={7}
            className='sect-icn'
            style={{marginLeft: 3}}
            onClick={() => {
              //
            }}
          />
        </div>
        <div
          className='sect-btn'
          onClick={(e) => {
            e.stopPropagation()
            setIndicatorState((prevState) => {
              const newState = prevState + 1
              if (newState > getIndicatorStateVal({}).length - 1) {
                return 0
              }
              return newState
            })
          }}
        >
          tag: {getIndicatorStateVal({}).title}
        </div>
      </div>
      {user.watchlists.find(w => w.id === user.selectedWatchlistIdHomeTab)?.tickers.length ? <DragDropContext
        onDragUpdate={onDragUpdate}
        onDragEnd={onDragEnd}
        key={'modal-content'}
      >
        <div className='droppable-container'>
          <Droppable droppableId={'1'} type='bucket'>
            {(provided, snapshot) => (
              <div
                className='draggable-container'
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {tickers.slice().sort((a: any, b: any) => sortTickers(uld.liveData[a], uld.liveData[b])).map((t, index) => {
                  if (!Object.keys(user.charts).includes(t)) {
                    return
                  }
                  const ticker: Chart = user.charts[t]
                  const chart = ticker.symbol
                  const chartIcon = 'square-' + ticker.symbol.charAt(0)
                  const paneContextObj = returnPaneContextObj(user, chart)
                  const chartIsVisible = paneContextObj?.paneThingIsVisible

                  let parentClass = 'entry'
                  if (chartIsVisible) {
                    parentClass += ' selected'
                  }
                  return (
                    <Draggable
                      key={ticker.symbol}
                      draggableId={ticker.symbol}
                      index={index}
                    >
                      {(provided, snapshot) => {

                        // Collect and format price change data
                        let price = uld.liveData[ticker.symbol]?.price
                        let day_price_change = uld.liveData[ticker.symbol]?.day_price_change
                        let day_price_change_pc = uld.liveData[ticker.symbol]?.day_price_change_pc

                        // Gracefully handle partial information from streamingQuotes
                        if (streamingQuotes[ticker.symbol]) {
                          price = streamingQuotes[ticker.symbol].price
                          if (streamingQuotes[ticker.symbol].day_price_change) {
                            day_price_change = streamingQuotes[ticker.symbol].day_price_change
                          }
                          if (streamingQuotes[ticker.symbol].day_price_change_pc) {
                            day_price_change_pc = streamingQuotes[ticker.symbol].day_price_change_pc
                          }
                        }

                        const mockTicker = {
                          ...ticker,
                          price,
                          day_price_change,
                          day_price_change_pc
                        }

                        return (
                          <div
                            className={parentClass}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              backgroundColor: snapshot.isDragging ? (validDrop ? GREEN_PARTIAL : RED_PARTIAL) : ''
                            }}
                            onClick={ async (event) => {
                              event.stopPropagation();
                              setAppTab('home')
                              set_open_pane(user, uld, selectedEmptyPane || 1, chart, strategiesWithUnsavedChanges, setShowDiscardOrSaveModal)
                              return
                            }}
                            onContextMenu={(event) => {
                              if (get_contextmenu_off()) return
                              event.preventDefault()
                              const width = 220
                              const clickX = event.clientX + 5
                              const clickY = event.clientY - 10
                              if (user.selectedWatchlistIdHomeTab) {
                                setOpenDropdown(
                                  <TickerDropdown
                                    symbol={ticker.symbol}
                                    watchlistId={user.selectedWatchlistIdHomeTab}
                                    left={clickX}
                                    top={clickY}
                                    width={width}
                                    onClose={() => setOpenDropdown(null)}
                                    fromLeftPane={true}
                                  />
                                )
                              }
                            }}
                          >
                            <div className='row'>
                              <Icon2
                                icon={chartIcon}
                                set='sharp-solid'
                                size={12}
                                className={'key-icon'}
                                style={{marginTop: 1, marginRight: 10, marginLeft: 0}}
                              />
                              <div style={{marginTop: 0}}>
                                {ticker.symbol}
                              </div>
                              {Object.keys(uld.liveData.POSITIONS).includes(ticker.symbol) ? <div
                                className='tag'
                              >
                                POS
                              </div> : null}
                            </div>
                            <div className='row'>
                              <div style={{marginTop: .75}}>{valueForTable('price', price)}</div>
                              <div
                                title={getIndicatorStateVal(mockTicker).title}
                                className={getIndicatorStateVal(mockTicker).greenDriver >= 0 ? ' green-tag' : ' red-tag'}
                                style={{marginLeft: 10, width: 60, textAlign: 'right'}}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setIndicatorState((prevState) => {
                                    const newState = prevState + 1
                                    if (newState > getIndicatorStateVal(mockTicker).length - 1) {
                                      return 0
                                    }
                                    return newState
                                  })
                                }}
                              >
                                {getIndicatorStateVal(mockTicker).value}
                              </div>
                            </div>
                          </div>
                        )
                      }}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext> : <div className='no-entries'>No tickers.</div>}
    </div>
  </>
}