import axios from 'axios'
import { currentUser } from '../firebase'
import { getConfig } from '../config'
import { is_order_working } from './u'
const config: any = getConfig()

export const sort_orders = (raw_orders: any[]) => {
  // raw_orders?.sort((a: any, b: any) => a.submitted_at - b.submitted_at)
  const oldest_order = raw_orders[raw_orders.length - 1]
  let earliest_ts = new Date().toISOString()
  if (oldest_order) {
    earliest_ts = oldest_order.updated_at
  }

  const orders: any = {
    unsorted: raw_orders,
    recent: [],
    filled: [],
    working: [],
    earliest_ts
  }

  raw_orders?.forEach((order: any) => {
    orders.recent.push(order)
      if (order.status === 'filled') {
        orders.filled.push(order)
      }
      if (is_order_working(order)) {
        orders.working.push(order)
      }
  })
  return orders
}

const LOAD_INCREMENT = 50
export const _load_more_orders = async (uid: string, untilTimestamp?: string) => {
  try {
    const token = await currentUser()?.getIdToken()
    const res = await axios.get(`${config.api_root_url}getOrders`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      params: {
        uid,
        untilTimestamp,
        requestedPageSize: LOAD_INCREMENT
      }
    });
    return res.data

  } catch (err) {
    console.log(`failed to load incremental orders: ${err}`)
  }
}


export const _load_all_orders = async (uid: string) => {
  const allOrders: any = []
  let untilTimestamp

  try {
    while (true) {
      const res = await axios.get(`${config.api_root_url}getOrders`, {
        headers: {
          'Authorization': `Bearer ${await currentUser()?.getIdToken()}`,
          'Content-Type': 'application/json'
        },
        params: {
          uid,
          untilTimestamp,
          requestedPageSize: 500
        }
      })

      const orders = res.data

      if (!orders || orders.length === 0) break

      allOrders.push(...orders)
      untilTimestamp = orders[orders.length - 1]?.created_at

      if (orders.length < 500) break
    }
  } catch (err) {
    console.log(`Failed to load all orders: ${err}`)
  }

  return allOrders
}

