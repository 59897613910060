import React, { useState } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

import { format_date_for_crosshairs } from '../TBChart/ChartOverlay';
import { format_date_for_minichart } from './minichart_formatters';

// Utility functions (you may need to adjust the imports based on your project structure)
import {
  format_price_appropriately,
  formatPercent,
} from '../../logic/u';
import { Icon2 } from '../reusable/Icon2';

export const BacktestLineChart = ({ data, entries, exits }) => {
  // State variable to track crosshair data
  const [crosshairData, setCrosshairData] = useState<any>(null);

  // Calculate minutes per datapoint
  let minutes_per_point = 1;
  if (data.length > 1) {
    minutes_per_point = (data[1][0] - data[0][0]) / 60000;
  }

  const min_interval = get_min_interval(data);

  // Find first and last non-null values
  const first_price = data.find(item => item[1] !== null && !isNaN(item[1]))?.[1] || 0;
  let last_price = data.findLast(item => item[1] !== null && !isNaN(item[1]))?.[1] || 0;

  if (crosshairData) {
    last_price = crosshairData.y;
  }

  // Determine color based on price movement
  let color = '#00cc66';
  let text_color = '#00cc66';
  let fillColor = '#00cc6622';

  if (last_price < first_price) {
    text_color = '#ff5555';
    // color = '#ff5555';
    // fillColor = '#ff555522';
  }

  // Calculate price difference
  let price_diff = last_price ? last_price - first_price : 0;
  let price_diff_icon = price_diff > 0 ? 'caret-up' : 'caret-down';
  let price_diff_human = format_price_appropriately(price_diff);
  const percent_diff = first_price ? (price_diff / first_price) * 100 : 0;
  let percent_diff_human = formatPercent(percent_diff);
  let last_price_human = format_price_appropriately(last_price);

  let diff_string = `$${price_diff_human} (${percent_diff_human})`;

  // Calculate min and max values from non-null data points
  const validPrices = data.map(([_, price]) => price).filter(price => price !== null && !isNaN(price));
  const minPrice = Math.min(...validPrices);
  const maxPrice = Math.max(...validPrices);
  const priceRange = maxPrice - minPrice;

  const chartOptions = {
    series: [
      {
        name: '',
        data: data,
        type: 'area',
        color: '#FF8F0E',
        tooltip: {
          valueDecimals: 2,
        },
        fillColor: '#FF8F0E11',
      },
    ],
    xAxis: {
      type: 'datetime',
      title: { text: '' },
      tickLength: 0,
      lineWidth: 0,
      labels: {
        enabled: false,
        format: '{value:%e %b %Y}',
        style: { color: 'white' },
        formatter: (obj) => {
          const d = new Date(obj.value);
          const timestamp = d.getTime();
          return format_date_for_minichart(timestamp, min_interval || '1h');
        },
        tickPositioner: function () {
          const ticks: any = [];

          // Loop through data points to find the start of each day
          for (let i = 0; i < data.length; i++) {
            const timestamp = data[i][0];
            const date = new Date(timestamp);

            // Check if it's the first data point of the day (e.g., 11:00 EDT)
            if (date.getHours() === 11) {
              ticks.push(timestamp);
            }
          }

          return ticks;
        },
      },
      crosshair: {
        label: {
          enabled: false,
        },
      },
    },
    yAxis: {
      title: { text: '' },
      labels: {
        style: { color: 'white' },
        x: -10,
      },
      gridLineWidth: 0,
      align: 'right',
      min:
        data && data.length > 0
          ? Math.min(...data.map(([_, price]) => price).filter(price => price != null)) * 0.95
          : undefined,
      max:
        data && data.length > 0
          ? Math.max(...data.map(([_, price]) => price).filter(price => price != null)) * 1.05
          : undefined,
    },
    time: {
      timezone: 'America/New_York',
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: '#000000BB',
      // backgroundColor: 'transparent',
      borderWidth: 0,
      shadow: false,
      shape: 'rect',
      style: {
        color: 'white',
        fontSize: '12px',
        fontWeight: 'bold',
        textAlign: 'center',
      },
      formatter: function () {
        const d = new Date(this.x);
        let ret = format_date_for_crosshairs(d.toISOString());

        // Set state to be used elsewhere
        if (crosshairData?.x !== this.x) {
          const last = this.points[this.points.length - 1].y;
          const diff = last - first_price;
          const percent = (diff / first_price) * 100;
          setCrosshairData({
            x: this.x,
            y: this.y,
            date: ret,
            price: format_price_appropriately(this.y),
            diff: format_price_appropriately(diff),
            pct: formatPercent(percent),
          });
        }

        return ret;
      },
      positioner: function (labelWidth, labelHeight, point) {
        const chartWidth = this.chart.chartWidth;
        const padding = 0;
        let x = point.plotX - labelWidth / 2;
        x = Math.max(padding, Math.min(chartWidth - labelWidth - padding, x));
        return {
          x: x,
          y: this.chart.chartHeight - labelHeight - 20,
        };
      },
    },
    chart: {
      height: '600px',
      backgroundColor: 'transparent',
      spacingRight: 10,
      marginRight: 10,
    },
    credits: { enabled: false },
    rangeSelector: { enabled: false },
    navigator: { enabled: false },
    scrollbar: { enabled: false },
  };

  return (
    <div
      style={{
        height: 'calc(100% - 0px)',
        position: 'relative',
      }}
    >
      {/* Price line */}
      <div
        style={{
          position: 'absolute',
          left: 10,
          top: 10,
          color: 'white',
          display: 'flex',
          fontSize: 18,
          fontWeight: 'bold',
          backgroundColor: '#000000BB',
        }}
      >
        {last_price_human}
      </div>

      {/* Diff line */}
      <div
        style={{
          position: 'absolute',
          left: 7,
          top: 35,
          color: 'white',
          display: 'flex',
          fontSize: 12,
          fontWeight: 'bold',
          backgroundColor: '#000000BB',
          padding: 3,
        }}
      >
        <Icon2
          icon={price_diff_icon}
          set={'sharp-solid'}
          size={10}
          className={price_diff > 0 ? 'green-svg' : 'red-svg'}
          style={{
            marginTop: price_diff > 0 ? 2 : 0,
          }}
        />
        <div style={{ marginLeft: 5 }}>
          <span style={{ color: text_color }}>{diff_string}</span>
        </div>
      </div>

      <div onMouseLeave={() => setCrosshairData(null)}>
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={'stockChart'}
          options={chartOptions}
        />
      </div>
    </div>
  );
};

function get_min_interval(points) {
  if (points.length < 2) return null; // No interval if there's less than 2 points

  let minInterval = Infinity;

  for (let i = 1; i < points.length; i++) {
    const interval = (points[i][0] - points[i - 1][0]) / 60000; // Difference in minutes
    if (interval < minInterval) minInterval = interval;
  }

  // Return appropriate label based on minInterval
  if (minInterval <= 1) return '1m';
  if (minInterval <= 5) return '5m';
  if (minInterval <= 30) return '30m';
  if (minInterval <= 60) return '1h';
  return '1d';
}
