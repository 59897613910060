

import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Input } from '../reusable/Input'
import { Modal } from '../reusable'
import { set_open_pane, updateLiveData, uuid } from '../../logic/u'
import { DropdownSelect } from '../reusable/DropdownSelect'
import { loggedInUserAtom, openModalAtom, selectedEmptyPaneAtom, showDiscardOrSaveModalAtom, strategiesWithUnsavedChangesAtom, userLiveDocAtom } from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { WatchlistObj } from '../../types/user_types'
import { db } from '../../firebase'
import { AddTickersModal } from './AddTickersModal'

interface CreateWatchlistModalProps {
  watchlistId?: string
  fromHome?: boolean
}

export const CreateWatchlistModal = (props: CreateWatchlistModalProps) => {
  const {watchlistId} = props

  // General helpers
  const [ user ] = useAtom(loggedInUserAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)
  const [yesButtonText, setYesButtonText] = useState<string>(watchlistId ? 'Update' : 'Create')
  const [uld] = useAtom(userLiveDocAtom)
  const [selectedEmptyPane] = useAtom(selectedEmptyPaneAtom)
  const [, setShowDiscardOrSaveModal] = useAtom(showDiscardOrSaveModalAtom)
  const [strategiesWithUnsavedChanges, setStrategiesWithUnsavedChanges] = useAtom(strategiesWithUnsavedChangesAtom)

  const watchlistObj = (user?.watchlists || []).find(w => w.id === watchlistId)

  // Form fields
  const [name, setName] = useState<string>(watchlistObj?.name.replace(/\s/g, '_') || '')

  // Check for errors when onclick isnt running
  let errorMessage = ''
  if (!buttonIsLoading) {
    // Check if name is unique
    let conflictingWatchlists: WatchlistObj[] = []
    if (watchlistObj) {
      conflictingWatchlists = user?.watchlists?.filter(w => w.id !== watchlistObj.id && w.name === name) || []
    } else {
      conflictingWatchlists = user?.watchlists?.filter(f => f.name === name) || []
    }
    if (conflictingWatchlists?.length) {
      errorMessage = 'Watchlist name is being used'
    }

    // Limit to 50 characters
    if (name?.length > 50) {
      errorMessage = 'Watchlist name must be < 50 characters'
    }

    // Ensure name doesn't start with number
    if (/^\d/.test(name)) {
      errorMessage = 'Watchlist name must not start with a number'
    }
  }

  useEffect(() => {
    // New & update path
    setButtonEnabled(!!name && !errorMessage)
  }, [name, errorMessage]);

  if (!user) return null

  const content = (
    <div className='column width-full'>

      {/* Name input */}
      <Input
        label={'Name'}
        type={'text'}
        style={{width: '100%'}}
        initialValue={name}
        onChange={(val) => {
          let modified = val.replace(/\s/g, '_').toLowerCase().trim();
          modified = modified.slice(0, 50);
          setName(modified);
        }}
        autoFocus={true}
      />
      {name && <div className='under-input-label'>
        {'Compiled: ' + name}
      </div>}

      {/* Error message, if relevant */}
      <div
        className={errorMessage ? 'font-size-10 red-text' : 'display-none'}
        style={{
          marginTop: 15,
          marginBottom: -10,
        }}
      >
        {errorMessage}
      </div>
    </div>
  )

  return (
    <Modal
      title={watchlistObj ? 'Update watchlist' : 'Create watchlist'}
      contents={[content]}
      yesButtonText={yesButtonText}
      isButtonDisabled={!buttonEnabled || buttonIsLoading}
      isButtonLoading={buttonIsLoading}
      onYes={async () => {
        try {
          setButtonIsLoading(true)                  
          
          // Update codepath
          if (watchlistObj) {
            const updatedWatchlistObj: WatchlistObj = {
              ...watchlistObj,
              name: name,
              updatedAt: new Date()
            }
            let currentWatchlists = user.watchlists || []
            const watchlistObjToUpdateIndex = currentWatchlists.findIndex(w => w.id === watchlistObj.id)
            currentWatchlists[watchlistObjToUpdateIndex] = updatedWatchlistObj
            await db.collection('users').doc(user.uid).update({ watchlists: currentWatchlists })
          } else {
            // new watchlist
            const newWatchlistObj: WatchlistObj = {
              userId: user.uid,
              id: uuid(),
              createdAt: new Date(),
              updatedAt: new Date(),
              name: name,
              tickers: [],
              isDefault: false
            }
            let currentWatchlists = user.watchlists || []
            currentWatchlists.push(newWatchlistObj)
            await db.collection('users').doc(user.uid).update({watchlists: currentWatchlists})
            if (props.fromHome) {
              await db.collection('users').doc(user.uid).update({selectedWatchlistIdHomeTab: newWatchlistObj.id})
            } else {
              await db.collection('users').doc(user.uid).update({selectedWatchlistIdWatchlistsTab: newWatchlistObj.id})
            }
            const paneThing = 'watchlist.' + newWatchlistObj.id
            set_open_pane(user, uld, selectedEmptyPane, paneThing, strategiesWithUnsavedChanges, setShowDiscardOrSaveModal)
          }
          updateLiveData(user)
          setButtonIsLoading(false)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}

