/*
MenuBar used for SignUpStages
*/

import './SignUp.scss'
import { useAtom } from 'jotai'
import React, { useState } from 'react'
import { Icon } from '../reusable'
import { logout } from '../../firebase'
import {
  appTabAtom,
  loadingAtom,
  loggedInUserAtom,
  loggedOutTabAtom,
  userLiveDocAtom,
  ordersAtom,
  transfersAtom,
  backtestsAtom,
  strategiesAtom,
  deploymentsAtom,
} from '../../types/global_types'

export function LoggedOutMenuBar() {
  const [loggedInUser, setLoggedInUser] = useAtom(loggedInUserAtom)
  // const [appTab, setAppTab] = useAtom(appTabAtom)
  // const [loggedOutTab, setLoggedOutTab] = useAtom(loggedOutTabAtom)
  // const [loading, setLoading] = useAtom(loadingAtom)
  const [ , setUld] = useAtom(userLiveDocAtom)
  const [, setOrders] = useAtom(ordersAtom)
  const [, setTransfers] = useAtom(transfersAtom)
  const [, setBacktests] = useAtom(backtestsAtom)
  const [, setStrategies] = useAtom(strategiesAtom)
  const [, setDeployments] = useAtom(deploymentsAtom)

  return (
    <div className='menu-bar'>
      <div className='left'>
        <div>
          Tickerbot
        </div>
      </div>
      {loggedInUser ? (
        <div className='right'>
          <div
            className='row align-center font-size-13 white-text pointer'
            onClick={() => {
              setLoggedInUser(null)
              setUld(null)
              setOrders([])
              setTransfers([])
              setBacktests([])
              setStrategies([])
              setDeployments([])
              logout()
            }}
          >
            <Icon
              icon='right-from-bracket'
              set='sharp-solid'
              className='margin-right-5'
            />
            <div>Sign out</div>
          </div>
        </div>
      ) : (
        null
      )}
    </div>
  )
}