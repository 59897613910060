import '../reusable/Modal.scss'
import { useAtom } from 'jotai'
import React, { useEffect, useState, useRef } from 'react'
import { Input } from '../reusable/Input'
import { Icon, Modal, showModal } from '../reusable'
import {
  loggedInUserAtom,
} from '../../types/global_types'
import { getConfig } from '../../config'
import {
  get_defaults,
  add_indicator,
  get_indicators_for_chart,
  delete_indicator,
} from '../../logic/indicator_helpers'
import { updateLiveData } from '../../logic/u'
const config = getConfig() as any

export interface IndicatorModalProps {
  symbol: string
  interval: string
}
export const IndicatorModal = (props: IndicatorModalProps) => {
  const { symbol, interval } = props
  const [user] = useAtom(loggedInUserAtom)
  if (!user) return null

  const [searchString, setSearchString] = useState<string>('')
  const [col1Selection, setCol1Selection] = useState<string>('Technical')
  const [subtab, setSubtab ] = useState<string>('Overlays')
  const [err_msg, setErr_msg] = useState<string>('')
  const inputRef = useRef(null)

  const all_options = {
    Technical: {
      hasSubtabs: true,
      Overlays: [
        {value: 'SMA', display: 'Simple moving average'},
        {value: 'EMA', display: 'Exponential moving average'},
        {value: 'VWAP', display: 'Volume weighted average price'},
        {value: 'BOLL', display: 'Bollinger bands'},
      ],
      Oscillators: [
        {value: 'MACD', display: 'Moving average convergence/divergence'},
        {value: 'RSI', display: 'Relative strength index'},
      ],
    },
    Position: {
      hasSubtabs: true,
      Overlays: [
        {value: 'avg_entry_price', display: 'Average entry price of the position'},
      ],
      Plots: [
        {value: 'qty', display: 'Number of shares'},
        {value: 'cost_basis', display: 'Total cost basis'},
        {value: 'day_price_change', display: 'Change from last day price'},  // bar chart
        {value: 'day_price_change_pc', display: 'Percent change from last day price'}, // bar chart
        {value: 'mkt_value', display: 'Total market value of the position'},
        {value: 'day_unrealized_pl', display: 'Unrealized profit/loss for the day'}, // bar chart
        {value: 'day_unrealized_pl_pc', display: 'Unrealized profit/loss percent for the day'}, // bar chart
        {value: 'unrealized_pl', display: 'Unrealized profit/loss'},
        {value: 'unrealized_pl_pc', display: 'Unrealized profit/loss percent'},
      ]
    },    
  }  

  const can_have_n_on_chart = ['SMA', 'EMA']

  // How many of each of the indicator types are already on the chart?
  const indicatorCounts: any = {}
  let overlay_count = 0
  let oscillator_count = 0
  const indicators_for_chart = get_indicators_for_chart(user, symbol, interval)
  Object.keys(indicators_for_chart).forEach((ind_key: string) => {
    const indicator = indicators_for_chart[ind_key]
    if (indicatorCounts[indicator.value] === undefined) indicatorCounts[indicator.value] = 1
    else indicatorCounts[indicator.value] += 1

    //
    if (indicator.oscillator_settings) oscillator_count += 1
    else overlay_count += 1
  })

  const make_first_col_buckets = () => {
    let ret: any = []
    Object.keys(all_options).forEach((option: string) => {
      let className = 'bucket'
      if (col1Selection === option) className += ' s'
      ret.push(
        <div
          key={option}
          className={className}
          onClick={() => {
            //@ts-ignore
            inputRef.current?.clear()
            setErr_msg('')
            setCol1Selection(option)
            setSearchString('')
            setSubtab(Object.keys(all_options[option]).filter(o => o !== 'hasSubtabs')[option === 'Position' ? 1 : 0])
          }}
        >
          {option}
        </div>
      )
    })
    return ret
  }

  const make_subtabs = () => {
    let ret: any =[]
    const subtabs = all_options[col1Selection].hasSubtabs ? Object.keys(all_options[col1Selection]).filter(o => o !== 'hasSubtabs') : []
    subtabs.forEach(s => {
      let className = 'indicators-modal-subtab'
      if (subtab === s) className += ' selected'
      ret.push(
        <div
          className={className}
          onClick={() => {
            setErr_msg('')
            setSubtab(s)}
          }
        >
          {s}
        </div>
      )
    })
    return ret
  }

  const make_second_col_buckets = () => {
    let ret: any = []
    if (searchString === '') {
      // Display the options from the selected bucket in column 1
      if (all_options[col1Selection].hasSubtabs) {
        all_options[col1Selection][subtab].forEach((option: any) => {
          ret.push(make_indicator_listing(option))
        })
      } else {
        all_options[col1Selection].forEach((option: any) => {
          ret.push(make_indicator_listing(option))
        })
      }
    } else {
      // Display only the second column with filtered results
      Object.keys(all_options).forEach(b => {
        let options: any[] = []
        if (all_options[b].hasSubtabs) {
          Object.keys(all_options[b]).filter(s => s !== 'hasSubtabs').forEach(subtab => {
            all_options[b][subtab].forEach((o: any) => {
              options.push(o)
            })
          })
          const matchedOptions = filterOptions(options, searchString)
          if (matchedOptions.length > 0) {
            ret.push(<div key={b} className='search-header'>{b}</div>)
            matchedOptions.forEach(option => {
              ret.push(make_indicator_listing(option))
            })
          }
        } else {
          all_options[b].forEach((o: any) => {
            options.push(o)
          })
          const matchedOptions = filterOptions(options, searchString)
          if (matchedOptions.length > 0) {
            ret.push(<div key={b} className='search-header'>{b}</div>)
            matchedOptions.forEach(option => {
              ret.push(make_indicator_listing(option))
            })
          }
        }
      })
    }
    return ret.length ? ret : <div className='no-results'>No indicators.</div>
  }

  const filterOptions = (options: any[], searchString: string) => {
    return options.filter((option: any) => option.display.toLowerCase().includes(searchString.toLowerCase()))
  }

  const make_indicator_listing = (option: any) => {
    return (
      <div
        className={'bucket'}
        key={option.value}       
      >
        <div className='row'>
          <div className='indicators-modal-val'>{option?.value}</div>
          <div className='indicators-modal-display'>{option?.display}</div>
        </div>
        <div className='row'>
          {makeOrangeDots(option)}
          <div className='add-remove-container'>
            {makeMinusIcon(option)}
            {makePlusIcon(option)}
          </div>
        </div>
      </div>
    )
  }

  const makeOrangeDots = (option) => {    
    if (can_have_n_on_chart) {
      const similarIndicators = Object.values(indicators_for_chart).map((iObj: any) => iObj.value).filter(v => v === option.value)      
      return <div className='row'>
        {similarIndicators.map(i => {
          return <div className='orange-dot' />
        })}
      </div>
    } else {
      return <div className='orange-dot' />
    }
  }  

  const makePlusIcon = (option) => {
    let alreadyOnChart = false
    if (!can_have_n_on_chart.includes(option.value)) {
      alreadyOnChart = Object.values(indicators_for_chart).map((iObj: any) => iObj.value).includes(option.value)
    }
    if (alreadyOnChart) {
      return
    }
    return <Icon
      icon={'square-plus'}
      set='sharp-solid'
      size={15}
      style={{ marginTop: -1 }}
      onClick={async () => {
        await addIndicator(option)
        await updateLiveData(user)
      }}
    />
  }

  const makeMinusIcon = (option) => {
    const alreadyOnChart = Object.values(indicators_for_chart).map((iObj: any) => iObj.value).includes(option.value)
    if (!alreadyOnChart) {
      return
    }
    return <Icon
      icon={'square-minus'}
      set='sharp-solid'
      size={15}
      style={{ marginTop: -1, marginRight: can_have_n_on_chart.includes(option.value) ? 10 : 0, marginLeft: 10 }}
      onClick={async () => {  
        await removeIndicator(option)
        await updateLiveData(user)
      }}
    />
  }

  const addIndicator = async (option) => {
    // Add the indicator    
    const default_params = get_defaults(option.value)
    const is_oscillator = !!default_params.oscillator_settings
    
    if (is_oscillator && oscillator_count >= 5) {
      setErr_msg('Tickerbot supports up to five oscillators per chart')
      return
    } else if (!is_oscillator && overlay_count >= 5) {
      setErr_msg('Tickerbot supports up to five overlays per chart')
      return
    } else {
      setErr_msg('')
    }
    
    const alreadyOnChart = Object.values(indicators_for_chart).map((iObj: any) => iObj.value).includes(option.value)
    const makeNewDefaults = alreadyOnChart && can_have_n_on_chart.includes(option.value)

    await add_indicator({
      indicator: option,
      interval: interval,
      symbol,
      user: user,
      makeNewDefaults
    })
  }

  const removeIndicator = async (option) => {
    setErr_msg('')
    if (can_have_n_on_chart) {
      let highest_index = 0
      for (const id in indicators_for_chart) {
        const indicator = indicators_for_chart[id]      
        if (option.value === indicator.value) {
          if (indicator.index > highest_index) {
            highest_index = indicator.index
          }
        }
      }
      const targetIndicator: any = Object.values(indicators_for_chart).find((ind: any) => indicators_for_chart[ind.abbrev_str].value == option.value && indicators_for_chart[ind.abbrev_str].index === highest_index)      
      if (targetIndicator) {
        await delete_indicator(targetIndicator.id, user)
      }
    } else {
      const targetIndicator: any = Object.values(indicators_for_chart).find((ind: any) => indicators_for_chart[ind.abbrev_str].value === option.value)
      if (targetIndicator) {
        await delete_indicator(targetIndicator.id, user)
      }      
    }
  }  

  return (
    <Modal
      title={'Indicators'}
      contents={[
        <div className='indicators-modal-container'>
          <Input
            noLabel={true}
            placeholder='Search'
            value={searchString}
            ref={inputRef}
            onChange={(val) => {
              setErr_msg('')
              setSearchString(val)
              setCol1Selection('')
              if (val === '') {
                // Reset to default when search is cleared
                setCol1Selection('Technical')
                setSubtab('Overlays')
              }
            }}
          />
          <div className='col-container'>
            <div className='first-col'>
              {make_first_col_buckets()}
            </div>
            <div className='second-col-conatiner'>
              {searchString === '' && all_options[col1Selection].hasSubtabs ? <div className='indicators-modal-subheader'>
                {make_subtabs()}
              </div> : null}
              <div className='second-col'>
                {make_second_col_buckets()}
              </div>
              {err_msg ? <div className='err-message'>{err_msg}</div> : null}
            </div>
          </div>
        </div>
      ]}
      style={{width: 800, marginLeft: -200}}
    />
  )
}

