import React, { useState, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridOverlay, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import {
  tableSX,
  BACKGROUND,
  formatPercent,
  roundToSigFigs,
  formatNumberForTable,
  valueForTable,
  uuid,
  handleMUISorting,
  get_contextmenu_off,
  TEXT_GRAY,
  updateLiveData,
} from '../../../logic/u'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  appTabAtom
} from '../../../types/global_types'
import { getConfig } from '../../../config'
import { TickerDropdown } from '../../dropdowns/TickerDropdown'
import { TickerModal } from '../../modals/TickerModal'
import { Backtest } from '../../../types/backtest_types'
import { Icon } from '../../reusable'
import { db } from '../../../firebase'
const config = getConfig() as any

interface TickerModalWatchlistsSummaryProps {
  symbol: string
  backtest?: Backtest | null
}
export const TickerModalWatchlistsSummary = (props: TickerModalWatchlistsSummaryProps) => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [ appTab, setAppTab ] = useAtom(appTabAtom)
  const [, setOpenModal] = useAtom(openModalAtom)


  if (!user || !uld) return null

  const {symbol, backtest} = props
  const LD_DATA_SOURCE = props.backtest ? props.backtest.liveData : uld.liveData.POSITIONS[symbol]

  return (
    <div className='generic-content-container modal-version'>
      <div className='generic-title'>Watchlists</div>
      <div className='row'>
        <div className='generic-content-column narrower'>
          {user.watchlists.map(watchlist => {
            return <div 
              className='generic-content-item hoverable'
              onClick={ async () => {
                  
                const tickers = user.watchlists.find(w => w.id === watchlist.id)?.tickers || []
                const ticker = symbol
                if (tickers.includes(ticker)) {
                  const updatedTickers = tickers.filter(t => t !== ticker)
                  let charts = user.charts
                  delete charts[ticker]
                  await db.collection('users').doc(user.uid).update({
                    watchlists: user.watchlists.map(w =>
                      w.id === watchlist.id ? { ...w, tickers: updatedTickers, updatedAt: new Date() } : w
                    ),
                    charts
                  })
                  // Update symbolsFollowing on UID
                  const symbolsFollowing = Object.keys(charts)
                  await db.collection('userLiveDocs').doc(user.uid).update({
                    symbolsFollowing
                  })
                } else {
                  const updatedTickers = [...tickers, ticker]
                  const charts = {
                    ...user.charts,
                    [ticker]: {
                      symbol: ticker,
                      display: ticker,
                      annotations: {},
                      indicators: {},
                    }
                  }
                  await db.collection('users').doc(user.uid).update({
                    watchlists: (user.watchlists || []).map(w =>
                      w.id === watchlist.id ? { ...w, tickers: updatedTickers, updatedAt: new Date() } : w
                    ),
                    charts
                  })
    
                  // Update symbolsFollowing on UID
                  const symbolsFollowing = Object.keys(charts)
                  await db.collection('userLiveDocs').doc(user.uid).update({
                    symbolsFollowing
                  })
                }
              }}  
            >
              <div className='add-ellipses-cutoff' style={{width: 175}}>{watchlist.name}</div>
              <div className='row' style={{marginTop: 2}}>
                {watchlist.tickers.includes(symbol) && <div
                  style={{                    
                    backgroundColor: TEXT_GRAY,
                    height: 9,
                    width: 9,
                    borderRadius: '50%',                                        
                    marginTop: 1,
                    marginRight: 10
                  }}
                />}
                <Icon 
                  icon='arrow-up-right'
                  set='sharp-solid'
                  size={9}
                  onClick={(e) => {
                    e.stopPropagation()
                    setOpenModal(null)
                    db.collection('users').doc(user.uid).update({selectedWatchlistIdHomeTab: watchlist.id})
                  }}
                />                
              </div>
            </div>
          })}            
        </div>                  
      </div>
    </div>      
  )
}