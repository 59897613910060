import moment from 'moment-timezone'
import { Strategy, User, UserLiveDoc } from '../types/user_types'
import { db } from '../firebase'
import axios from 'axios'
import { getConfig } from '../config'
import { currentUser, getFirebase } from '../firebase'
import { create } from 'lodash'
import { format } from 'date-fns-tz'
import { DiscardOrSaveModalState } from '../types/global_types'
// import { FieldValue } from '../firebase'

const firebase = getFirebase()

const config = getConfig()

export const ORANGE = '#FF8F0E'
export const GREEN = '#27ad75'
export const GREEN_PARTIAL = '#27ad7544'
export const RED = '#F0616d'
export const RED_PARTIAL = '#990000'
export const BACKGROUND = '#0D0A0A'
export const TEXT_GRAY = '#999999'
export const TEXT_GRAY_LIGHT = '#bfbfbf'
export const SUCCESS = '#00c621'
export const PRIMARY = '#006DFF'
export const BACKGROUND_HOVER = '#2e1a03'
export const BACKGROUND_HOVER_ALT = '#03172e'
export const SECOND_SURFACE = '#2C2B2B'
export const SURFACE_HOVER = '#333333'
export const BORDER_INPUT = '#51535e'


export const uuid = () => {
    return crypto.randomUUID()
}

// fix
export const setCursorToEnd = (target: any) => {
  const selection: any = window.getSelection()
  const range = document.createRange()
  selection.removeAllRanges()
  range.selectNodeContents(target)
  range.collapse(false)
  selection.addRange(range)
  target.focus()
}

export const setCursorToPosition = (target: any, position: number) => {
  const selection: any = window.getSelection()
  const range = document.createRange()
  selection.removeAllRanges()
  range.selectNodeContents(target)
  range.collapse(true)
  range.setStart(target.childNodes[0], position)
  range.setEnd(target.childNodes[0], position)
  selection.addRange(range)
  target.focus()
}

export const selectContents = (target: any) => {
  const range = document.createRange();
  range.selectNodeContents(target);
  const sel: any = window.getSelection();
  sel.removeAllRanges();
  sel.addRange(range);
}

export const cumulativeOffset = (element: any) => {
  const boundingRect = element.getBoundingClientRect()
  return {
    top: boundingRect.top + window.pageYOffset,
    left: boundingRect.left + window.pageXOffset
  }
}

export const replace = (string: string, toReplace: string, toReplaceWith: string) => {
  while(string.includes(toReplace)) {
      string = string.replace(toReplace, toReplaceWith)
  }
  return string
}

/*
  'datasource.method(arg: val)' ->
  ['datasource', ',', 'method', '(' 'arg', ':', ' ', 'val', ')']
*/
export const splitByMultiple = (string: string, delimiters: string[], includeDelimiters: boolean) => {
  let ret = [string]
  delimiters.forEach(delimiter => {
    let newRet: string[] = []
    ret.forEach(item => {
      if (!includeDelimiters) {
        newRet = newRet.concat(item.split(delimiter))
      } else {
        const split = item.split(delimiter)
        const newSplit: string[] = []
        split.forEach((item, i) => {
          newSplit.push(item)
          if (i !== split.length - 1) {
            newSplit.push(delimiter)
          }
        })
        newRet = newRet.concat(newSplit)
      }
    })
    ret = newRet
  })
  ret = ret.filter(item => item !== '')
  return ret
}

/*
  ['abc def', 'ghi'] ->
  ['abc ', 'def', 'ghi']
  MAYBE NOT USING THIS
*/
export const splitIncludingSpace = (arr: string[]) => {
  let ret: string[] = []
  arr.forEach(item => {
    const splitBySpace = item.split(' ')
    splitBySpace.forEach((item, i) => {
      if (i !== splitBySpace.length - 1) {
        splitBySpace[i] = item + ' '
      }
    })
    ret.concat(splitBySpace)
    ret = ret.concat(splitBySpace)
  })
  return ret
}

export const getIndexInPieces = (pieces: string[], pieceIndex: number) => {
  let index = 0
  for (let i = 0; i < pieceIndex; i++) {
    index += pieces[i].length
  }
  return index
}

/*
Given an array like so
  ['spy', '.', 'price()', ' > trendline']
  splits on spaces, but adds spaces to previous chunk:
  ['spy', '.', 'price() ', '> ', 'trendline']

*/
export const spaceSplit = (arr: string[]) => {

  // Join with artificial delimiter, split on spaces
  const DELIMITER = '●'
  const joined = arr.join(DELIMITER)
  const preliminarySplit = joined.split(' ')

  const secondarySplit: string[] = []
  preliminarySplit.forEach((piece, i) => {
    if (i < preliminarySplit.length - 1) {
      secondarySplit.push(piece + ' ')
    } else {
      secondarySplit.push(piece)
    }
  })

  // Remove artificial delimiter
  const ret = furtherSplitArray(secondarySplit, DELIMITER)

  return ret
}

export const furtherSplitArray = (arr: string[], delimiter: string) => {
  let ret: string[] = []
  arr.forEach(item => {
    ret = ret.concat(item.split(delimiter))
  })
  return ret
}

export const removeChars = (string: string, chars: string[]) => {
  for (let i = 0; i < chars.length; i++) {
    string = removeCharFromString(string, chars[i])
  }
  return string
}

export const removeCharFromString = (string: string, char: string) => {
  while (string.includes(char)) {
    string = string.replace(char, '')
  }
  return string
}

export const limitToSet = (string: string, set: string[]) => {
  let ret = ''
  for (let i = 0; i < string.length; i++) {
      if (set.includes(string[i])) {
          ret += string[i]
      }
  }
  return ret
}

// Note: someday we should add an option for user to specify preferred date format
export const getHumanDate = (date: Date) => {
  date = date || new Date()
  return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
}

export const getHumanDateTime = (date: Date) => {
  date = date || new Date()
  return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' })
}

export const alpacaTimeframeFromTVTimeframe = (timeframe: string) => {
  switch (timeframe) {
    case '1':
      return '1Min'
    case '5':
      return '5Min'
    case '15':
      return '15Min'
    case '30':
      return '30Min'
    case '60':
      return '1Hour'
    case '120':
      return '2Hour'
    case '240':
      return '4Hour'
    case '1D':
      return '1Day'
    case '3D':
      return '3Day'
    case '1W':
      return '1Week'
    case '1M':
      return '1Month'
    default:
      throw new Error('Invalid timeframe')
  }
}

const ALPACA_RES_TO_MINUTES: any = {
  '1': 1,
  '5': 5,
  '15': 15,
  '30': 30,
  '60': 60,
  '120': 120,
  '240': 240,
  '1D': 24 * 60,
  '3D': 3 * 24 * 60,
  '1W': 7 * 24 * 60,
  '1M': 30 * 24 * 60,
}
const EXTENDED_OPEN = 4
const EXTENDED_CLOSE = 20

export const calculateExpectedBars = (startTime: string, endTime: string, resolution: string) => {
  // Define market hours (in this example, we assume U.S. market hours)
  const marketOpenHour = EXTENDED_OPEN
  const marketCloseHour = EXTENDED_CLOSE

  // Define the resolution in minutes
  const resolutionInMinutes = ALPACA_RES_TO_MINUTES[resolution]

  // Parse the start and end times
  const start = new Date(startTime)
  const end = new Date(endTime)

  // Calculate the total number of minutes in each trading day
  const tradingMinutesPerDay = (marketCloseHour - marketOpenHour) * 60

  // Initialize a counter for the number of bars
  let barCount = 0

  // Iterate through each day in the time window
  for (let current = new Date(start); current <= end; current.setDate(current.getDate() + 1)) {
    // Check if the current day is a weekday (not a weekend)
    if (current.getDay() !== 0 && current.getDay() !== 6) {
      // Calculate the number of bars for this trading day and add it to the counter
      barCount += tradingMinutesPerDay / resolutionInMinutes;
    }
  }

  // Return the total number of bars
  return Math.ceil(barCount)
}

export const countBackBars = (startDate: string, n: number, interval: string) => {
  const marketOpenHour = EXTENDED_OPEN
  const marketCloseHour = EXTENDED_CLOSE

  const intervalInMinutes = ALPACA_RES_TO_MINUTES[interval]

  // Calculate the total number of minutes in each trading day
  const tradingMinutesPerDay = (marketCloseHour - marketOpenHour) * 60

  // Calculate the number of days to go back to cover n bars
  const daysToGoBack = Math.ceil((n * intervalInMinutes) / tradingMinutesPerDay)

  // Calculate the number of weekends within the days to go back
  const weekends = Math.floor(daysToGoBack / 5)

  // Calculate the total number of days, including weekends
  const totalDaysToGoBack = daysToGoBack + (weekends * 2)

  // Create a new Date object based on startDate
  const newDate = new Date(startDate)

  // Go back the total number of days
  newDate.setDate(newDate.getDate() - totalDaysToGoBack)

  // Return the new Date object
  return newDate
}


/*
Given an array and an array of updates, replaces members of array.
  - note: if changeType is specified as "removed", item is removed
*/
export const replaceUpdated = (arr: any[], changes: any[]) => {

  // Put everything into an object
  const keyedById: any = {}
  arr.forEach(item => {
    keyedById[item.id] = item
  })

  // Replace. If removed, remove
  changes.forEach(change => {
    keyedById[change.id] = change
    if (change.changeType === 'removed') {
      delete keyedById[change.id]
    }
  })

  const newArray = Object.values(keyedById)
  return newArray
}

// US hardcoded as now
export const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('us-EN', { style: 'currency', currency: 'USD' }).format(Number(value))
}

export const formatPercent = (value: number) => {
  if (value === null || value === undefined) {
    return '-'
  }
  return value.toFixed(2) + '%';
}

export const encodeFunctionCall = (functionName: string, args: any) => {
  let ret = functionName + '('
  Object.keys(args).forEach((key: string, i: number) => {
    const value = args[key]
    ret += (key + ': ' + value)
    if (i + 1 < Object.keys(args).length) {
      ret += ', '
    }
  })
  ret += (')')
  return ret
}
// window.encodeFunctionCall = u.encodeFunctionCall

export const decodeFunctionCall = (fnString: string) => {
  if (!fnString) {
    return {functionName: '', args: {}}
  }
  const args: any = {}
  const split = fnString.split('(')
  const functionName = split[0]
  if (split.length > 1) {
    const argString = fnString.split('(')[1].slice(0, -1)
    const argsSplit = argString.split(',')
    argsSplit.forEach((str: string) => {
      if (str !== '') {
        const key = str.split(':')[0].trim()
        const value = str.split(':')[1].trim()
        args[key] = value
      }
    })
  }

  return {functionName, args}
}

export const usStates = [
  { display: 'AL', value: 'AL' },
  { display: 'AK', value: 'AK' },
  { display: 'AZ', value: 'AZ' },
  { display: 'AR', value: 'AR' },
  { display: 'CA', value: 'CA' },
  { display: 'CO', value: 'CO' },
  { display: 'CT', value: 'CT' },
  { display: 'DE', value: 'DE' },
  { display: 'DC', value: 'DC' },
  { display: 'FL', value: 'FL' },
  { display: 'GA', value: 'GA' },
  { display: 'HI', value: 'HI' },
  { display: 'ID', value: 'ID' },
  { display: 'IL', value: 'IL' },
  { display: 'IN', value: 'IN' },
  { display: 'IA', value: 'IA' },
  { display: 'KS', value: 'KS' },
  { display: 'KY', value: 'KY' },
  { display: 'LA', value: 'LA' },
  { display: 'ME', value: 'ME' },
  { display: 'MD', value: 'MD' },
  { display: 'MA', value: 'MA' },
  { display: 'MI', value: 'MI' },
  { display: 'MN', value: 'MN' },
  { display: 'MS', value: 'MS' },
  { display: 'MO', value: 'MO' },
  { display: 'MT', value: 'MT' },
  { display: 'NE', value: 'NE' },
  { display: 'NV', value: 'NV' },
  { display: 'NH', value: 'NH' },
  { display: 'NJ', value: 'NJ' },
  { display: 'NM', value: 'NM' },
  { display: 'NY', value: 'NY' },
  { display: 'NC', value: 'NC' },
  { display: 'ND', value: 'ND' },
  { display: 'OH', value: 'OH' },
  { display: 'OK', value: 'OK' },
  { display: 'OR', value: 'OR' },
  { display: 'PA', value: 'PA' },
  { display: 'RI', value: 'RI' },
  { display: 'SC', value: 'SC' },
  { display: 'SD', value: 'SD' },
  { display: 'TN', value: 'TN' },
  { display: 'TX', value: 'TX' },
  { display: 'UT', value: 'UT' },
  { display: 'VT', value: 'VT' },
  { display: 'VA', value: 'VA' },
  { display: 'WA', value: 'WA' },
  { display: 'WV', value: 'WV' },
  { display: 'WI', value: 'WI' },
  { display: 'WY', value: 'WY' },
]
// window.decodeFunctionCall = u.decodeFunctionCall

export const returnFinancialProfileMinMax = (minVal: string) => {
  const minMaxArr = [
    {min: '0', max: '24999'},
    {min: '25000', max: '99999'},
    {min: '100000', max: '499999'},
    {min: '500000', max: '999999'},
    {min: '1000000', max: null},
  ]
  const min = minVal
  const max = minMaxArr.filter(item => item.min === minVal)[0].max
  return [min, max]
}

export const fundingSourceOptions = [
  {display: 'Employment income', value: 'employment_income'},
  {display: 'Investments', value: 'investments'},
  {display: 'Inheritance', value: 'inheritance'},
  {display: 'Business income', value: 'business_income'},
  {display: 'Savings', value: 'savings'},
  {display: 'Family', value: 'family'},
]

export const stringToNumber = (amountString: string) => {
  return parseFloat(amountString?.replace(/[^0-9.-]/g, ''))
}


// TODO MUST FIX TO HANDLE OTHER PARAM TYPES
export const stringToFormattedAmount = (amountString: string | null): string => {
  if (amountString === null) {
    return '-';
  }

  const number = parseFloat(amountString);
  if (isNaN(number)) {
    return '-';
  }

  const formattedAmount = number.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return formattedAmount;
};

export const capitalizeFirstLetterOfAWord = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const indicatorClass = (number: number) => {
  if (number >= 0) {
    return ' pos'
  }
  return ' neg'
}

export const activityTypes = [
  // { activityType: 'FILL', description: 'Order Fills (Partial/Full)' },
  { activityType: 'ACATC', description: 'ACATS IN/OUT (Cash)' },
  { activityType: 'ACATS', description: 'ACATS IN/OUT (Securities)' },
  { activityType: 'CIL', description: 'Cash in Lieu of Stock' },
  { activityType: 'CSD', description: 'Cash Disbursement (+)' },
  { activityType: 'CSW', description: 'Cash Withdrawable' },
  { activityType: 'DIV', description: 'Dividend' },
  { activityType: 'DIVCGL', description: 'Dividend (Capital Gain Long Term)' },
  { activityType: 'DIVCGS', description: 'Dividend (Capital Gain Short Term)' },
  { activityType: 'DIVNRA', description: 'Dividend Adjusted (NRA Withheld)' },
  { activityType: 'DIVROC', description: 'Dividend Return of Capital' },
  { activityType: 'DIVTXEX', description: 'Dividend (Tax Exempt)' },
  { activityType: 'FEE', description: 'REG and TAF Fees' },
  { activityType: 'INT', description: 'Interest (Credit/Margin)' },
  { activityType: 'JNLC', description: 'Journal Entry (Cash)' },
  { activityType: 'JNLS', description: 'Journal Entry (Stock)' },
  { activityType: 'MA', description: 'Merger/Acquisition' },
  { activityType: 'PTC', description: 'Pass Thru Change' },
  { activityType: 'REORG', description: 'Reorg CA' },
  { activityType: 'SPIN', description: 'Stock Spinoff' },
  { activityType: 'SPLIT', description: 'Stock Split' },
  { activityType: 'WH', description: 'Withheld' },
];

export const datafeedFilterOptions = [
  { display: 'Algorithm', value: 'algorithm'},
  { display: 'Account', value: 'account'}
]

// Returns a string of form $1,425.29
export const formatStockPrice = (price: number, removeDollarSign?: boolean) => {
  if (isNaN(price)) {
    return ''
  }
  let formattedPrice: string
  if (price < 0) {
    // formattedPrice = `($${Math.abs(price).toFixed(2)})`
    formattedPrice = `-$${Math.abs(price).toFixed(2)}`
  } else {
    formattedPrice = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price)
    if (removeDollarSign) {
      formattedPrice = formattedPrice.slice(1)
    }
  }
  return formattedPrice
}

const HOLIDAYS = [
  // 2024 Holidays
  +new Date('2024-01-01T00:00:00-05:00'), // New Year's Day
  +new Date('2024-02-19T00:00:00-05:00'), // Washington's Birthday
  +new Date('2024-05-27T00:00:00-04:00'), // Memorial Day
  +new Date('2024-06-19T00:00:00-04:00'),
  +new Date('2024-07-04T00:00:00-04:00'), // Independence Day
  +new Date('2024-09-02T00:00:00-04:00'), // Labor Day
  +new Date('2024-11-28T00:00:00-05:00'), // Thanksgiving Day
  +new Date('2024-12-25T00:00:00-05:00'), // Christmas Day
  // 2025 Holidays
  +new Date('2025-01-01T00:00:00-05:00'), // New Year's Day
  +new Date('2025-02-17T00:00:00-05:00'), // Washington's Birthday
  +new Date('2025-05-26T00:00:00-04:00'), // Memorial Day
  +new Date('2025-06-19T00:00:00-04:00'),
  +new Date('2025-07-04T00:00:00-04:00'), // Independence Day
  +new Date('2025-09-01T00:00:00-04:00'), // Labor Day
  +new Date('2025-11-27T00:00:00-05:00'), // Thanksgiving Day
  +new Date('2025-12-25T00:00:00-05:00'), // Christmas Day
  // 2026 Holidays
  +new Date('2026-01-01T00:00:00-05:00'), // New Year's Day
  +new Date('2026-02-16T00:00:00-05:00'), // Washington's Birthday
  +new Date('2026-05-25T00:00:00-04:00'), // Memorial Day
  +new Date('2026-06-19T00:00:00-04:00'),
  +new Date('2026-07-03T00:00:00-04:00'), // Independence Day (observed)
  +new Date('2026-09-07T00:00:00-04:00'), // Labor Day
  +new Date('2026-11-26T00:00:00-05:00'), // Thanksgiving Day
  +new Date('2026-12-25T00:00:00-05:00'), // Christmas Day
]

// COPY MAINTAINED ON BACKEND
export const isMarketOpen = (timestamp?: any) => {
  // Convert the timestamp to a moment object in New York time
  const newYorkTime = moment.tz(timestamp, 'America/New_York');

  const dayOfWeek = newYorkTime.day();
  const hour = newYorkTime.hour();
  const minute = newYorkTime.minute();

  // Market open hours in NYC (Eastern Time)
  const marketOpenHour = 9; // 9:00 AM ET
  const marketCloseHour = 16; // 4:00 PM ET
  const marketOpenMinute = 30; // 9:30 AM ET

  const isWeekday = dayOfWeek >= 1 && dayOfWeek <= 5;
  const isWithinMarketHours = (hour > marketOpenHour || (hour === marketOpenHour && minute >= marketOpenMinute)) && hour < marketCloseHour;

  // Convert the moment object to the start of the day in Unix timestamp for comparison
  const startOfDayTimestamp = newYorkTime.startOf('day').valueOf();

  // Check if the current date is a holiday
  const isHoliday = HOLIDAYS.includes(startOfDayTimestamp);

  return isWeekday && isWithinMarketHours && !isHoliday;
}


// Should be formatted as "1.42%"
export const getDailyPriceDiffPct = (currentPrice: number, prevClosePrice: number) => {
  if (isNaN(currentPrice) || isNaN(prevClosePrice)) {
    return ''
  }
  const diff = currentPrice - prevClosePrice
  const diffPct = (diff / prevClosePrice) * 100
  return diffPct.toFixed(2) + '%'
}


export const getMostRecentCloseTime = () => {
  const currentTime = new Date() // Current time
  const oneDay = 24 * 60 * 60 * 1000 // One day in milliseconds
  const nyTimeZone = 'America/New_York' // New York timezone

  // Start checking from the current time and go back one day at a time
  for (let i = 1; i < 7; i++) {
    const timestamp = currentTime.getTime() - (i * oneDay)
    const noonTimestamp = new Date(timestamp).setHours(12, 0, 0, 0)
    const nyNoonTimestamp = new Date(noonTimestamp).toLocaleString('en-US', {
      timeZone: nyTimeZone,
      hour12: false,
    })

    const wasMarketOpenAtNoon = isMarketOpen(new Date(nyNoonTimestamp).getTime())

    // Return the timestamp of 4:30 PM on the day of nyNoonTimestamp
    if (wasMarketOpenAtNoon) {
      const closeTime = new Date(nyNoonTimestamp).setHours(16, 0, 0, 0)
      return closeTime
    }
  }

  throw new Error('Could not find a recent close time')
}

// Like getMostRecentCloseTime, but returns now if the market is open
// IF NOTHING BREAKS FROM THE BELOW, DELETE THIS
// export const getMostRecentMarketOpenTime = (referenceDate?: Date): Date => {
//   if (isMarketOpen()) {
//     return getNYCDate()
//   }
//   return new Date(getMostRecentCloseTime())
// }

export const getMostRecentMarketOpenTime = (referenceDate?: Date): Date => {
  // If no reference date is provided, use the current NYC date
  let dateToCheck = referenceDate || getNYCDate()

  // Check if the market is open on the given date
  if (isMarketOpen(dateToCheck)) {
    return dateToCheck
  }

  // If the market is not open, decrement the date until we find an open market day
  dateToCheck.setDate(dateToCheck.getDate() - 1)
  while (!isMarketOpen(dateToCheck)) {
    dateToCheck.setDate(dateToCheck.getDate() - 1)
  }

  // Once an open market day is found, set the time to the market's opening time
  // Assuming market opens at 9:30 AM Eastern Time
  dateToCheck.setHours(9, 30, 0, 0)

  return dateToCheck
}



// Used for eval() results, cleans up bullshit like "451.58000000000004".
export const cleanEvalOutput = (input: any) => {
  if (typeof input === 'number') {
    return Number(input.toString().split('000')[0])
  }
  return input
}

export const evalStringLocal = (str: string) => {
  try {
    const raw = eval(str)
    return cleanEvalOutput(raw)
  } catch(err) {
    return 'ERROR'
  }
}

export const tableSX = {
  '&.MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '&.MuiDataGrid-menuIconButton': {
    opacity: 1,
    color: '#FFFFEE',
  },
  '&.MuiDataGrid-sortIcon': {
    opacity: 1,
    color: '#FFFFEE',
  },
  '&.MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator': {
    opacity: 1,
    color: '#FFFFEE',
  },
  '&.MuiDataGrid-cell:focus': {
    outline: 'none'
  },
  '&.MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '&.MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '&.MuiDataGrid-columnHeader:focus-hover': {
    outline: 'none',
  },
  '&.MuiDataGrid-row:hover': {
    backgroundColor: '#03172e',
  },
  '&.MuiDataGrid-pinnedColumns': {
    backgroundColor: `${BACKGROUND}`,
    borderRight: `1px solid ${BACKGROUND_HOVER}`
  },
  '&.MuiDataGrid-pinnedColumnHeaders': {
    backgroundColor: `${BACKGROUND_HOVER}`,
  },
  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { pt: '8px', pb: '8px', pr: '10px', pl: '10px' },
  '&.MuiDataGrid-root .MuiDataGrid-sortIcon': {
    color: ORANGE
  }
}

export const tableSX_Modal = {
  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
    pt: '8px', pb: '8px', pr: '10px', pl: '10px',
    borderBottom: `1px solid ${BORDER_INPUT}`
  },
  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-columnHeaders': {
    color: TEXT_GRAY_LIGHT,
    backgroundColor: SECOND_SURFACE,
    borderBottom: `2px solid ${BORDER_INPUT}`,
  },
  '&.MuiDataGrid-root--densityCompact': {
    border: `2px solid ${BORDER_INPUT}`,
    borderRadius: 0
  },
  '&.MuiDataGrid-root .MuiDataGrid-iconSeparator': {
    color: TEXT_GRAY_LIGHT
  },
  '&.MuiDataGrid-root': {
    color: TEXT_GRAY_LIGHT,
  },
  '&.MuiDataGrid-root .MuiDataGrid-footerContainer': {
    borderTop: `1px solid ${BORDER_INPUT}`
  },
  '&.MuiDataGrid-root .MuiDataGrid-overlay': {
    backgroundColor: SECOND_SURFACE,
  },
  '&.MuiDataGrid-root .MuiDataGrid-row:hover': {
    // backgroundColor: `${SECOND_SURFACE} !important`
  },
  // '&.MuiDataGrid-root .MuiDataGrid-columnSeparator:last-of-type': {
  //   display: 'none'
  // },
  '&.MuiDataGrid-root .MuiDataGrid-sortIcon': {
    color: TEXT_GRAY_LIGHT
  }
}

export const msToHumanReadable = (ms: number) => {
  if (typeof ms !== 'number' || ms < 0) {
    return 'Invalid input'
  }

  const msInASecond = 1000
  const secondsInAMinute = 60
  const minutesInAnHour = 60
  const hoursInADay = 24

  let remainingMilliseconds = ms

  const days = Math.floor(remainingMilliseconds / (msInASecond * secondsInAMinute * minutesInAnHour * hoursInADay))
  remainingMilliseconds -= days * msInASecond * secondsInAMinute * minutesInAnHour * hoursInADay

  const hours = Math.floor(remainingMilliseconds / (msInASecond * secondsInAMinute * minutesInAnHour))
  remainingMilliseconds -= hours * msInASecond * secondsInAMinute * minutesInAnHour

  const minutes = Math.floor(remainingMilliseconds / (msInASecond * secondsInAMinute))
  remainingMilliseconds -= minutes * msInASecond * secondsInAMinute

  const seconds = Math.floor(remainingMilliseconds / msInASecond)
  remainingMilliseconds -= seconds * msInASecond

  return `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`
}

export const getNYCDate = (offset = 0) => {
    const date = new Date(new Date().toLocaleString("en-US", { timeZone: "America/New_York" }))
    date.setDate(date.getDate() + offset)
    return date
}

export const convertToNYCDate = (date: Date) => {
  return new Date(date.toLocaleString("en-US", { timeZone: "America/New_York" }))
}

export const getRecentTradingDayStart = (daysAgo = 0) => {
  let nycDate = moment.tz('America/New_York');

  function before930AM(date: any) {
    return date.hour() < 9 || (date.hour() === 9 && date.minute() < 30);
  }

  function isMarketOpen(date: any) {
    const dayOfWeek = date.day();
    const startOfDayTimestamp = date.startOf('day').valueOf();
    const isHoliday = HOLIDAYS.includes(startOfDayTimestamp);
    return dayOfWeek !== 0 && dayOfWeek !== 6 && !isHoliday; // Check for weekends and holidays
  }

  // If it's before 9:30 AM or it's a non-trading day, adjust to the last trading day
  if (before930AM(nycDate) || !isMarketOpen(nycDate)) {
    nycDate.subtract(1, 'days');
    while (!isMarketOpen(nycDate)) {
      nycDate.subtract(1, 'days');
    }
  }

  // If daysAgo is 1 or more, adjust for trading days from this point
  if (daysAgo > 0) {
    let tradingDaysToGoBack = daysAgo;
    while (tradingDaysToGoBack > 0) {
      nycDate.subtract(1, 'days');
      if (isMarketOpen(nycDate)) {
        tradingDaysToGoBack--;
      }
    }
  }

  nycDate.set({ hour: 9, minute: 30, second: 0, millisecond: 0 });

  return nycDate.toDate();
};




export const getRecentTradingDayEnd = (daysAgo = 0) => {
  const day_start = getRecentTradingDayStart(daysAgo)
  const day_end = new Date(day_start)
  day_end.setHours(day_end.getHours() + 6, 30, 0, 0)
  return day_end
}

// Returns date of most recent Monday, 9:30 AM in NYC
export const getRecentWeekStart = (weeksAgo = 0) => {
  let nycDate = moment.tz('America/New_York')

  // Subtract weeks, if any
  nycDate.subtract(weeksAgo, 'weeks')

  // Get the most recent Monday
  nycDate.startOf('isoWeek') // ISO week starts on Monday

  // Set the time to 9:30 AM in New York time
  nycDate.set({ hour: 9, minute: 30, second: 0, millisecond: 0 })

  return nycDate.toDate()
}

// Returns date of Friday, 4:00 PM in NYC, at the close of the week started in getRecentWeekStart()
export const getRecentWeekEnd = (weeksAgo = 0) => {
  let weekStart = getRecentWeekStart(weeksAgo)
  let weekEnd = new Date(weekStart)

  // Add 4 days to get to Friday
  weekEnd.setDate(weekEnd.getDate() + 4)

  // Set the time to 4:00 PM in New York time
  weekEnd.setHours(16, 0, 0, 0)

  return weekEnd
}

export const getCurrentYearStart = (yearsAgo = 0) => {
  const date = new Date()
  const year = date.getFullYear() - yearsAgo
  const yearStart = new Date(year, 0, 1)
  return yearStart
}

export const getCurrentYearEnd = (yearsAgo = 0) => {
  const date = new Date()
  const year = date.getFullYear() - yearsAgo
  const yearEnd = new Date(year, 11, 31)
  return yearEnd
}

export const getCurrentMonthStart = (monthsAgo = 0) => {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth() - monthsAgo
  const monthStart = new Date(year, month, 1)
  return monthStart
}

export const getCurrentMonthEnd = (monthsAgo = 0) => {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth() - monthsAgo
  const monthEnd = new Date(year, month + 1, 0)
  return monthEnd
}

// How many candles of the appropriate interval between startDate and endDate
// "9:30a monday", "9:30a tuesday", 1000 * 60 * 60 -> 7
export const getMarketOpenTimeDiffCandles = (startDate: Date, endDate: Date, intervalMs: number) => {
  const startTs = startDate.getTime()
  const endTs = endDate.getTime()

  let barCount = 0
  let candidateTs = startTs
  while (candidateTs < endTs) {
    if (isMarketOpen(candidateTs)) {
      barCount++
    }
    candidateTs += intervalMs
  }
  return barCount
}


// export const getLastTimeMarketOpen = () => {
//   // Initialize to the current time
//   let currentTime = moment().tz('America/New_York');

//   // Iterate backwards to find the most recent time the market was open
//   while (true) {
//     if (isMarketOpen(currentTime.unix())) {
//       // If market is currently open, get the time it will close today
//       return currentTime
//         .clone()
//         .hour(16)
//         .minute(0)
//         .second(0)
//         .unix();
//     }

//     // If it's before the market open time, then check the previous day's market close time
//     if (currentTime.hour() < 9 || !isMarketOpen(currentTime.hour(16).unix())) {
//       currentTime.subtract(1, 'day');  // Go to previous day
//       currentTime.hour(16);             // Set to market close time
//     } else {
//       // If the market was closed today but it's after 9:30, go back to 4 PM and check again
//       currentTime.hour(16);
//     }

//     // Check if we've found a day the market was open at 4 PM
//     if (isMarketOpen(currentTime.unix())) {
//       return currentTime.unix();
//     }
//   }
// };

export const getNextExecutionTime = (previous: Date, interval: '1m' | '1h' | 'daily'): Date => {

  switch (interval) {
    case '1m': {
      // Add one minute to the previous time
      const nextMinute = new Date(previous.getTime() + 60000);

      // Check if the next minute is within market hours (9:30 am to 4:00 pm)
      if ((nextMinute.getHours() === 15 && nextMinute.getMinutes() < 59) || (nextMinute.getHours() >= 9 && nextMinute.getHours() < 15)) {
          return nextMinute;
      } else {
          // Find the next trading day
          let nextTradingDay = getRecentTradingDayStart();
          nextTradingDay.setDate(nextTradingDay.getDate() + 1);
          while (!isMarketOpen(nextTradingDay)) {
              nextTradingDay.setDate(nextTradingDay.getDate() + 1);
          }
          return new Date(nextTradingDay.setHours(9, 30, 0, 0));
      }
    }

    case '1h': {
      // Define market hours in NYC time
      const marketHours = [9.5, 10, 11, 12, 13, 14, 15, 15.983333]; // 3:59pm as 15.983333 hours
      let nextHour = marketHours.find(hour => hour > previous.getHours() + previous.getMinutes() / 60);

      // If no next hour is found on the same day, return 9:30am of the next trading day
      if (nextHour === undefined) {
          let nextDay = getRecentTradingDayStart();
          nextDay.setDate(nextDay.getDate() + 1);
          while (!isMarketOpen(nextDay)) {
              nextDay.setDate(nextDay.getDate() + 1);
          }
          return new Date(nextDay.setHours(9, 30, 0, 0));
      }

      // Return the next hour within the trading day
      return new Date(previous.setHours(Math.floor(nextHour), (nextHour % 1) * 60, 0, 0));
    }

    case 'daily': {
      // Start from the most recent trading day at 9:30am
      let nextTradingDay = getRecentTradingDayStart();
      nextTradingDay.setDate(nextTradingDay.getDate() + 1);

      // Find the next day the market is open
      while (!isMarketOpen(nextTradingDay)) {
          nextTradingDay.setDate(nextTradingDay.getDate() + 1);
      }

      return new Date(nextTradingDay.setHours(9, 30, 0, 0));
    }

    default:
        throw new Error('Invalid interval');
  }
}

// True if market opens within two hours
export const isMarketOpenOrWillItOpenSoon = () => {
  if (isMarketOpen()) return true
  const now_nyc = getNYCDate()
  const next_open_time = getNextExecutionTime(now_nyc, '1m')
  const minutes_until_open = (next_open_time.getTime() - new Date().getTime()) / 1000 / 60
  return minutes_until_open < 120
}

// "3:42pm" or "Tomorrow, 9:30am", or "Monday, 9:30am"
export const format_time = (date: Date) => {
  const now = new Date();
  const tomorrow = new Date(now);
  tomorrow.setDate(tomorrow.getDate() + 1);

  // Formatting function for the time part
  const formatAMPM = (date: Date) => {
      let hours = date.getHours();
      let minutes: any = date.getMinutes();
      const ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      return hours + ':' + minutes + ampm;
  }

  // Check if the date is today
  if (date.toDateString() === now.toDateString()) {
      return formatAMPM(date);
  }
  // Check if the date is tomorrow
  else if (date.toDateString() === tomorrow.toDateString()) {
      return `Tomorrow, ${formatAMPM(date)}`;
  }
  // Otherwise, use the day of the week
  else {
      return `${date.toLocaleString('en-US', { weekday: 'long' })}, ${formatAMPM(date)}`;
  }
}

export const human_price_to_number = (price: string): number => {
  return Number(price.replace(/[$,]/g, ''))
}

export const make_human_readable_indicator_label = (indicatorObj: any) => {
  const a = indicatorObj
  if (indicatorObj.type === 'SMA') {
    return `SMA(${a.chart},${a.period})`
  } else if (a.type === 'EMA') {
    return `EMA(${a.chart},${a.period})`
  } else if (a.type === 'VWAP') {
    return `VWAP(${a.chart},${a.period})`
  } else if (a.type === 'BOLL') {
    return `BOLL(${a.chart},${a.period},${a.width})`
  } else if (a.type === 'RSI') {
    return `RSI(${a.chart},${a.overbought},${a.oversold})`
  } else if (a.type === 'MACD') {
    return `MACD(${a.chart},${a.fastPeriod},${a.slowPeriod},${a.signalPeriod})`
  }
  throw new Error('Invalid indicator type')
}

// For use in query parameters (TSLA-SMA-1d-9)
export const encode_indicator = (symbol: string, indicatorObj: any) => {
  const a = indicatorObj
  if (indicatorObj.type === 'SMA') {
    return `${symbol}.SMA_${a.chart}_${a.period}`
  } else if (a.type === 'EMA') {
    return `${symbol}.EMA_${a.chart}_${a.period}`
  } else if (a.type === 'VWAP') {
    return `${symbol}.VWAP_${a.chart}_${a.period}`
  } else if (a.type === 'BOLL') {
    return `${symbol}.BOLL_${a.chart}_${a.period}_${a.width}`
  } else if (a.type === 'RSI') {
    return `${symbol}.RSI_${a.chart}_${a.overbought}_${a.oversold}`
  } else if (a.type === 'MACD') {
    return `${symbol}.MACD_${a.chart}_${a.fastPeriod}_${a.slowPeriod}_${a.signalPeriod}`
  }
  throw new Error('Invalid indicator type')
}

// NOTE: for now, must be kept manually up to date with tickerbot-backend/src/logic/helpers
export const decode_indicator = (indicatorString: string): any => {
  indicatorString = indicatorString.replace(/-/g, '_')    // TEMPORARY FIX

  // NOTE: when stable, remove handling of old case
  let firstParts: any = null
  let symbol = ''
  let parts: any = null
  if (indicatorString.includes('.')) {
    firstParts = indicatorString.split('.')
    symbol = firstParts[0]
    parts = firstParts[1].split('_')
  } else {
    console.log(`NOTE: indicatorString ${indicatorString} is of the old format`)
    parts = indicatorString.split('_')
    symbol = parts[0]
    parts = parts.slice(1)
  }

  const type = parts[0];
  const chart = parts[1]
  const indicatorObj: any = { symbol, type, chart };

  switch (type) {
    case 'SMA':
      indicatorObj.period = parts[2];
      break;
    case 'EMA':
      indicatorObj.period = parts[2];
      break;
    case 'VWAP':
      indicatorObj.period = parts[2];
      break;
    case 'BOLL':
      indicatorObj.period = parts[2];
      indicatorObj.width = parts[3];
      break;
    case 'RSI':
      indicatorObj.overbought = parts[2];
      indicatorObj.oversold = parts[3];
      break;
    case 'MACD':
      indicatorObj.fastPeriod = parts[2];
      indicatorObj.slowPeriod = parts[3];
      indicatorObj.signalPeriod = parts[4];
      break;
    case 'OHLC':
      break;
    default:
      throw new Error('Invalid indicator type');
  }
  return indicatorObj;
}

export const INTERVALS: { [key: string]: number } = {
  '1m': 60 * 1000,
  '5m': 5 * 60 * 1000,
  '15m': 15 * 60 * 1000,
  '30m': 30 * 60 * 1000,
  '1h': 60 * 60 * 1000,
  '2h': 2 * 60 * 60 * 1000,
  '4h': 4 * 60 * 60 * 1000,
  '1d': 24 * 60 * 60 * 1000,
  'open': 24 * 60 * 60 * 1000,
  '1w': 7 * 24 * 60 * 60 * 1000,
  '1mo': 30 * 24 * 60 * 60 * 1000,
  '3mo': 3 * 30 * 24 * 60 * 60 * 1000,
  '12mo': 12 * 30 * 24 * 60 * 60 * 1000,
}

type IntervalType = '1m' | '5m' | '15m' |'30m' | '1h' | '4h' | '1d' | '7d' | '1mo' | '3mo' | '12mo'

const HEX_COLORS = [
  "#FF5733",
  "#C70039",
  "#900C3F",
  "#581845",
  "#F1C40F",
  "#2ECC71",
  "#3498DB",
  "#9B59B6",
  "#E74C3C",
  "#34495E",
  "#1ABC9C",
  "#2C3E50",
  "#7F8C8D",
  "#F39C12",
  "#D35400",
  "#8E44AD",
  "#2980B9",
  "#16A085",
  "#27AE60",
  "#BDC3C7"
]

export const getRandomColor = () => {
  return HEX_COLORS[Math.floor(Math.random() * HEX_COLORS.length)]
}

export const DEFAULT_INDICATOR_COLORS = {
  SMA: '#ff5733',
  EMA: '#3498db',
  VWAP: '#ff5733',
  BOLL: '#1ed4b7',
  RSI: '#900c3f',
  MACD: '#ff6d01',
  MACD_SIGNAL: '#ffeb3b',
  MACD_HISTOGRAM_POSITIVE: GREEN,
  MACD_HISTOGRAM_NEGATIVE: RED,
}

export const valueForTable = (key: string, value: any, trim?: boolean): string => {
  if (value === null) {
    return '-';
  }

  if (!key) {
    console.log('error in valueForTable, key is undefined');
    return value;
  }

  // TODO ENSURE ALL KEYS THAT ARE % END IN PC
  if (key?.endsWith('pc')) {
    if (isNaN(value)) return '-';
    else return formatPercent(value);

  // BOOLEAN
  } else if (typeof value === 'boolean') {
    return value ? 'True' : 'False';

  // NUMBER
  } else if (!isNaN(Number(value))) {
    const numValue = parseFloat(value);
    return trim ? formatNumberForTableTrimmed(numValue) : formatNumberForTable(numValue);

  // DATE
  } else if (!isNaN(Date.parse(value))) {
    return formatDateForTable(value);

  // STRING
  } else if (typeof value === 'string') {
    return value;

  } else {
    // Handle other cases (e.g., non-numeric, non-date values)
    return '-';
  }
};

export const now = () => {
  return moment().format('MM/DD/YY h:mm:ss A');
};

export const formatNumberForTable = (number: number | null) => {
  if (number === null || isNaN(number) || number === undefined) {
    return '-';
  }

  const formattedNumber = number.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true
  });

  return formattedNumber;
};

// New function to handle "trim" logic
export const formatNumberForTableTrimmed = (number: number): string => {
  if (number === null || isNaN(number)) {
    return '-';
  }

  const options =
    number >= 1000
      ? { minimumFractionDigits: 0, maximumFractionDigits: 0, useGrouping: true }
      : { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: true };

  return number.toLocaleString(undefined, options);
};


export const formatDateForTable = (date: Date | string, includeSeconds?: boolean) => {
  if (date === null) {
    return '-'
  }
  if (includeSeconds) {
    return moment(date).format('MM/DD/YY h:mm:ss A')
  }
  return moment(date).format('MM/DD/YY hh:mm A')
}

// 'AAPL-1h-SMA-9', or 'algo.market_val' -> hex color
export const get_color_for_ds_tree_item = (item: string) => {
  return 'not implemented'
}

export const accentColors = [
  "#D32F2F", // Dark Red
  "#1976D2", // Dark Blue
  "#E53935", // Red
  "#FDD835", // Yellow
  "#9E579D", // Medium Purple
  "#43A047", // Green
  "#44BD32", // Shamrock Green
  "#1ABC9C", // Medium Sea Green
  "#039BE5", // Cyan
  "#273746", // Blue-Grey
  "#F57C00", // Tangerine
  "#7CB342", // Lime
  "#FF6F42", // Orange
  "#7B1FA2", // Dark Purple
  "#D35400", // Mahogany
  "#E040FB", // Violet
  "#FA8072", // Salmon
  "#2980B9", // Ocean Blue
  "#8E44AD", // Violet
  "#27AE60", // Jungle Green
  "#00897B", // Teal
  "#2C3E50",  // Midnight Blue
  "#D3545D", // Rose
  "#FBC02D", // Golden Yellow
  "#6D4C41", // Brown
  "#BA68C8", // Orchid
  "#9B59B6", // Amethyst
  "#16A085", // Sea Green
  "#1E88E5", // Blue
  "#D68910", // Bronze
  "#C2185B", // Deep Pink
  "#2874A6", // Steel Blue
  "#B03A2E",  // Brick Red
  "#E84393", // Medium Violet
  "#C2185B", // Magenta
  "#6E2C00", // Brown Sugar
  "#F4511E", // Vermilion
  "#641E16", // Maroon
  "#8E24AA", // Purple
  "#F0E68C", // Khaki
  "#8C9EFF", // Lavender
  "#E67E22", // Carrot
  "#388E3C", // Dark Green
  "#E74C3C", // Coral
  "#E64A19", // Deep Orange
  "#DC7633", // Cinnamon
  "#0288D1", // Dark Cyan
  "#F39C12", // Pumpkin Orange
  "#76D7C4", // Turquoise Lagoon
  "#26C6DA", // Turquoise
  "#FB8C00", // Amber
  "#689F38", // Olive Green
  "#D81B60", // Pink
  "#C62828", // Chili Red
  "#FB8C00", // Amber
  "#FFD700", // Gold
  "#3498DB", // Sky Blue
  "#5499C7", // Soft Blue
  "#E17055", // Terracotta
  "#546E7A"  // Slate Blue
  // "#E53935", // Red
  // "#8E24AA", // Purple
  // "#1E88E5", // Blue
  // "#43A047", // Green
  // "#FDD835", // Yellow
  // "#FF6F42", // Orange
  // "#D81B60", // Pink
  // "#039BE5", // Cyan
  // "#F4511E", // Vermilion
  // "#7CB342", // Lime
  // "#C2185B", // Magenta
  // "#00897B", // Teal
  // "#FB8C00", // Amber
  // "#8C9EFF", // Lavender
  // "#6D4C41", // Brown
  // "#E040FB", // Violet
  // "#26C6DA", // Turquoise
  // "#FFAB91", // Melon
  // "#BA68C8", // Orchid
  // "#546E7A", // Slate Blue
  // "#D32F2F", // Dark Red
  // "#7B1FA2", // Dark Purple
  // "#1976D2", // Dark Blue
  // "#388E3C", // Dark Green
  // "#FBC02D", // Golden Yellow
  // "#E64A19", // Deep Orange
  // "#C2185B", // Deep Pink
  // "#0288D1", // Dark Cyan
  // "#C62828", // Chili Red
  // "#689F38", // Olive Green
  // "#8E44AD", // Violet
  // "#F39C12", // Pumpkin Orange
  // "#D35400", // Mahogany
  // "#27AE60", // Jungle Green
  // "#16A085", // Sea Green
  // "#2980B9", // Ocean Blue
  // "#E74C3C", // Coral
  // "#3498DB", // Sky Blue
  // "#F57C00", // Tangerine
  // "#2C3E50"  // Midnight Blue
]

export const round_to_decimal_places = (number_to_round: number, decimal_places: number) => {
  let factor = Math.pow(10, decimal_places)
  return Math.round(number_to_round * factor) / factor
}


export const format_date_range = (start: Date, end: Date) => {
  const isIntraDay = start.toDateString() === end.toDateString()

  // Helper function to get the day of the week
  const getDayOfWeek = (date: Date) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    return days[date.getDay()]
  };

  // Modified helper function to format date as "Day MM/DD/YY" (e.g., "Mon 11/06/23")
  const formatDate = (date: Date) => {
    const nycDate = convertToNYCDate(date)
    const dayOfWeek = getDayOfWeek(nycDate)
    return `${dayOfWeek} ${nycDate.getMonth() + 1}/${nycDate.getDate()}/${nycDate.getFullYear().toString().slice(-2)}`
  };

  // Helper function to format time as h:mma (e.g., 9:30a)
  const formatTime = (date: Date) => {
    const nycDate = convertToNYCDate(date)
    let hours = nycDate.getHours()
    let minutes: any = nycDate.getMinutes()
    const ampm = hours >= 12 ? 'p' : 'a'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    return `${hours}:${minutes}${ampm}`
  };

  if (isIntraDay) {
    // For intraday, format both date and time
    const startDateFormatted = formatDate(start)
    return `${startDateFormatted}`
  } else {
    // For non-intraday, format only the dates
    const startDateFormatted = formatDate(start)
    const endDateFormatted = formatDate(end)
    return `${startDateFormatted} - ${endDateFormatted}`
  }
}

export const humanDateForFirebaseDate = (date: any, includeSeconds = false) => {
  if (!date) return '-';

  const d = new Date(date.seconds * 1000);

  // Extract the individual date components
  const optionsTime: any = {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZone: 'America/New_York',
  };

  if (includeSeconds) {
    optionsTime.second = '2-digit';
  }

  const optionsDate: any = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    timeZone: 'America/New_York',
  };

  const optionsTimeZone: any = {
    timeZoneName: 'short',
    timeZone: 'America/New_York',
  };

  let timeString = d.toLocaleTimeString('en-US', optionsTime);
  // Remove the space before "AM" or "PM"
  // timeString = timeString.replace(/ (?=AM|PM)/i, '').toLowerCase();

  const dateString = d.toLocaleDateString('en-US', optionsDate);
  // const timeZoneString = d.toLocaleTimeString('en-US', optionsTimeZone).split(' ')[2];

  // Assemble the strings to get your desired format
  // Note: The timeZoneString is removed as per the provided function body
  // If you wish to include it, uncomment the following line and comment out the one after that:
  // const formattedDate = `${timeString}, ${dateString} ${timeZoneString}`;
  // const formattedDate = `${timeString}, ${dateString}`;
  const formattedDate = `${dateString}, ${timeString}`;

  return formattedDate;
}

// export const period_string = (fixedTimeframe: string, stepBack: number) => {
//   if (!fixedTimeframe) return 'This period'
//   if (fixedTimeframe === '1D') {
//     if (stepBack === 0) return 'Today'
//     else if (stepBack === 1) return 'Yesterday'
//     else return `${stepBack} days ago`
//   } else if (fixedTimeframe === '1W') {
//     if (stepBack === 0) return 'This week'
//     else if (stepBack === 1) return 'Last week'
//     else return `${stepBack} weeks ago`
//   } else if (fixedTimeframe === '1M') {
//     if (stepBack === 0) return 'This month'
//     else if (stepBack === 1) return 'Last month'
//     else return `${stepBack} months ago`
//   } else if (fixedTimeframe === '6M') {
//     if (stepBack === 0) return 'This half-year'
//     else if (stepBack === 1) return 'Last half-year'
//     else return `${stepBack} half-years ago`
//   } else if (fixedTimeframe === '1Y') {
//     if (stepBack === 0) return 'This year'
//     else if (stepBack === 1) return 'Last year'
//     else return `${stepBack} years ago`
//   } else if (fixedTimeframe === 'All') {
//     return 'All time'
//   }
// }

export const period_string = (start_date_iso: string, end_date_iso: string, interval: string) => {
  if (start_date_iso === undefined || end_date_iso === undefined) {
    return "This period";
  }

  const interval_ms = INTERVALS[interval];

  const startDate = new Date(start_date_iso);
  const endDate = new Date(new Date(end_date_iso).getTime() + interval_ms);
  const now = new Date();
  const fiveMinutesAgo = new Date(now.getTime() - 5 * 60000);
  const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);

  const formatTime = (date: Date) => date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' }).toLowerCase();
  const formatDate = (date: Date) => date.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: '2-digit' });
  const formatDayTime = (date: Date) => `${date.toLocaleDateString('en-US', { weekday: 'short' })} ${formatTime(date)}`;

  const isMarketClosedSince = (date: Date): boolean => {
    let checkTime = new Date(date.getTime() + 5 * 60000); // Add 5 minutes safety margin
    while (checkTime <= now) {
      if (isMarketOpen(checkTime.getTime())) {
        return false;
      }
      checkTime.setTime(checkTime.getTime() + 60 * 60000); // Move forward by 1 hour
    }
    return true;
  };

  let start = "";
  let end = "";

  // Handle end date
  if (endDate >= fiveMinutesAgo || isMarketClosedSince(endDate)) {
    end = "present";
  } else if (endDate > oneWeekAgo) {
    end = formatDayTime(endDate);
  } else {
    end = formatDate(endDate);
  }

  // Handle start date
  if (startDate > oneWeekAgo) {
    start = formatDayTime(startDate);
  } else {
    start = formatDate(startDate);
  }

  // Special case: both dates within last 5 minutes
  if (startDate >= fiveMinutesAgo && endDate >= fiveMinutesAgo) {
    return formatTime(startDate) + " - now";
  }

  return `${start} - ${end}`;
};

export const user_is_admin = (user: User) => {
  return user.email.split('@')[1] === 'tickerbot.io'
}

export const positionIndicatorObjArry = [
  {
    path: 'symbol',
    description: 'Asset symbol',
    hasChart: false,
  },
  {
    path: 'asset_class',
    description: 'Asset class',
    hasChart: false,
  },
  {
    path: 'exchange',
    description: 'Exchange name of the asset.',
    hasChart: false,
  },
  {
    path: 'qty',
    description: 'The number of shares',
    hasChart: true,
    wholeNumber: true,
  },
  {
    path: 'last_price',
    description: 'Last day’s asset price per share based on the closing value of the last trading day',
    hasChart: false,
  },
  {
    path: 'day_price_change',
    description: 'Change from last day price',
    hasChart: false,    // bar chart
  },
  {
    path: 'day_price_change_pc',
    description: 'Percent change from last day price (by a factor of 1)',
    hasChart: false,     // bar chart
  },
  {
    path: 'mkt_value',
    description: 'Total market value of the position',
    hasChart: true,
  },
  {
    path: 'day_unrealized_pl',
    description: 'Unrealized profit/loss for the day',
    hasChart: false,    // bar chart
  },
  {
    path: 'day_unrealized_pl_pc',
    description: 'Unrealized profit/loss percent for the day (by a factor of 1)',
    hasChart: false,  // bar chart
  },
  {
    path: 'unrealized_pl',
    description: 'Unrealized profit/loss',
    hasChart: true,
  },
  {
    path: 'unrealized_pl_pc',
    description: 'Unrealized profit/loss percent (by a factor of 1)',
    hasChart: true,
  },
  {
    path: 'avg_entry_price',
    description: 'Average entry price of the position',
    hasChart: true,
  },
  {
    path: 'cost_basis',
    description: 'Total cost basis.',
    hasChart: true,
  },
];

export const techIndicatorObjArr = [
  {
    path: 'volume',
    description: 'The number of shares traded in a given period.',
    hasChart: true,
  },
  {
    path: 'SMA',
    description: 'Moving average (MA) shows the average closing price over a period of time. It’s often used to track price trends. The period is defined by a number of intervals. The length of an interval depends on the span of the chart.',
    hasChart: true,
  },
  {
    path: 'EMA',
    description: 'Exponential moving average (EMA) shows the average closing price over a time period. Unlike the moving average (MA), it adds more weight to recent price data.',
    hasChart: true,
  },
  {
    path: 'VWAP',
    description: 'Volume-weighted average price (VWAP) shows the average price over a time period, adjusting for volume. Periods with higher trading volume will impact VWAP more than periods with lower trading volume.',
    hasChart: true,
  },
  {
    path: 'BOLL',
    description: 'Bollinger Bands (BOLL) creates two bands around a moving average (MA). Each is usually 2 standard deviations away from the MA.',
    hasChart: true,
  },
  {
    path: 'RSI',
    description: 'Relative strength index (RSI) uses the magnitude of recent changes in price to evaluate whether an asset is overbought or oversold. Our RSI uses Wilder’s smoothing, which helps filter out price fluctuations to make it easier to spot trends.',
    hasChart: true,
  },
  {
    path: 'MACD',
    description: 'Moving average convergence divergence (MACD) shows the difference between two exponential moving averages (EMAs): one short-term (the “fast” EMA), and one long-term (the “slow” EMA). This is plotted against a “signal line” (usually the 9-day EMA).',
    hasChart: true,
  }
]
export const hasUnsavedVisibleStrategies = (user, strategiesWithUnsavedChanges) => {
  if (!strategiesWithUnsavedChanges || !Object.keys(strategiesWithUnsavedChanges).length) {
    return false;
  }

  const context = returnPaneContextObj(user);
  const strategiesVisible = context?.visiblePaneThings
    .filter(p => p.startsWith('strategy.'))
    .map(s => s.split('.')[1]) || [];

  return Object.keys(strategiesWithUnsavedChanges).some(strategy =>
    strategiesVisible.includes(strategy)
  );
};

export const set_open_pane = async (user: any, uld: any, paneNo: number, paneThing: string | null, strategiesWithUnsavedChanges: {[id: Strategy['id']]: any}, setShowDiscardOrSaveModal: (args: DiscardOrSaveModalState) => void) => {

  if (hasUnsavedVisibleStrategies(user, strategiesWithUnsavedChanges)) {
    setShowDiscardOrSaveModal({
      showModal: true,
      paneThing: paneThing ? paneThing : '',
    });
    return;
  }

  const docRef = db.collection('users').doc(user.uid)

  if (paneNo === 1) await docRef.update({paneSettingsCH1: paneThing})
  else if (paneNo === 2) await docRef.update({paneSettingsCH2: paneThing})
  else if (paneNo === 3) await docRef.update({paneSettingsCH3: paneThing})
  else if (paneNo === 4) await docRef.update({paneSettingsCH4: paneThing})

  if (paneThing?.startsWith('table.') || paneThing?.startsWith('strategy.')) return

  // Update symbolsFollowing and needsStreamingData on uld
  let symbolsFollowing = uld.symbolsFollowing || []
  const symbolsInActiveCharts = [
    uld.paneSettingsCH1,
    uld.paneSettingsCH2,
    uld.paneSettingsCH3,
    uld.paneSettingsCH4
  ]
  symbolsFollowing = symbolsFollowing.filter((symbol: string) => symbolsInActiveCharts.includes(symbol))
  symbolsFollowing.push(paneThing)
  const uldRef = db.collection('userLiveDocs').doc(user.uid)
  await uldRef.update({
    symbolsFollowing,
    needsStreamingData: true,
    lastSeen: firebase.firestore.FieldValue.serverTimestamp()
  })
}

export const format_price = (price: any): string => {
  price = Number(price)
  if (isNaN(price)) return ''
  if (price < 1000 && price > -1000) {
    return price.toFixed(2)
  } else {
    return price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
}


export const topStockChartsObjArr = [
  { symbol: "AAPL", name: "Apple Inc.", exchange: "NASDAQ", class: "us_equity" },
  { symbol: "AMZN", name: "Amazon.com Inc.", exchange: "NASDAQ", class: "us_equity" },
  { symbol: "GOOG", name: "Alphabet Inc. (Class A)", exchange: "NASDAQ", class: "us_equity" },
  { symbol: "MSFT", name: "Microsoft Corporation", exchange: "NASDAQ", class: "us_equity" },
  { symbol: "TSLA", name: "Tesla, Inc.", exchange: "NASDAQ", class: "us_equity" },
  { symbol: "META", name: "Meta Platforms, Inc.", exchange: "NASDAQ", class: "us_equity" },
  { symbol: "JNJ", name: "Johnson & Johnson", exchange: "NYSE", class: "us_equity" },
  { symbol: "JPM", name: "JPMorgan Chase & Co.", exchange: "NYSE", class: "us_equity" },
  { symbol: "V", name: "Visa Inc.", exchange: "NYSE", class: "us_equity" },
  { symbol: "PG", name: "Procter & Gamble Company", exchange: "NYSE", class: "us_equity" },
  { symbol: "NVDA", name: "NVIDIA Corporation", exchange: "NASDAQ", class: "us_equity" },
  { symbol: "UNH", name: "UnitedHealth Group Incorporated", exchange: "NYSE", class: "us_equity" },
  { symbol: "HD", name: "The Home Depot, Inc.", exchange: "NYSE", class: "us_equity" },
  { symbol: "MA", name: "Mastercard Incorporated", exchange: "NYSE", class: "us_equity" },
  { symbol: "DIS", name: "The Walt Disney Company", exchange: "NYSE", class: "us_equity" },
  { symbol: "PYPL", name: "PayPal Holdings, Inc.", exchange: "NASDAQ", class: "us_equity" },
  { symbol: "BAC", name: "Bank of America Corporation", exchange: "NYSE", class: "us_equity" },
  { symbol: "INTC", name: "Intel Corporation", exchange: "NASDAQ", class: "us_equity" },
  { symbol: "CMCSA", name: "Comcast Corporation", exchange: "NASDAQ", class: "us_equity" },
  { symbol: "ADBE", name: "Adobe Inc.", exchange: "NASDAQ", class: "us_equity" },
  { symbol: "NFLX", name: "Netflix, Inc.", exchange: "NASDAQ", class: "us_equity" },
  { symbol: "CRM", name: "Salesforce.com, Inc.", exchange: "NYSE", class: "us_equity" },
  { symbol: "ABT", name: "Abbott Laboratories", exchange: "NYSE", class: "us_equity" },
  { symbol: "NKE", name: "NIKE, Inc.", exchange: "NYSE", class: "us_equity" },
  { symbol: "KO", name: "The Coca-Cola Company", exchange: "NYSE", class: "us_equity" },
  { symbol: "PEP", name: "PepsiCo, Inc.", exchange: "NASDAQ", class: "us_equity" },
  { symbol: "T", name: "AT&T Inc.", exchange: "NYSE", class: "us_equity" },
  { symbol: "MRK", name: "Merck & Co., Inc.", exchange: "NYSE", class: "us_equity" },
  { symbol: "ABNB", name: "Airbnb, Inc.", exchange: "NASDAQ", class: "us_equity" },
  { symbol: "NOW", name: "ServiceNow, Inc.", exchange: "NYSE", class: "us_equity" },
  { symbol: "ZM", name: "Zoom Video Communications, Inc.", exchange: "NASDAQ", class: "us_equity" },
  { symbol: "SQ", name: "Square, Inc.", exchange: "NYSE", class: "us_equity" },
  { symbol: "ROKU", name: "Roku, Inc.", exchange: "NASDAQ", class: "us_equity" },
  { symbol: "SHOP", name: "Shopify Inc.", exchange: "NYSE", class: "us_equity" },
  { symbol: "UBER", name: "Uber Technologies, Inc.", exchange: "NYSE", class: "us_equity" },
  { symbol: "LYFT", name: "Lyft, Inc.", exchange: "NASDAQ", class: "us_equity" },
  { symbol: "CRWD", name: "CrowdStrike Holdings, Inc.", exchange: "NASDAQ", class: "us_equity" },
  { symbol: "SNOW", name: "Snowflake Inc.", exchange: "NYSE", class: "us_equity" },
  { symbol: "DOCU", name: "DocuSign, Inc.", exchange: "NASDAQ", class: "us_equity" },
  { symbol: "NET", name: "Cloudflare, Inc.", exchange: "NYSE", class: "us_equity" },
  { symbol: "PLTR", name: "Palantir Technologies Inc.", exchange: "NYSE", class: "us_equity" },
];

export const updateLiveData = async (user: any) => {
  if (!config) return null;
  //@ts-ignore
  if (!user) user = window.user         // for usage from window
  const token = await currentUser()?.getIdToken();
  const before = new Date().getTime();
  await axios.post(`${config.api_root_url}runTick`, {
    updateTransfers: true,
    updateOrders: true
  }, {
    headers: {
      'Authorization': `Bearer ${token}`
    },
    params: {
      mode: 'dataOnly',
      userId: user.uid
    }
  });
  // console.log(`Loaded liveData in ${new Date().getTime() - before}ms to update live data`);
};
//@ts-ignore
window.updateLiveData = updateLiveData;


/*
Timed out status is maintained on the backend, however in order to do so we
need to know whether the user is still around so as to know whether to update lastSeen
*/
export const registerUsage = () => {
  //@ts-ignore
  window.LAST_SEEN = new Date()
}

export const haveWeTimedOut = () => {
  //@ts-ignore
  return new Date() - window.LAST_SEEN > 1000 * 60 * 15
}

export const returnPaneContextObj = (user?: User, paneThing?: string) => {
  if (!user) return null
  let visiblePaneThings: string[] = []
  let paneThingIsVisible = false
  let panesWherePaneThingIsVisible: number[] = []

  if (user.paneSettingsConfig === '1') {
    visiblePaneThings.push(user.paneSettingsCH1)
    if (user.paneSettingsCH1 === paneThing) {
      panesWherePaneThingIsVisible.push(1)
    }
  }

  if (user.paneSettingsConfig === '2-one-top-one-bottom') {
    visiblePaneThings.push(user.paneSettingsCH1)
    visiblePaneThings.push(user.paneSettingsCH2)
    if (user.paneSettingsCH1 === paneThing) {
      panesWherePaneThingIsVisible.push(1)
    }
    if (user.paneSettingsCH2 === paneThing) {
      panesWherePaneThingIsVisible.push(2)
    }
  }

  if (user.paneSettingsConfig === '4') {
    visiblePaneThings.push(user.paneSettingsCH1)
    visiblePaneThings.push(user.paneSettingsCH2)
    visiblePaneThings.push(user.paneSettingsCH3)
    visiblePaneThings.push(user.paneSettingsCH4)
    if (user.paneSettingsCH1 === paneThing) {
      panesWherePaneThingIsVisible.push(1)
    }
    if (user.paneSettingsCH2 === paneThing) {
      panesWherePaneThingIsVisible.push(2)
    }
    if (user.paneSettingsCH3 === paneThing) {
      panesWherePaneThingIsVisible.push(3)
    }
    if (user.paneSettingsCH4 === paneThing) {
      panesWherePaneThingIsVisible.push(4)
    }
  }

  if (paneThing) {
    paneThingIsVisible = visiblePaneThings.includes(paneThing)
  }
  const uniqueSet = new Set(panesWherePaneThingIsVisible);
  panesWherePaneThingIsVisible = [...uniqueSet];

  const contextObj = {
    visiblePaneThings,
    paneThingIsVisible,
    panesWherePaneThingIsVisible
  }

  return contextObj
}

export const kycDocTable = {
  'IDENTITY_VERIFICATION': 'Government issued passport or license (front and back).',
  'TAX_IDENTIFICATION': 'Tax ID card.',
  'ADDRESS_VERIFICATION': 'A lease, bank statement, or utility bill from the last 30 days displaying your full name and address.',
  'DATE_OF_BIRTH': 'Government issued passport or license (front and back).',
  'SELFIE_VERIFICATION': 'Selfie.',
  'PEP': 'Job title / occupation and address.',
  'FAMILY_MEMBER_PEP': 'Name of politically exposed person if immediate family.',
  'CONTROL_PERSON': 'Company name, company address, and company email.',
  'AFFILIATED': 'Company / firm name, company / firm address, company / firm email.',
  'VISA_TYPE_OTHER': 'Visa type and expiration date.',
  'W8BEN_CORRECTION': 'An updated W8BEN form with corrected information.',
  'OTHER': 'Please contact our support team.'
}

export const array_compare = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort()
  const sortedArr2 = arr2.slice().sort()
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false
    }
  }
  return true
}

export const format_price_appropriately = (price: any) => {
  if (price === undefined) return ''
  if (typeof price === 'string') {
    price = Number(price)
  }
  // Check if the price is a five-figure number or more
  if (price >= 10000) {
      // Format the price with commas but no decimal places
      return price.toLocaleString('en-US', {
          maximumFractionDigits: 0
      });
  } else {
      // Format the price with commas and two decimal places
      return price.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
      });
  }
}

export const createEELog = async (db: any, uid: string, opType: any, console_output: any) => {
  if (!config) return null
  const token = await currentUser()?.getIdToken()
  const createEELogEndpoint = `${config?.api_root_url}createEELog`
  const data = {
    userId: uid,
    opType,
    console_output
  }
  await axios.post(createEELogEndpoint, data, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
}

export const barISOToNYCHuman = (isoDatetime: string) => {
  if (!isoDatetime) return '-'
  const date = new Date(isoDatetime);
  const options: any = {
    timeZone: 'America/New_York',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  }
  const formatter = new Intl.DateTimeFormat('en-US', options)
  return formatter.format(date)
}

export const getTradingDayOpenBeforeTime = (date: Date) => {
  const newYorkTime = moment.tz(date, 'America/New_York');

  // Set time to 9:30 AM
  newYorkTime.set({ hour: 9, minute: 30, second: 0, millisecond: 0 });

  // Adjust to the previous trading day if market is not open
  do {
    newYorkTime.subtract(1, 'day');
    // Skip weekends
    if (newYorkTime.day() === 0) {
      newYorkTime.subtract(2, 'days'); // Sunday -> Friday
    } else if (newYorkTime.day() === 6) {
      newYorkTime.subtract(1, 'day'); // Saturday -> Friday
    }
  } while (!isMarketOpen(newYorkTime));

  return newYorkTime.toDate();
};

// Rounds stock position to reasonable number of sig figs based on stock price
export const roundToSigFigs = (position: any, stockPrice: any) => {
  if (position === null) return null

  position = Number(position)
  if (position % 1 === 0) return position

  const pos_val = position * stockPrice
  const THRESHOLD = 0.10        // dollar amount error tolerable

  const round = (num: number, decimals: number) => {
    const factor = Math.pow(10, decimals)
    return Math.round(num * factor) / factor
  }

  for (let decimals = 0; decimals < 10; decimals++) {
    const position_rounded = round(position, decimals)
    const candidate_val = position_rounded * stockPrice
    const difference = Math.abs(candidate_val - pos_val)
    if (difference < THRESHOLD) {
      return position_rounded
    }
  }
}

export const is_internal = (symbol: string) => {
  let ret = false
  let symbol_pieces = symbol.split('.');
  if (symbol_pieces.length > 1) {
    if (['BALANCES', 'STATE'].includes(symbol_pieces[0])) {
      ret = true;
    }
  }
  return ret
}

// export const isWeekendOrHoliday = (date: Date) => {
//   const day = date.getDay();
//   const startOfDayTimestamp = date.setHours(0, 0, 0, 0);
//   return day === 0 || day === 6 || HOLIDAYS.includes(startOfDayTimestamp);
// };


export const timeAgo = (date: Date) => {
  const now = moment()
  const inputDate = moment(date)

  const diffSeconds = now.diff(inputDate, 'seconds')
  const diffMinutes = now.diff(inputDate, 'minutes')
  const diffHours = now.diff(inputDate, 'hours')
  const diffDays = now.diff(inputDate, 'days')
  const diffWeeks = now.diff(inputDate, 'weeks')
  const diffMonths = now.diff(inputDate, 'months')
  const diffYears = now.diff(inputDate, 'years')

  if (diffSeconds < 60) {
    return `${diffSeconds} second${diffSeconds > 1 ? 's' : ''} ago`
  } else if (diffMinutes < 60) {
    return `${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago`
  } else if (diffHours < 24) {
    return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`
  } else if (diffDays === 1) {
    return 'yesterday'
  } else if (diffDays < 7) {
    return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`
  } else if (diffWeeks === 1) {
    return 'last week'
  } else if (diffWeeks < 4) {
    return `${diffWeeks} week${diffWeeks > 1 ? 's' : ''} ago`
  } else if (diffMonths === 1) {
    return 'last month'
  } else if (diffMonths < 12) {
    return `${diffMonths} month${diffMonths > 1 ? 's' : ''} ago`
  } else if (diffYears === 1) {
    return 'last year'
  } else {
    return `${diffYears} year${diffYears > 1 ? 's' : ''} ago`
  }
}

export const create_daily_log = async () => {
  if (!config) return null
  //@ts-ignore
  const user = window.user
  console.log('Creating daily log...')
  try {
    const token = await currentUser()?.getIdToken();
    await axios.post(`${config.api_root_url}createEELog`, {
      userId: user.uid,
      opType: 'daily_log',
      console_output: [{className: 'log-item', text: '(manually created daily log)'}],
      manualOpData: {}
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      params: {
        // mode: 'dataOnly',
      }
    });
    console.log('Daily log created')
  } catch(err) {
    console.log('Failed creating daily log')
    console.log(err)
  }
}
//@ts-ignore
window.create_daily_log = create_daily_log

export const handleMUISorting = (v1: string | number | Date, v2: string | number | Date): number => {
  // Handle '-' values
  if (v1 === '-' && v2 !== '-') {
    return -1;
  }
  if (v1 !== '-' && v2 === '-') {
    return 1;
  }

  // Custom sorting for dates
  if (typeof v1 === 'string' && typeof v2 === 'string' && Date.parse(v1) && Date.parse(v2)) {
    return new Date(v1).getTime() - new Date(v2).getTime();
  }

  // Default sorting logic for numbers and strings
  return v1?.toString?.().localeCompare(v2?.toString?.(), undefined, { numeric: true });
};

export const is_order_working = (order: any) => {
  if (!['filled', 'canceled', 'expired'].includes(order.status)) {
    return true
  }
  return false
}

export const set_phantom_log = (text: string, uld: UserLiveDoc, setPhantomLog) => {
  let latest_log_id = '-'
  if (uld.cachedEELogs && uld.cachedEELogs.length) {
    const most_recent_log = uld.cachedEELogs[uld.cachedEELogs.length - 1]
    latest_log_id = most_recent_log.id
  }
  setPhantomLog({latest_log_id, text})
}


// For debugging
//@ts-ignore
window.CONTEXTMENU_OFF = false
//@ts-ignore
window.contextmenu_off = () => {
  //@ts-ignore
  window.CONTEXTMENU_OFF = true
}
export const get_contextmenu_off = () => {
  //@ts-ignore
  return window.CONTEXTMENU_OFF
}