import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Input } from '../reusable/Input'
import { Modal } from '../reusable'
import { updateLiveData, set_phantom_log } from '../../logic/u'
import {
  loggedInUserAtom,
  openModalAtom,
  ordersAtom,
  phantomLogAtom,
  userLiveDocAtom
} from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { currentUser, db } from '../../firebase'
import { getConfig } from '../../config'
import axios from 'axios'
import { TickerModal } from './TickerModal'
const config = getConfig() as any


interface ClosePositionModalProps {
  symbol: string
  showBack?: boolean
}

export const ClosePositionModal = (props: ClosePositionModalProps) => {
  const [ user ] = useAtom(loggedInUserAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [orders, setOrders] = useAtom(ordersAtom)
  const [ , setPhantomLog] = useAtom(phantomLogAtom)
  const [ uld ] = useAtom(userLiveDocAtom)

  const {symbol} = props

  if (!user || !uld) return null

  return (
    <Modal
      title={`Close position?`}
      contents={[
        <div className='column width-full font-size-13 orange-text'>
          <div className=''>
            All shares of <span className='bold'>{symbol}</span> will be sold and all its open orders will be canceled.
          </div>
        </div>
      ]}
      yesButtonText={`Close position`}
      isDanger
      twoStepConfirmation={true}
      isButtonLoading={buttonIsLoading}
      backIconOnClick={props.showBack ? () => setOpenModal(props.symbol ? <TickerModal symbol={props.symbol} /> : null) : null}
      onYes={ async () => {
        try {
          set_phantom_log(`Closing ${symbol} position...`, uld, setPhantomLog)
          setOpenModal(null)

          // setButtonIsLoading(true)
          const token = await currentUser()?.getIdToken()
          const ordersToCancel = orders.working.filter((o: any) => o.symbol === symbol) || []
          const openOrderIds = ordersToCancel.map((o: any) => {
            return o.id
          })
          if (openOrderIds.length) {
            for (const id of openOrderIds) {
              const url = `${config.api_root_url}deleteOrder?orderId=${id}&create_ee_log=true`
              await axios.delete(url, { headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              } })
            }
          }
          updateLiveData(user)

          const closePositionUrl = `${config.api_root_url}closePosition?symbol=${symbol}&create_ee_log=true`
          await axios.delete(closePositionUrl, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          updateLiveData(user)
          // setButtonIsLoading(false)
          // setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}

