/*
SignUp page
*/

import './SignUp.scss'
import React, { useState, useEffect } from 'react'
import { Input, Button } from '../reusable'
import { db, createUserWithEmailAndPassword, currentUser } from '../../firebase'
import { ErrorModal } from '../modals/ErrorModal'
import {loggedOutTabAtom, openModalAtom } from '../../types/global_types'
import { SignUpStage, WatchlistObj  } from '../../types/user_types'
import { useAtom } from 'jotai'
import { uuid } from '../../logic/u'



interface SignUpProps {
  subscribeToUserUpdates: (string) => void
}

export function SignUp(props: SignUpProps) {
  const [, setLoggedOutTab] = useAtom(loggedOutTabAtom)
  const [, setOpenModal] = useAtom(openModalAtom)

  // This signup stage
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState<boolean>(false)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)

  const waitListStage: SignUpStage = 'WaitlistConfirmation'

  useEffect(() => {
    setButtonEnabled(!!email && !!password && !passwordError);
  }, [email, password, passwordError]);

  return (
    <div className='logged-out-screen'>
      <div className='signup-container'>
        <div className='signup-form-container'>
          <div className='signup-context-title text-align-left'>
            Sign up for Tickerbot
          </div>
          <div className='signup-context-subtitle text-align-left'>
            Join the waitlist as we scale our systems.
          </div>
          <Input
            label={'Email'}
            type={'email'}
            // placeholder={'john.doe@example.com'}
            className={'margin-top-20'}
            style={{width: '100%'}}
            onChange={(val) => {setEmail(val)}}
          />
          <Input
            label={'Password'}
            type={'password'}
            className={'margin-top-20'}
            style={{width: '100%'}}
            onChange={(val) => {setPassword(val)}}
            isError={(val) => {
              // TODO ADD BACK IN
              // if (val.length < 20) {
              //   setPasswordError(true)
              //   return 'PASSWORD MUST BE AT LEAST 20 CHARACTERS LONG'
              // }
              setPasswordError(false)
            }}
          />
          <Button
            display={'Join waitlist'}
            className={'margin-top-20'}
            style={{width: '100%', paddingTop: '10px', paddingBottom: '10px'}}
            isDisabled={!buttonEnabled || buttonIsLoading}
            isLoading={buttonIsLoading}
            onClick={async () => {
              setButtonIsLoading(true);

              if (passwordError) {
                setButtonIsLoading(false);
                return;
              }

              // Create user with email and password
              const res = await createUserWithEmailAndPassword({ email, password });

              // Handle error
              if (res.error) {
                setButtonIsLoading(false)
                setOpenModal(<ErrorModal errorMessage={res.error} />);
                return
              }

              // Send to waitlist, which will handle whether to show the waitlist or the form
              try {
                const user = currentUser()
                props.subscribeToUserUpdates(user?.uid)
                const defaultWatchlist: WatchlistObj = {
                  userId: user ? user.uid : '',
                  id: uuid(),
                  createdAt: new Date(),
                  updatedAt: new Date(),
                  name: 'my_tickers',
                  tickers: ['AAPL', 'NVDA', 'TSLA'],
                  isDefault: true
                }                
                await db.collection('users').doc(user?.uid).update({
                  signUpStage: waitListStage,
                  tbVersion: 6,
                  charts: {
                    'AAPL': {
                      symbol: 'AAPL',
                      display: 'AAPL',
                      annotations: {},
                      indicators: {}
                    },
                    'AMZN': {
                      symbol: 'AMZN',
                      display: 'AMZN',
                      annotations: {},
                      indicators: {}
                    },
                    'MSFT': {
                      symbol: 'MSFT',
                      display: 'MSFT',
                      annotations: {},
                      indicators: {}
                    },
                    'NVDA': {
                      symbol: 'NVDA',
                      display: 'NVDA',
                      annotations: {},
                      indicators: {}
                    },
                    'TSLA': {
                      symbol: 'TSLA',
                      display: 'TSLA',
                      annotations: {},
                      indicators: {}
                    },                                       
                  },
                  variables: {},
                  watchlists: [defaultWatchlist],
                  selectedWatchlistIdHomeTab: defaultWatchlist.id,
                  paneSettingsConfig: '1',
                  paneSettingsCH1: 'AAPL',
                  'onboardingChecklist.hasBrokerageAccount': true,
                  'onboardingChecklist.hasBankAccount': true,
                  'onboardingChecklist.hasTickersInWatchlist': true,                  
                  // these things dont actually happen here but we set here for convenience
                })
                setButtonIsLoading(false)
              } catch (err) {
                setButtonIsLoading(false);
                setOpenModal(<ErrorModal errorMessage={err.message} />);
              }
            }}

          />
          <div className='signup-print'>
            Already have an account?
            <span
              className='text-button margin-left-10'
              onClick={() => {setLoggedOutTab('log_in')}}
            >
              Log in
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}




