

import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Input } from '../reusable/Input'
import { Checkbox, Modal } from '../reusable'
import { createEELog, set_open_pane, updateLiveData, uuid } from '../../logic/u'
import { DropdownSelect } from '../reusable/DropdownSelect'
import { loggedInUserAtom, openModalAtom, selectedEmptyPaneAtom, showDiscardOrSaveModalAtom, strategiesAtom, strategiesWithUnsavedChangesAtom, userLiveDocAtom } from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { Strategy, WatchlistObj } from '../../types/user_types'
import { db } from '../../firebase'

interface CreateStrategyModalProps {
  strategy?: Strategy
}

export const CreateStrategyModal = (props: CreateStrategyModalProps) => {
  const {strategy} = props

  // General helpers
  const [ user ] = useAtom(loggedInUserAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)
  const [strategies, setStrategies] = useAtom(strategiesAtom)
  const [selectedEmptyPane] = useAtom(selectedEmptyPaneAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [, setShowDiscardOrSaveModal] = useAtom(showDiscardOrSaveModalAtom)
  const [strategiesWithUnsavedChanges, setStrategiesWithUnsavedChanges] = useAtom(strategiesWithUnsavedChangesAtom)


  // Form fields
  const [name, setName] = useState<string>(strategy?.name.replace(/\s/g, '_') || '')

  // Check for errors when onclick isnt running
  let errorMessage = ''
  if (!buttonIsLoading) {
    // Check if name is unique
    let conflictingStrategies: Strategy[] = []
    if (strategy) {
      conflictingStrategies = strategies?.filter(s => s.id !== strategy.id && s.name === name) || []
    } else {
      conflictingStrategies = strategies?.filter(s => s.name === name) || []
    }
    if (conflictingStrategies.length) {
      errorMessage = 'Strategy name is being used'
    }

    // Limit to 50 characters
    if (name?.length > 50) {
      errorMessage = 'Strategy name must be < 50 characters'
    }

    // Ensure name doesn't start with number
    if (/^\d/.test(name)) {
      errorMessage = 'Strategy name must not start with a number'
    }
  }

  useEffect(() => {
    setButtonEnabled(!!name && !errorMessage)
  }, [name, errorMessage]);

  if (!user) return null

  const content = (
    <div className='column width-full'>

      {/* Name input */}
      <Input
        label={'Name'}
        type={'text'}
        style={{width: '100%'}}
        initialValue={name}
        onChange={(val) => {
          let modified = val.replace(/\s/g, '_').toLowerCase().trim();
          modified = modified.slice(0, 50);
          setName(modified);
        }}
        autoFocus={true}
      />
      {name && <div className='under-input-label'>
        {'Compiled: ' + name}
      </div>}

      {/* Error message, if relevant */}
      <div
        className={errorMessage ? 'font-size-10 red-text' : 'display-none'}
        style={{
          marginTop: 15,
          marginBottom: -10,
        }}
      >
        {errorMessage}
      </div>
    </div>
  )

  return (
    <Modal
      title={strategy ? 'Strategy settings' : 'Create strategy'}
      contents={[content]}
      yesButtonText={strategy ? 'Update' : 'Create'}
      isButtonDisabled={!buttonEnabled || buttonIsLoading}
      isButtonLoading={buttonIsLoading}
      onYes={async () => {
        try {
          setButtonIsLoading(true)

          // Update codepath
          if (strategy) {

            const updatedStrategy: Strategy = {
              ...strategy,
              name: name,
              updatedAt: new Date(),
            }

            await db.collection('users')
              .doc(user.uid)
              .collection('strategies')
              .doc(updatedStrategy.id)
              .set(updatedStrategy)
                
          } else {
            // New codepath
            let highestIndex = 0
            strategies.forEach(s => {
              if (s.index > highestIndex) {
                highestIndex = s.index
              }
            })
            const newStrategy: Omit<Strategy, 'id'> = { // omit 'id' for now
              userId: user.uid,
              createdAt: new Date(),
              updatedAt: new Date(),
              name: name,
              code: BLANK_STRATEGY_CODE,
              function_metadata: [],
              tickers: [],
              min_interval: '',
              index: highestIndex + 1 // assuming this is your logic for index
            }
            const newStrategyRef = await db.collection('users')
              .doc(user.uid)
              .collection('strategies')
              .add(newStrategy)

            const strategyId = newStrategyRef.id

            // Now update the strategy object with the generated id
            const strategyWithId: Strategy = {
              ...newStrategy,
              id: strategyId,
            }
            await db.collection('users')
              .doc(user.uid)
              .collection('strategies')
              .doc(strategyId)
              .set(strategyWithId) // if you need to store the id
        
            // Update user's selected strategy
            await db.collection('users')
              .doc(user.uid)
              .update({ codeEditorSlot1: strategyId })

            const paneString = 'strategy.' + strategyId
            set_open_pane(user, uld, selectedEmptyPane, paneString, strategiesWithUnsavedChanges, setShowDiscardOrSaveModal)
          }          
          updateLiveData(user)
          setButtonIsLoading(false)
          setOpenModal(null)
        } catch (error) {
          setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}

const BLANK_STRATEGY_CODE = `import { tb, portfolio, strategy, ai, utils } from 'tb'

strategy.on_interval('an_hourly_function', '1h', async () => {
  await tb.log('Code you write here will run every hour during market hours if this strategy is deployed')
})

strategy.on_trigger('a_trigger_function', async () => {
  await tb.log('Code you write here will run only when you trigger it by clicking test or run')
})

`