import React, { useState, useEffect } from 'react'
import { useAtom } from 'jotai'
import { DataGridPro, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro'
import {
  tableSX,
  formatNumberForTable,
  formatDateForTable,
  handleMUISorting,
  get_contextmenu_off,
  valueForTable,
  tableSX_Modal,
} from '../../logic/u'
import {
  openModalAtom,
  ordersAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { _load_all_orders, sort_orders } from '../../logic/order_record_utils'
import { TickerDropdown } from '../dropdowns/TickerDropdown'
import { CustomGridOverlay } from '../reusable/CustomGridOverlay';
import { TickerModal } from '../modals/TickerModal'
import { Backtest } from '../../types/backtest_types'
import { Icon } from '../reusable'
import { TableDropdown } from '../dropdowns/TableDropdown'

const DEFAULT_PAGE_SIZE = 100;

interface OrdersTableProps {
  paginate: boolean
  backtest?: Backtest | null
  symbol?: string
  orderId?: string
  height?: string
  width?: string
  paneIndex?: number
}

export const OrdersTable = (props: OrdersTableProps) => {
  const { backtest, symbol, paginate = true } = props

  const [orders, setOrders] = useAtom(ordersAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'submitted_at',
      sort: 'desc' as GridSortDirection
    },
  ]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [isLoading, setIsLoading] = useState(false);
  const [openDropdown, setOpenDropdown] = useState<any>(null)

  const uid = uld?.id;

  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);

  useEffect(() => {
    const fetchAllOrders = async () => {
      if (!uid) return;
      setIsLoading(true);
      const all_orders = await _load_all_orders(uid);
      const sorted_orders = sort_orders(all_orders);

      setPageSize(Math.max(DEFAULT_PAGE_SIZE, sorted_orders.length));
      setRowCount(sorted_orders.length);  // Update row count

      setOrders(sorted_orders);
      setIsLoading(false);
    }
    fetchAllOrders();
  }, [uid, setOrders]);

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  }

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  }

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setPage(0);
  }

  const handleContextMenuClick = (event: any, params: any, openLeft?: boolean) => {
    if (get_contextmenu_off()) return
    event.preventDefault()
    const width = 220
    const clickX = openLeft ? event.clientX - (width + 5) : event.clientX + 5
    const clickY = event.clientY - 10
    const symbol = params.row.symbol
    setOpenDropdown(
      <TickerDropdown
        symbol={symbol}
        left={clickX}
        top={clickY}
        width={width}
        onClose={() => setOpenDropdown(null)}
        orderObj={params.row}
        fromTable={true}
      />
    )
  }

  let orderRows = orders?.unsorted || []
  if (backtest) {
    orderRows = backtest.orders
  }
  if (symbol) {
    orderRows = orders?.unsorted.filter(o => o.symbol === symbol)
  }

  const forModal = !!symbol || !!props.backtest
  let parentClassName = 'table-tab'
  let tableClassName = 'table-container'
  if (forModal) {
    parentClassName += ' for-modal'
    tableClassName += ' for-modal'
  }

  return (
    <div className={parentClassName} style={{ width: props.width, height: props.height, minHeight: 0, position: 'relative' }}>
      {openDropdown}
      {!forModal && <div className='tab-header'>
        <div>Orders table</div>
        <Icon
          icon='ellipsis'
          set='sharp-solid'
          size={14}
          style={{ marginLeft: 12, marginTop: .5 }}
          onClick={(e) => {
            const rect = e.target.getBoundingClientRect()
            if (rect) {
              const width = 175
              const left = rect.left - width + 10
              const top = rect.bottom
              setOpenDropdown(<TableDropdown
                left={left}
                top={top}
                width={width}
                onClose={() => setOpenDropdown(null)}
                paneIndex={props.paneIndex}
                table='orders'
              />)
            }
          }}
        />
      </div>}
      <div className={tableClassName}>
        <DataGridPro
          className={symbol || props.backtest ? 'uppercase-grid' : 'clickable-grid uppercase-grid'}
          rows={orderRows}
          rowCount={rowCount}
          columns={[
            ...(!symbol ? [{
              field: 'symbol',
              headerName: 'ticker',
              width: 100,
              type: 'string',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {params.row.symbol}
                </div>
              )
            }] : []),
            {
              field: 'side',
              headerName: 'side',
              width: 100,
              headerAlign: 'left',
              align: 'left',
              type: 'string',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {params.row.side}
                </div>
              )
            },
            {
              field: 'type',
              headerName: 'type',
              width: 100,
              headerAlign: 'left',
              align: 'left',
              type: 'string',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {params.row.type}
                </div>
              )
            },
            {
              field: 'time_in_force',
              headerName: 'time_in_force',
              width: 155,
              headerAlign: 'left',
              align: 'left',
              type: 'string',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {params.row.time_in_force}
                </div>
              )
            },
            {
              field: 'status',
              headerName: 'status',
              width: 100,
              headerAlign: 'left',
              align: 'left',
              type: 'string',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'left'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params, true)
                  }}
                >
                  {params.row.status}
                </div>
              )
            },
            {
              field: 'notional',
              headerName: 'notional',
              width: 120,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {formatNumberForTable(params.row.notional)}
                </div>
              )
            },
            {
              field: 'qty',
              headerName: 'qty',
              width: 120,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {formatNumberForTable(params.row.qty)}
                </div>
              )
            },
            {
              field: 'filled_qty',
              headerName: 'filled_qty',
              width: 155,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {params.row.filled_qty}
                </div>
              )
            },
            {
              field: 'limit_price',
              headerName: 'limit_price',
              width: 120,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {valueForTable('price', params.row.limit_price)}
                </div>
              )
            },
            {
              field: 'stop_price',
              headerName: 'stop_price',
              width: 120,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {valueForTable('price', params.row.stop_price)}
                </div>
              )
            },
            {
              field: 'filled_avg_price',
              headerName: 'filled_avg_price',
              width: 120,
              headerAlign: 'right',
              align: 'right',
              type: 'number',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {formatNumberForTable(params.row.filled_avg_price)}
                </div>
              )
            },
            {
              field: 'submitted_at',
              headerName: 'submitted_at',
              width: 155,
              headerAlign: 'right',
              align: 'right',
              type: 'date',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {formatDateForTable(params.row.submitted_at)}
                </div>
              )
            },
            {
              field: 'filled_at',
              headerName: 'filled_at',
              width: 155,
              headerAlign: 'right',
              align: 'right',
              type: 'date',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {formatDateForTable(params.row.filled_at)}
                </div>
              )
            },
            {
              field: 'expired_at',
              headerName: 'expired_at',
              width: 155,
              headerAlign: 'right',
              align: 'right',
              type: 'date',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {formatDateForTable(params.row.expired_at)}
                </div>
              )
            },
            {
              field: 'canceled_at',
              headerName: 'canceled_at',
              width: 155,
              headerAlign: 'right',
              align: 'right',
              type: 'date',
              sortComparator: handleMUISorting,
              renderCell: (params) => (
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right'
                  }}
                  onContextMenu={(event) => {
                    handleContextMenuClick(event, params)
                  }}
                >
                  {formatDateForTable(params.row.canceled_at)}
                </div>
              )
            },
          ]}
          disableColumnResize
          disableColumnMenu
          disableColumnPinning
          disableSelectionOnClick
          disableColumnReorder
          density='compact'
          components={{
            NoRowsOverlay: () => {
              let txt = 'No orders.'
              if (isLoading) {
                txt = 'Loading orders...'
              } else if (symbol && orderRows.length === 0) {
                txt = `No orders associated with ${symbol}.`
              }
              return <CustomGridOverlay text={txt} />
            },
          }}
          sx={{
            ...tableSX,
            ...(forModal ? tableSX_Modal : null)
          }}
          onRowClick={(params) => {
            if (symbol) { return }
            if (props.backtest) { return }
            const orderObj = params.row
            setOpenModal(<TickerModal symbol={orderObj.symbol} orderId={params.row.id} />)
          }}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}

          // Pagination configuration
          pagination={paginate}
          paginationMode={paginate ? "client" : "server"}
          page={paginate ? page : 0}
          pageSize={paginate ? pageSize : orderRows.length}
          rowsPerPageOptions={paginate ? [10, 25, 50, 100] : []}
          onPageChange={paginate ? handlePageChange : undefined}
          onPageSizeChange={paginate ? handlePageSizeChange : undefined}

          localeText={{
            footerTotalRows: 'Total orders:',
          }}
          hideFooter={!paginate}
          getRowClassName={(params) =>
            params.row.id === props.orderId ? 'selected-modal-table-row' : ''
          }
        />
      </div>
    </div>
  )
}
