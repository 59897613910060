import React, { useEffect, useState } from 'react'
import {
  openModalAtom,
  loggedInUserAtom,
  userLiveDocAtom,
  deploymentsAtom,
  strategiesAtom,
} from '../../types/global_types'
import { useAtom } from 'jotai'
import { Deployment } from '../../types/user_types'
import { TakeDownDeploymentModal } from './TakeDownDeploymentModal'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Modal } from '../reusable'
import { SECOND_SURFACE } from '../../logic/u'

interface DeploymentDetailsModalProps {
  deployment: Deployment
}

export const DeploymentDetailsModal = (props: DeploymentDetailsModalProps) => {
  const [ user ] = useAtom(loggedInUserAtom)
  const [ uld ] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [ openDropdown, setOpenDropdown ] = useState<any>(null)
  const [deployments, ] = useAtom(deploymentsAtom)
  const [strategies, ] = useAtom(strategiesAtom)
  const { deployment } = props
  const [col1Selection, setCol1Selection] = useState<string>(Object.keys(deployment.liveCode)[0])

  if (!user || !uld || !deployment) return null  

  return (
    <Modal
      title={'Deployment: ' + deployment.name}
      contents={[(
        <div className='indicators-modal-container'>
          <div className='col-container' style={{marginTop: 0, height: 'calc(100% - 0px'}}>
            <div className='first-col'>
              {Object.keys(deployment?.liveCode).map((s, i) => {
                const name = strategies.find(strat => strat.id === s)?.name || `[deleted]`
                let className = 'bucket'
                if (col1Selection === s) className += ' s'
                if (!col1Selection && i === 0) className+= ' s'
                return <div 
                  className={className}
                  key={s}
                  onClick={() => {
                    setCol1Selection(s)
                  }}
                >
                  <div className='add-ellipses-cutoff' style={{maxWidth: '90%'}}>{name}</div>
                </div>
              })}
            </div>
            <div className={'second-col-conatiner'} style={{width: 'calc(100% - 203px'}}>
              <div className={'second-col'} >
                <div style={{height: '100%'}}>
                  <SyntaxHighlighter
                    language="javascript"
                    style={oneDark}
                    wrapLines={true}
                    showLineNumbers={true}
                    customStyle={{
                      paddinTop: 20, 
                      paddingBottom: 10, 
                      marginTop: 0, 
                      marginBottom: 0, 
                      fontSize: 11, 
                      maxHeight: 'calc(100% - 20px)',
                      backgroundColor: SECOND_SURFACE
                    }}
                    lineNumberStyle={{
                      minWidth: '30px', // Adjust this width as needed
                      textAlign: 'right', // Align the numbers properly
                      paddingRight: '10px', // Add some padding for spacing
                      backgroundColor: SECOND_SURFACE
                    }}                    
                  >
                    {deployment.liveCode[col1Selection]}
                  </SyntaxHighlighter>                
                </div>
              </div>
            </div>
          </div>
        </div>
      )]}
      style={{
        height: 500,
        width: 800,
        marginLeft: -200
      }}
    />
  )
}