import { useAtom } from 'jotai'
import {
  appTabAtom,
  loggedInUserAtom,
  showDiscardOrSaveModalAtom,
  strategiesWithUnsavedChangesAtom,
  userLiveDocAtom,
  ordersAtom,
  transfersAtom,
  backtestsAtom,
  strategiesAtom,
  deploymentsAtom,
  openModalAtom,
} from '../../types/global_types'
import { db, currentUser, logout } from '../../firebase'
import {
  Icon,
  DropdownMenu,
} from '../reusable'
import React, { useState, useEffect } from 'react'
import { DividerPaneIcon } from '../reusable/DividerPaneIcon'
import { TEXT_GRAY, hasUnsavedVisibleStrategies } from '../../logic/u'
import { Modal } from '../reusable/Modal'
import { KeyboardShortcutsModal } from '../modals/KeyboardShortcutsModal'

interface AccountDropdownProps {
  left: number
  top: number
  onClose: () => void
}

export const AccountDropdown = (props: AccountDropdownProps) => {
  const [user, setUser ] = useAtom(loggedInUserAtom)
  const [ , setUld] = useAtom(userLiveDocAtom)
  const [ appTab, setAppTab ] = useAtom(appTabAtom)
  const [, setOrders] = useAtom(ordersAtom)
  const [, setTransfers] = useAtom(transfersAtom)
  const [, setBacktests] = useAtom(backtestsAtom)
  const [, setStrategies] = useAtom(strategiesAtom)
  const [, setDeployments] = useAtom(deploymentsAtom)
  const [strategiesWithUnsavedChanges, setStrategiesWithUnsavedChanges] = useAtom(strategiesWithUnsavedChangesAtom)
  const [, setShowDiscardOrSaveModal] = useAtom(showDiscardOrSaveModalAtom)
  // const [ showKBSModal, setShowKBSModal ] = useState(false)
  const [ , setOpenModal ] = useAtom(openModalAtom)
  const {left, top, onClose} = props


  return (
    <DropdownMenu
      //@ts-ignore
      posLeft={left}
      posTop={top}
      width={240}
      height={500}
      onClose={() => onClose()}
      noArrowKeys={true}
      contents={[
        {
          display: (
            <div className='left'>
              <div>Account</div>
            </div>
          ),
          onClick: () => {
            if (hasUnsavedVisibleStrategies(user, strategiesWithUnsavedChanges)) {
              setShowDiscardOrSaveModal({
                showModal: true,
                showAccountTab: true
              });
              return;
            } else {
              setAppTab('account')
            }
          }
        },
        {
          display: (
            <div className='left space-between'>
              <div>Keyboard shortcuts</div>
              {/* <div>*</div> */}
            </div>
          ),
          onClick: () => {setOpenModal(<KeyboardShortcutsModal />)}
        },
        {
          display: (
            <div className='left space-between'>
              <div>Docs</div>
              <Icon
                icon='arrow-up-right'
                set='sharp-solid'
                size={9}
              />
            </div>
          ),
          onClick: () => {
            //
          }
        },
        {
          display: (
            <div
              style={{width: '100%', height: 1, backgroundColor: TEXT_GRAY, marginBottom: 5}}
            ></div>
          )
        },
        {
          display: (
            <div className='left space-between'>
              <div>Log out</div>
              <Icon
                icon='right-from-bracket'
                set='sharp-solid'
                size={10}
                style={{marginTop: 1}}
              />
            </div>
          ),
          onClick: () => {
            setUser(null)
            setUld(null)
            setOrders([])
            setTransfers([])
            setBacktests([])
            setStrategies([])
            setDeployments([])
            logout()
          }
        },
      ]}
    />
  )
}