import { useAtom } from 'jotai'
import {
  loggedInUserAtom,
  openModalAtom,
  selectedCodeEditorSlotAtom,
  selectedEmptyPaneAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { db, currentUser } from '../../firebase'
import {
  Icon,
  DropdownMenu,
} from '../reusable'
import React, { useState, useEffect } from 'react'
import { valueForTable } from '../../logic/u'
import { TransferDetailsModal } from '../modals/TransferDetailsModal'
import { CancelPendingTransferModal } from '../modals/CancelPendingTransferModal'

interface TransferDropdownProps {
  transferObj: any
  left: number
  top: number
  width: number
  onClose: () => void
}

export const TransferDropdown = (props: TransferDropdownProps) => {
  const [user ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [selectedEmptyPane] = useAtom(selectedEmptyPaneAtom)

  const {transferObj: transfer, left, top, width, onClose} = props


  interface DropdownItem {
    display: JSX.Element
    isDisabled?: boolean
    onClick?: () => void
  }
  let contents: DropdownItem[] = []
  contents = [
    {
      display: (
        <div className='font-size-10 gray-text'>
          {valueForTable('amount', transfer.amount)}
        </div>
      )
    },
    {
      display: (
        <div className='left'>  
          <Icon
            icon='memo-circle-info'
            set='sharp-solid'
            size={10.5}
            style={{marginRight: 10, marginTop: .5}}
          />         
          <div>View transfer details</div>
        </div>
      ),
      onClick: () => {
        setOpenModal(<TransferDetailsModal transferObj={transfer} />)
      }
    },
    {
      display: (
        <div className='left'>
          <Icon
            icon='ban'
            set='sharp-solid'
            size={10.5}
            className={['REJECTED', 'CANCELED', 'APPROVED', 'COMPLETE', 'RETURNED'].includes(transfer.status) ? 'menu-item-icon-disabled' : ''}
            style={{marginRight: 10, marginTop: .5}}
          />   
          <div>Cancel transfer</div>
        </div>
      ),
      isDisabled: ['REJECTED', 'CANCELED', 'APPROVED', 'COMPLETE', 'RETURNED'].includes(transfer.status),
      onClick: () => {
        setOpenModal(<CancelPendingTransferModal transferObj={transfer}  />)
      }
    }, 
  ]

  return (
    <DropdownMenu
      //@ts-ignore
      posLeft={left}
      posTop={top}
      width={width}
      height={500}
      onClose={() => onClose()}
      noArrowKeys={true}
      contents={contents}
    />
  )
}