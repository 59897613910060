import './LogsPane.scss'
import React, { useState, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import { EELog } from '../../../types/user_types'
import { formatDateForTable, format_price, timeAgo, valueForTable } from '../../../logic/u'
import {
  loggedInUserAtom,
  openModalAtom,
  userLiveDocAtom,
  phantomLogAtom,
} from '../../../types/global_types'
import { db, currentUser } from '../../../firebase'
import { LogRow } from '../../reusable/LogRow'
import { ORANGE, ORANGE_FAINT } from '../../../logic/colors'
import { LogModal } from '../../modals/LogModal'

const LOAD_INCREMENT = 50

export const LogsPane = () => {
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [phantomLog, setPhantomLog] = useAtom(phantomLogAtom)
  const [logs, setLogs] = useState<any>([])
  // const [logs, setLogs] = useState<EELog[]>([])
  const containerRef = useRef<HTMLDivElement>(null)
  const [haveMore, setHaveMore] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)

  // Initially load cached logs
  useEffect(() => {
    if (uld?.cachedEELogs) {
      const sortedLogs = [...uld.cachedEELogs].sort((a, b) => new Date(b.time_iso).getTime() - new Date(a.time_iso).getTime());
      setLogs(sortedLogs);
    } else {
      setLogs([]);
    }

    // If we have a phantom log and there's a real log newer than it, clear
    const newestLog = uld?.cachedEELogs?.[uld?.cachedEELogs.length - 1];
    if (phantomLog && newestLog) {
      console.log(`checking. newestLog: ${newestLog.id}, phantomLog: ${phantomLog.latest_log_id}`)
      if (phantomLog.latest_log_id !== newestLog.id) {
        setPhantomLog(null);
      }
    }
  }, [uld, phantomLog]);

  // Load more if user's scrolled within 200px of the bottom
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollHeight - scrollTop - clientHeight < 200 && !loadingMore && haveMore) {
        load_more();
      }
    };

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [loadingMore, haveMore]); // Add dependencies to prevent stale closure

  const load_more = async () => {
    if (!uld) return
    if (loadingMore || !haveMore || !user) return;

    setLoadingMore(true);

    // In case logs hasn't been initialized
    let _logs = logs
    if (_logs.length === 0) {
      _logs = [...uld.cachedEELogs].sort((a, b) => new Date(b.time_iso).getTime() - new Date(a.time_iso).getTime());
    }

    try {
      // No logs case
      if (_logs.length === 0) {
        setLoadingMore(false);
        setHaveMore(false);
        return;
      }

      const last_log = _logs[_logs.length - 1];
      if (!last_log) {
        setLoadingMore(false);
        setHaveMore(false);
        return;
      }

      const beforeTime = new Date(last_log.time_iso);
      const snapshot = await db
        .collection('users')
        .doc(user.uid)
        .collection('EELogs')
        .where('time', '<', beforeTime)
        .orderBy('time', 'desc')
        .limit(LOAD_INCREMENT)
        .get();

      const newLogs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      if (newLogs.length === 0) {
        setHaveMore(false);
      } else {
        setLogs(currentLogs => [...currentLogs, ...newLogs]);
        setHaveMore(newLogs.length >= LOAD_INCREMENT);
      }
    } catch (error) {
      console.error('Error loading more logs:', error);
    } finally {
      setLoadingMore(false);
    }
  };

  if (!user || !uld) return null;

  return (
    <div className='logs-pane-container' ref={containerRef}>
      {!logs.length && !phantomLog? (
        <div className='no-logs-text'>
          No logs.
        </div>
      ) : null}

      {phantomLog ? <div className='phantom-log'>
        {phantomLog.text}
      </div> : null}

      {logs.map((log: EELog) => {
        if (Object.keys(log).length === 0) return null;
        return <LogRow key={log.id} log={log} />;
      })}

      {/* Bottom message, if loading or no more present */}
      {!haveMore || loadingMore ? (
        <div
          style={{
            textAlign: 'center',
            padding: 10,
            height: 20,
            color: ORANGE,
            fontSize: 11,
          }}
        >
          {!haveMore ? "You've reached the end" : null}
          {loadingMore ? "Loading more..." : null}
        </div>
      ) : null}
    </div>
  );
};