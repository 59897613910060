import './Checkbox.scss';
import React from 'react';

import { Icon } from './Icon';

interface CheckboxProps {
  value: boolean
  isDisabled?: boolean
  onChange: (value: boolean) => void
  className?: string
  style?: any
  checkMarginTop?: number
}

export function Checkbox(props: CheckboxProps) {
  let mainClassName = 'checkbox';
  if (props.className) {
    mainClassName += ' ' + props.className;
  }
  if (props.value) {
    mainClassName += ' checked';
  }
  if (props.isDisabled) {
    mainClassName += ' disabled';
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (props.isDisabled) return;

    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      props.onChange(!props.value);
    }
  };

  return (
    <div
      className={mainClassName}
      style={props.style}
      tabIndex={props.isDisabled ? -1 : 0} // Make tabbable unless disabled
      role="checkbox" // Accessible role for checkboxes
      aria-checked={props.value} // Accessibility state
      onClick={() => {
        if (props.isDisabled) return;
        props.onChange(!props.value);
      }}
      onKeyDown={handleKeyDown} // Handle keyboard interaction
    >
      {props.value ? (
        <Icon
          icon={'check'}
          set={'sharp-solid'}
          size={10}
          style={{
            marginTop: props.checkMarginTop ? props.checkMarginTop : -1,
            marginLeft: 1,
            svg: { fill: 'black' },
          }}
        />
      ) : null}
    </div>
  );
}
