import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import axios from 'axios';
import { Icon2 } from '../reusable/Icon2';

// Import your utility functions and configurations
import {
  format_price,
  format_price_appropriately,
  formatPercent,
  getRecentTradingDayEnd,
  getRecentTradingDayStart,
  getRecentWeekEnd,
  getRecentWeekStart,
} from '../../logic/u';
import { currentUser } from '../../firebase';
import { getConfig } from '../../config';
import { format_date_for_crosshairs } from '../TBChart/ChartOverlay';

const config: any = getConfig();

interface MinichartProps {
  ticker: string;
}

export const Minichart: React.FC<MinichartProps> = ({ ticker }) => {
  const [selectedRange, setSelectedRange] = useState('1M');
  const [chartData, setChartData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [crosshairData, setCrosshairData] = useState<any>(null);

  // Identify bounding dates, load data for each timeframe
  useEffect(() => {
    let startDate: Date = new Date();
    let endDate: Date = new Date();
    let timeframe = '1m';

    switch (selectedRange) {
      case '1D':
        startDate = getRecentTradingDayStart();
        endDate = getRecentTradingDayEnd();
        timeframe = '1m';
        break;
      case '1W':
        startDate.setDate(startDate.getDate() - 7);
        timeframe = '1h';
        break;
      case '1M':
        startDate.setMonth(startDate.getMonth() - 1);
        timeframe = '1d';
        break;
      case '3M':
        startDate.setMonth(startDate.getMonth() - 3);
        timeframe = '1d';
        break;
      // case 'ytd':
      //   startDate = new Date(new Date().getFullYear(), 0, 1);
      //   timeframe = '1w';
      //   break;
      case '1Y':
        startDate.setFullYear(startDate.getFullYear() - 1);
        timeframe = '1w';
        break;
      case '5Y':
        startDate.setFullYear(startDate.getFullYear() - 5);
        timeframe = '1w';
        break;
      case 'all':
        startDate = new Date(2015, 0, 1);
        endDate = new Date();
        timeframe = '1w';
        break;
    }
    const startDateISO = startDate.toISOString();
    const endDateISO = endDate.toISOString();

    console.log(`${startDateISO} - ${endDateISO}`);

    // Load data for selected timeframe
    load_data(ticker, timeframe, startDateISO, endDateISO, selectedRange, setChartData, setIsLoading);
  }, [selectedRange, ticker]);

  // Set colors and extract the first and last non-null values
  let color = '#00cc66';
  let text_color = '#00cc66';
  let fillColor = '#00cc6622';

  // Find first and last non-null values
  const first_price = chartData.find(item => item[1] !== null && !isNaN(item[1]))?.[1] || 0;
  let last_price = chartData.findLast(item => item[1] !== null && !isNaN(item[1]))?.[1] || 0;

  if (crosshairData) {
    last_price = crosshairData.y;
  }

  if (last_price < first_price) {
    text_color = '#ff5555';
    // color = '#ff5555';
    // fillColor = '#ff555522';
  }

  // Diff data
  let price_diff = last_price ? last_price - first_price : 0;
  let price_diff_icon = (price_diff > 0) ? 'caret-up' : 'caret-down';
  let price_diff_human = format_price_appropriately(price_diff)
  const percent_diff = first_price ? (price_diff / first_price) * 100 : 0;
  let percent_diff_human = formatPercent(percent_diff);
  let last_price_human = format_price_appropriately(last_price);
  let timeframe_string = 'today'

  switch (selectedRange) {
    case '1W':
      timeframe_string = 'past week'
      break;
    case '1M':
      timeframe_string = 'past month'
      break;
    case '3M':
      timeframe_string = 'past three months'
      break;
    case '1Y':
      timeframe_string = 'past year'
      break;
    case '5Y':
      timeframe_string = 'past five years'
      break;
    case 'all':
      timeframe_string = `since ${new Date(chartData[0][0]).getFullYear()}`
      break;
  }
  let diff_string = `$${price_diff_human} (${percent_diff_human})`

  if (crosshairData) {
    timeframe_string = ''
  }

  // If hovered, we show the hovered price
  // if (crosshairData) {
  //   last_price = crosshairData.y
  //   debugger
  //   price_diff = last_price ? last_price - first_price : 0;

  //   last_price_human = 'hovered price'
  //   diff_string = 'hovered'
  //   // price_diff = '123456'
  //   // price_diff_icon
  //   // price_diff

  //   // timeframe_string

  // }

  // Calculate min and max values from non-null data points
  const validPrices = chartData.map(([_, price]) => price).filter(price => price !== null && !isNaN(price));
  const minPrice = Math.min(...validPrices);
  const maxPrice = Math.max(...validPrices);
  const priceRange = maxPrice - minPrice;

  const chartOptions: Highcharts.Options = {
    title: { text: `` },
    series: [
      {
        name: 'Price',
        data: chartData,
        type: 'area',
        color,
        lineWidth: 2,
        tooltip: {
          valueDecimals: 2,
        },
        animation: {
          duration: 200,
        },
        fillColor,
      },
    ],
    xAxis: {
      type: 'datetime',
      title: { text: '' },
      labels: { enabled: false },
      // labels: {
      //   formatter: (obj) => {
      //     const d = new Date(obj.value);
      //     let ret = '';
      //     switch (selectedRange) {
      //       case '1D':
      //         ret = `${d.getHours() % 12 || 12}${
      //           d.getMinutes() === 0 ? '' : `:${d.getMinutes().toString().padStart(2, '0')}`
      //         }${d.getHours() < 12 ? 'a' : 'p'}`;
      //         break;
      //       case '1W':
      //         ret = d.toLocaleString('en-US', { weekday: 'short' });
      //         break;
      //       case '1M':
      //         ret = d.toLocaleString('en-US', { month: 'short', day: 'numeric' });
      //         break;
      //       case '3M':
      //         ret = d.toLocaleString('en-US', { month: 'short' });
      //         // ret = d.toLocaleString('en-US', { month: 'short', day: 'numeric' });
      //         break;
      //       case 'ytd':
      //         ret = d.toLocaleString('en-US', { month: 'short', day: 'numeric' });
      //         break;
      //       case '1Y':
      //         ret = d.toLocaleString('en-US', { month: 'short' });
      //         break;
      //       case '5Y':
      //         ret = d.toLocaleString('en-US', { year: 'numeric' });
      //         break;
      //       case 'all':
      //         ret = d.toLocaleString('en-US', { month: 'short', year: 'numeric' });
      //         break;
      //     }
      //     return ret;
      //   },
      //   style: { color: '#999999' },
      // },
    },
    yAxis: {
      title: { text: '' },
      // labels: { enabled: false },
      labels: {
        format: '${value}',
        style: { color: '#999999' },
        align: 'right',
        x: -5,
      },
      gridLineWidth: 0,
      min: minPrice - (priceRange * 0.05),
      max: maxPrice + (priceRange * 0.05),
      plotLines: first_price ? [
        {
          color: '#999999',
          dashStyle: 'Dot',
          value: first_price,
          width: 1.5,
          zIndex: 5,
        },
      ] : [],
    },
    tooltip: {
      shared: true,
      useHTML: true,
      // backgroundColor: 'transparent',
      backgroundColor: '#2C2B2BBB',
      borderWidth: 0,
      shadow: false,
      shape: 'rect',
      style: {
        color: 'white',
        fontSize: '11px',
        fontWeight: 'bold',
        textAlign: 'center',
      },
      formatter: function () {
        const d = new Date(this.x as number);
        let ret = format_date_for_crosshairs(d.toISOString());

        // ret += '<br>';
        // ret +=
        //   '$' +
        //   this.y?.toLocaleString(undefined, {
        //     minimumFractionDigits: 2,
        //     maximumFractionDigits: 2,
        //   });

        // Add percent change
        if (this.points) {
          const last = this.points[this.points.length - 1].y;
          if (last && first_price) {
            const diff = last - first_price;
            const percent = (diff / first_price) * 100;
            // ret += '<br>';
            // ret += `${format_price_appropriately(diff)} (${formatPercent(percent)})`;

            // Set state to be used elsewhere
            if (crosshairData?.x !== this.x) {
              setCrosshairData({
                x: this.x,
                y: this.y,
                date: ret,
                price: format_price_appropriately(this.y),
                diff: format_price_appropriately(diff),
                pct: formatPercent(percent),
              })
            }
          }
        }

        return ret;
      },
      positioner: function (labelWidth, labelHeight, point) {
        const chartWidth = this.chart.chartWidth;
        const padding = 0;
        let x = point.plotX - labelWidth / 2;
        x = Math.max(padding, Math.min(chartWidth - labelWidth - padding, x));
        return {
          x: x,
          y: this.chart.chartHeight - labelHeight,
        };
      },
      enabled: true,
    },
    chart: {
      height: null,
      width: null,
      margin: [0, 0, 0, 0],
      backgroundColor: 'transparent',
    },
    credits: { enabled: false },
    rangeSelector: { enabled: false },
    navigator: { enabled: false },
    scrollbar: { enabled: false },
  };

  return (
    <div
      style={{
        // width: '705px',
        // height: '200px',
        color: '#999999',
        width: '698px',
        height: '352px',     // size before chart draws
        // backgroundColor: 'pink',
      }}
    >
      {/* Timeframe selector */}
      <div style={{ marginBottom: '8px' }}>
        <button
          style={{
            fontWeight: selectedRange === '1D' ? 'bold' : 'normal',
            color: selectedRange === '1D' ? 'white' : '#999999',
          }}
          onClick={() => setSelectedRange('1D')}
        >
          1D
        </button>

        <button
          style={{
            fontWeight: selectedRange === '1W' ? 'bold' : 'normal',
            color: selectedRange === '1W' ? 'white' : '#999999',
          }}
          onClick={() => setSelectedRange('1W')}
        >
          1W
        </button>

        <button
          onClick={() => setSelectedRange('1M')}
          style={{
            fontWeight: selectedRange === '1M' ? 'bold' : 'normal',
            color: selectedRange === '1M' ? 'white' : '#999999',
          }}
        >
          1M
        </button>

        <button
          onClick={() => setSelectedRange('3M')}
          style={{
            fontWeight: selectedRange === '3M' ? 'bold' : 'normal',
            color: selectedRange === '3M' ? 'white' : '#999999',
          }}
        >
          3M
        </button>

        <button
          onClick={() => setSelectedRange('1Y')}
          style={{
            fontWeight: selectedRange === '1Y' ? 'bold' : 'normal',
            color: selectedRange === '1Y' ? 'white' : '#999999',
          }}
        >
          1Y
        </button>

        <button
          onClick={() => setSelectedRange('5Y')}
          style={{
            fontWeight: selectedRange === '5Y' ? 'bold' : 'normal',
            color: selectedRange === '5Y' ? 'white' : '#999999',
          }}
        >
          5Y
        </button>

        <button
          onClick={() => setSelectedRange('all')}
          style={{
            fontWeight: selectedRange === 'all' ? 'bold' : 'normal',
            color: selectedRange === 'all' ? 'white' : '#999999',
          }}
        >
          All
        </button>
      </div>

      {isLoading ? (
        <div
          style={{
            height: 'calc(100% - 52px)'
          }}
          className="generic-content-container modal-version"
        >
          <div
            style={{
              paddingTop: 140,
              paddingLeft: 'calc(50% - 50px)'
            }}
          >
            Loading chart...
          </div>
        </div>
      ) : (
        <div style={{position: 'relative'}}>

          {/* Price line */}
          <div
            style={{
              position: 'absolute',
              left: 10,
              top: 10,
              zIndex: 14,
              color: 'white',
              display: 'flex',
              fontSize: 18,
              fontWeight: 'bold',
            }}
          >
            {last_price_human}
          </div>

          {/* Diff line */}
          <div
            style={{
              position: 'absolute',
              left: 7,
              top: 35,
              zIndex: 10,
              color: 'white',
              display: 'flex',
              fontSize: 12,
              fontWeight: 'bold',
              backgroundColor: '#2C2B2BBB',
              padding: 3
            }}
          >
            <Icon2
              icon={price_diff_icon}
              set={'sharp-solid'}
              size={10}
              className={(price_diff > 0) ? 'green-svg' : 'red-svg'}
              style={{
                marginTop: (price_diff > 0) ? 2 : 0
              }}
            />
            <div style={{marginLeft: 5}}>
              <span style={{color: text_color}}>{diff_string}</span>
              <span style={{marginLeft: 5}}>{timeframe_string}</span>

            </div>
          </div>

          <div onMouseLeave={() => {setCrosshairData(null)}}>
            <HighchartsReact
              highcharts={Highcharts}
              constructorType={'stockChart'}
              options={chartOptions}
              containerProps={{
                style: { border: '2px solid #52535e', width: '706px', height: '320px' },
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

// Load data function remains the same
const load_data = async (
  ticker: string,
  timeframe: string,
  start: string,
  end: string,
  selectedRange: string,
  cb: any,
  setIsLoading: any
) => {
  setIsLoading(true);
  const token = await currentUser()?.getIdToken();
  const res = await axios.get(`${config.api_root_url}getBarsV5`, {
    params: {
      symbol: ticker,
      timeframe,
      start,
      end,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const rawBars = res.data.bars;

  const output: any[] = [];
  rawBars.forEach((bar: any) => {
    output.push([Date.parse(bar.t), bar.c]);
  });

  if (selectedRange === '1D') {
    const eod = getRecentTradingDayEnd();
    const last_bar_ts = output[output.length - 1][0];
    const empty_minutes_needed = Math.floor((eod.getTime() - last_bar_ts) / (1000 * 60));
    for (let i = 0; i < empty_minutes_needed; i++) {
      output.push([last_bar_ts + i * 60 * 1000, null]);
    }
  }

  cb(output);
  setIsLoading(false);
};