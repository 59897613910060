import { useAtom } from 'jotai'
import {
  backtestsAtom,
  loggedInUserAtom,
  openModalAtom,
} from '../../types/global_types'
import { db, currentUser } from '../../firebase'
import {
  Icon,
  DropdownMenu,
  ProgressBar,
} from '../reusable'
import React, { useState, useEffect } from 'react'
import { CreateWatchlistModal } from '../modals/CreateWatchlistModal'
import { TEXT_GRAY, formatDateForTable } from '../../logic/u'
import { AddTickersModal } from '../modals/AddTickersModal'
import { DeleteWatchlistModal } from '../modals/DeleteWatchlistModal'
import { CreateBacktestModal } from '../modals/CreateBacktestModal'
import { Strategy } from '../../types/user_types'
import { BacktestModal } from '../modals/BacktestModal'

interface BacktestsSelectorDropdownProps {
  strategyId: Strategy['id']
  left: number
  top: number
  onClose: () => void
}

export const BacktestsSelectorDropdown = (props: BacktestsSelectorDropdownProps) => {
  const [user ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [backtests, ] = useAtom(backtestsAtom)
  const {strategyId, left, top, onClose} = props

  interface DropdownItem {
    display: JSX.Element
    onClick?: () => void
  }
  let contents: DropdownItem[] = [
    {
      display: (
        <div className='font-size-10 gray-text'>
          Backtests
        </div>
      )
    },
  ]
  backtests?.filter(b => b.strategyObj.id === strategyId).forEach(backtestObj => {   
    const startDate = formatDateForTable(backtestObj?.start_time_iso).split(' ')[0]
    const endDate = formatDateForTable(backtestObj?.end_time_iso).split(' ')[0]
    const dateString = startDate + ' - ' + endDate 

    contents.push(
      {
        display: (
          <div className='left space-between'>
            <div className='row width-full space-between'>  
              <div className='column width-full'>
                <div className='row space-between'>
                  <div style={{width: 215}} className='add-ellipses-cutoff'>{backtestObj.name}</div>
                  <div className='row'>
                    {!['completed', 'failed'].includes(backtestObj.status) ? 
                    <ProgressBar
                      progressPercent={((backtestObj?.ticks_processed | 0) / (backtestObj?.ticks_total ? backtestObj?.ticks_total : 100)) * 100}
                      width={40}
                      style={{marginTop: 3}}
                    />
                    // <Spinner /> 
                    : backtestObj.status === 'failed' ? <Icon
                      hoverText='Failed to create backtest'
                      className='key-icon'
                      icon='triangle-exclamation'
                      size={14}
                      style={{marginRight: 0, marginTop: 1}}
                      // onClick={() => {
                      //   // for effect
                      // }}
                    /> : null}
                  </div>
                </div>
                <div className='font-size-9 offwhite-text margin-top-2'>{dateString}</div>
              </div>            
            </div>

            
          </div>
        ),
        onClick: async () => {
          setOpenModal(<BacktestModal backtest={backtestObj} />)
        }
      },
    )
  })
  if (user?.watchlists.length) {
    contents.push(
      {
        display: (
          <div
            style={{width: '100%', height: 1, backgroundColor: TEXT_GRAY, marginTop: 3, marginBottom: 5}}
          ></div>
        )
      },
    )
  }
  contents.push(
    {
      display: (
        <div className='left'>
          <div style={{width: 15, marginRight: 15}}>
            <Icon
              icon={'circle-plus'}
              set='sharp-solid'
              size={13}
              style={{marginRight: 20, marginTop: 1.5}}
              className={''}
            />
          </div>
          <div>{'Create backtest'}</div>
        </div>
      ),
      onClick: () => {
        setOpenModal(<CreateBacktestModal />)
      }
    },
  )

  return (
    <DropdownMenu
      //@ts-ignore
      posLeft={left}
      posTop={top}
      width={300}
      height={500}
      onClose={() => onClose()}
      noArrowKeys={true}
      contents={contents}
    />
  )
}