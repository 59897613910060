import { useAtom } from 'jotai';
import { loggedInUserAtom, userLiveDocAtom, openModalAtom, selectedEmptyPaneAtom, appTabAtom, showDiscardOrSaveModalAtom, strategiesWithUnsavedChangesAtom } from '../../../types/global_types';
import { Icon, Modal } from '../../reusable'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { db } from '../../../firebase';
import { GREEN_PARTIAL, RED_PARTIAL, BACKGROUND, uuid, formatDateForTable, timeAgo, returnPaneContextObj, set_open_pane } from '../../../logic/u';
import { WatchlistObj } from '../../../types/user_types';
import { CreateWatchlistModal } from '../../modals/CreateWatchlistModal';
import { WatchlistDropdown } from '../../dropdowns/WatchlistDropdown';
import { get_contextmenu_off } from '../../../logic/u';

export const WatchlistsList = () => {
  // General helpers
  const [user] = useAtom(loggedInUserAtom);
  const [userLiveDoc] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [watchlists, setWatchlists] = useState<WatchlistObj[]>([])
  const [validDrop, setValidDrop] = useState<boolean>(false)
  const [openDropdown, setOpenDropdown] = useState<any>(null)
  const [uld] = useAtom(userLiveDocAtom)
  const [selectedEmptyPane] = useAtom(selectedEmptyPaneAtom)
  const [appTab, setAppTab] = useAtom(appTabAtom)
  const [, setShowDiscardOrSaveModal] = useAtom(showDiscardOrSaveModalAtom)
  const [strategiesWithUnsavedChanges, setStrategiesWithUnsavedChanges] = useAtom(strategiesWithUnsavedChangesAtom)




  // Render tree
  useEffect(() => {
    setWatchlists(user?.watchlists || [])
  }, [user])

  const onDragUpdate = (update) => {
    const { source, destination } = update
    console.log(update)
    if (!destination) {
      setValidDrop(false)
      return
    }
    if (source.droppableId === destination.droppableId) {
      setValidDrop(true)
    }
  };

  const onDragEnd = async (result) => {
    const { source, destination, type } = result

    // If no destination or destinations don't match then return
    if (!destination) {
      return
    }
    if (source.droppableId !== destination.droppableId) {
      return
    }

    // If we have a valid drop then reorder
    let watchlistsClone: WatchlistObj[] = Array.from(watchlists) || []
    const [reorderedItem] = watchlistsClone.splice(source.index, 1) // remove dragged item
    watchlistsClone.splice(destination.index, 0, reorderedItem) // add it back

    // Set the new custom result on the user
    setWatchlists(watchlistsClone) // local state
    db.collection('users').doc(user?.uid).update({ watchlists: watchlistsClone });
  }

  // NOTE: if liveData hasn't loaded, sidebar will be missing
  if (!user) return null
  if (!userLiveDoc || !userLiveDoc.liveData) return null

  return (
    <div className='section-body'>
      {openDropdown}
      {!user.watchlists?.length ? <div className='no-entries'>No watchlists.</div> : null}
      <DragDropContext
        onDragUpdate={onDragUpdate}
        onDragEnd={onDragEnd}
        key={'modal-content'}
      >
        <div className='droppable-container'>
          <Droppable droppableId={'1'} type='bucket'>
            {(provided, snapshot) => (
              <div
                className='draggable-container'
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {watchlists.map((watchlistObj: WatchlistObj, index) => {

                  const paneThing = 'watchlist.' + watchlistObj.id
                  const paneContextObj = returnPaneContextObj(user, paneThing)
                  const paneThingIsVisible = paneContextObj?.paneThingIsVisible

                  let parentClass = 'entry'
                  if (paneThingIsVisible) {
                    parentClass += ' selected'
                  }                

                  return (
                    <Draggable
                      key={watchlistObj.id}
                      draggableId={watchlistObj.id}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            className={parentClass}
                            title={watchlistObj.name}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              backgroundColor: snapshot.isDragging ? (validDrop ? GREEN_PARTIAL : RED_PARTIAL) : ''
                            }}
                            onClick={async () => {
                              setAppTab('home')
                              set_open_pane(user, uld, selectedEmptyPane, paneThing, strategiesWithUnsavedChanges, setShowDiscardOrSaveModal)
                            }}
                            onContextMenu={(event) => {
                              if (get_contextmenu_off()) return
                              event.preventDefault()
                              const width = 250
                              const clickX = event.clientX + 5
                              const clickY = event.clientY - 10
                              setOpenDropdown(
                                <WatchlistDropdown
                                  watchlistId={watchlistObj.id}
                                  left={clickX}
                                  top={clickY}
                                  width={width}
                                  onClose={() => setOpenDropdown(null)}
                                  fromWatchlistList={true}
                                />
                              )
                            }}
                          >
                            <div className='row'>                              
                              <Icon
                                icon={'clock'}
                                set='sharp-solid'
                                size={12}
                                className={'key-icon'}
                                style={{marginTop: .75, marginRight: 10, marginLeft: 0}}
                              />
                              <div className='entry-title'>{watchlistObj.name}</div>
                            </div>
                            <div className='row'>
                              <div>{watchlistObj.tickers.length}</div>
                            </div>
                                    
                          </div>
                        )
                      }}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </div>
  )
}