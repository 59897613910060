import axios  from 'axios'
import React, { useState, useEffect } from 'react'
import { currentUser } from '../../firebase'
import { Modal, showModal, Checkbox, Switch } from '../reusable'

import { getConfig } from '../../config'
import { ErrorModal } from './ErrorModal'
import { useAtom } from 'jotai'
import {
  loggedInUserAtom,
  openModalAtom,
  userLiveDocAtom,
  phantomLogAtom,
} from '../../types/global_types'
import { set_phantom_log, updateLiveData } from '../../logic/u'
const config = getConfig() as any

export const CancelAllOpenOrdersModal = () => {
  const [ user ] = useAtom(loggedInUserAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [ uld ] = useAtom(userLiveDocAtom)
  const [ , setPhantomLog ] = useAtom(phantomLogAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  if (!user || !uld) return null

  return (
    <Modal
      title='Cancel all orders?'
      contents={[
        <div className='column width-full font-size-13 orange-text'>
          <div className='row'>
            All open orders will be canceled.
          </div>
        </div>
      ]}
      yesButtonText={'Cancel orders'}
      isDanger
      twoStepConfirmation={true}
      isButtonDisabled={buttonIsLoading}
      isButtonLoading={buttonIsLoading}
      onYes={ async () => {
        try {
          set_phantom_log('Canceling all orders...', uld, setPhantomLog)
          setOpenModal(null)
          // setButtonIsLoading(true)
          const token = await currentUser()?.getIdToken()
          const url = `${config.api_root_url}deleteOrders?create_ee_log=true`
          await axios.delete(url, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          // updateLiveData(user)
          // setButtonIsLoading(false)
        } catch (error) {
          // setButtonIsLoading(false)
          setOpenModal(<ErrorModal errorMessage={error}/>)
        }
      }}
    />
  )
}