

import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { Input } from '../reusable/Input'
import { Checkbox, Icon, Modal } from '../reusable'
import { BORDER_INPUT, set_phantom_log, updateLiveData, uuid } from '../../logic/u'
import { DropdownSelect } from '../reusable/DropdownSelect'
import { consoleOutputAtom, deploymentsAtom, functionExecutingAtom, loggedInUserAtom, openModalAtom, phantomLogAtom, strategiesAtom, userLiveDocAtom } from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import { db } from '../../firebase'
import { run_function_once } from '../../logic/run_function_once'
import { Deployment, Strategy } from '../../types/user_types'
import { INTERVALS, createEELog } from '../../logic/u'

interface DeploymentModalProps {
  strategiesToDeploy?: {[key: Strategy['id']]: boolean}
}

export const DeploymentModal = (props: DeploymentModalProps) => {
  const [user ] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [, setPhantomLog] = useAtom(phantomLogAtom)
  const [deployments, setDeployments] = useAtom(deploymentsAtom)
  const [strategies, setStrategies] = useAtom(strategiesAtom)
  const [strategiesToDeploy, setStrategiesToDeploy] = useState<{[key: Strategy['id']]: boolean}>(props.strategiesToDeploy ? props.strategiesToDeploy : {})
  const [, setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const liveCode = deployments.find(d => d.id === user?.liveDeploymentId)?.liveCode || {}
  const [name, setName] = useState<string>('')
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [helperText, setHelperText] = useState<string>('')

  useEffect(() => {
    const hasStrategies = !!Object.keys(strategiesToDeploy)?.filter(s => strategiesToDeploy[s])?.length
    setButtonEnabled(!!name && hasStrategies && !errorMessage)
  }, [name, strategiesToDeploy, errorMessage]);

  useEffect(() => {
    // Check for errors when onclick isnt running
    let message = ''
    if (!buttonIsLoading && !errorMessage && !!name) {
      if (user?.liveDeploymentId && Object.keys(strategiesToDeploy)?.filter(s => strategiesToDeploy[s])?.length) {
        message = 'This will replace your live deployment'
      }
    }

    setHelperText(message)

  }, [strategiesToDeploy, errorMessage, name, buttonIsLoading])

  useEffect(() => {
    // Check for errors when onclick isnt running
    let message = ''
    if (!buttonIsLoading) {

      // Name
      let conflictingDeployments = deployments.filter(d => d.name === name) || []
      if (conflictingDeployments.length) {
        message = 'Deployment name is being used'
      }
      // Limit to 50 characters
      if (name?.length > 50) {
        message = 'Deployment name must be < 50 characters'
      }

      // Ensure name doesn't start with number
      if (/^\d/.test(name)) {
        message = 'Deployment name must not start with a number'
      }
    }

    setErrorMessage(message)

  }, [name, buttonIsLoading])

  if (!user || !uld) return null

  const content = (
    <div className='column width-full'>
      <Input
        label={'Deployment name'}
        type={'text'}
        style={{width: '100%'}}
        initialValue={name}
        onChange={(val) => {
          let modified = val.replace(/\s/g, '_').toLowerCase().trim();
          modified = modified.slice(0, 50);
          setName(modified);
        }}
        autoFocus={true}
      />
      {name && <div className='under-input-label'>
        {'Compiled: ' + name}
      </div>}
      <div className='label-text' style={{marginBottom: 6, marginTop: 20}}>
        Strategies to deploy
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 200,
          overflowY: 'scroll',
          border: `1px solid ${BORDER_INPUT}`,
          borderRadius: 3,
          padding: 8
        }}
      >
        {strategies.map((s, i) => {

        const includedInLiveCode = Object.keys(liveCode).includes(s.id)
        const outOfSync = includedInLiveCode && liveCode[s.id] !== strategies.find(sObj => sObj.id === s.id)?.code
        return <div className={'row space-between'} style={{marginTop: i === 0 ? 0 : 10}}>
          <div
            className='row pointer white-text bold'
            onClick={() => {
              setStrategiesToDeploy({
                ...strategiesToDeploy,
                [s.id]: !strategiesToDeploy[s.id],
              });
            }}
          >
            <Checkbox
              checkMarginTop={-2}
              value={strategiesToDeploy[s.id]}
              onChange={() => {
                setStrategiesToDeploy({
                  ...strategiesToDeploy,
                  [s.id]: !strategiesToDeploy[s.id],
                });
              }}
            />
            <div
              style={{
                marginLeft: 7,
                fontSize: 11
              }}
              className='add-ellipses-cutoff'
            >
              {s.name}
            </div>
          </div>
          <div className='row'>
            {outOfSync && <Icon
              hoverText='Current is ahead of live'
              icon='circle-exclamation'
              size={14}
              style={{marginRight: 8}}
            />}
            {includedInLiveCode && <Icon
              hoverText='Included in live'
              icon='circle-check'
              size={14}
              style={{}}
            />}
          </div>
        </div>})}

      </div>

    </div>
  )

  return (
    <Modal
      title={'Deploy strategies'}
      contents={[content]}
      yesButtonText={'Deploy selected strategies'}
      isButtonDisabled={!buttonEnabled || buttonIsLoading}
      twoStepConfirmation={true}
      onYes={ async () => {
        try {
          set_phantom_log('Creating deployment...', uld, setPhantomLog)
          setOpenModal(null)

          // setButtonIsLoading(true)

          // New codepath
          let highestIndex = 0
          deployments.forEach(s => {
            if (s.index > highestIndex) {
              highestIndex = s.index
            }
          })

          interface DeploymentFunctionMetadata {
            strategy_id: string
            strategy_name: string
            function_name: string
            interval: string
          }

          // Get the code, interval, and function_metadata for each strategy
          const liveCodeToDeploy = {}
          const strategiesByMinInterval = {}
          const function_metadata: DeploymentFunctionMetadata[] = []
          const all_intervals: string[] = []
          Object.keys(strategiesToDeploy).forEach(s => {
            const strategy = strategies.find(strat => strat.id === s)
            const code = strategy?.code

            // Build function_metadata
            let strategy_min_interval_ms = 12 * 30 * 24 * 60 * 60 * 1000
            let strategy_min_interval = '12mo'
            strategy?.function_metadata?.forEach((m) => {
              if (m.type === 'on_interval') {
                const interval = m.params?.interval
                if (all_intervals.indexOf(interval) === -1) {
                  all_intervals.push(interval)
                }
                const interval_ms = INTERVALS[interval]
                if (interval_ms < strategy_min_interval_ms) {
                  strategy_min_interval_ms = interval_ms
                  strategy_min_interval = interval
                }
                function_metadata.push({
                  strategy_id: s,
                  strategy_name: strategy.name,
                  function_name: m.name,
                  interval,
                })
              }
            })
            liveCodeToDeploy[s] = code
            strategiesByMinInterval[s] = strategy_min_interval
          })


          // Find the overall minimum interval
          let min_interval = '12mo'
          let min_interval_ms = 12 * 30 * 24 * 60 * 60 * 1000
          Object.keys(strategiesByMinInterval).forEach(s => {
            const interval_ms = INTERVALS[strategiesByMinInterval[s]]
            if (interval_ms < min_interval_ms) {
              min_interval_ms = interval_ms
              min_interval = strategiesByMinInterval[s]
            }
          })

          // Create deployment object
          const newDeployment: Omit<Deployment, 'id'> = { // omit 'id' for now
            userId: user.uid,
            createdAt: new Date(),
            index: highestIndex + 1,
            name: name,
            liveCode: liveCodeToDeploy,
            minInterval: min_interval,
            strategiesByMinInterval,
            functionMetadata: function_metadata
          }
          const newDeploymentRef = await db.collection('users')
            .doc(user.uid)
            .collection('deployments')
            .add(newDeployment)
          const deploymentId = newDeploymentRef.id

          // Now update the strategy object with the generated id
          const deploymentWithId: Deployment = {
            ...newDeployment,
            id: deploymentId,
          }
          await db.collection('users')
            .doc(user.uid)
            .collection('deployments')
            .doc(deploymentId)
            .set(deploymentWithId)

          // Update user's current deployment
          await db.collection('users')
            .doc(user.uid)
            .update({
              liveDeploymentId: deploymentId,
              liveDeploymentMinInterval: min_interval,
              liveDeploymentRunIntervals: all_intervals,
              'onboardingChecklist.hasDeployedStrategy': true
            })

          // Create EELog
          const opType = 'deploy'
          const console_output = [{content: `You created a deployment: ${name}. Your designated strategies will now run.`}]
          await createEELog(db, user.uid, opType, console_output)

          console.log('created deployment')
          console.log(deploymentWithId)

          updateLiveData(user)
          // setButtonIsLoading(false)
        } catch (err) {
          // setButtonIsLoading(true)
          setOpenModal(<ErrorModal errorMessage={err}/>)
        }
      }}
      errorMessage={errorMessage}
      helperText={helperText}
    />
  )
}

