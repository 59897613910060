import {
  Icon,
} from '../reusable'
import { db, logout } from '../../firebase'
import React, { useState } from 'react'
import { DocumentsTab } from './DocumentsTab'
import { ProfileTab } from './ProfileTab'
import { SubscriptionTab } from './SubscriptionTab'
import { DisclosuresTab } from './DisclosuresTab'
import { appTabAtom, loggedInUserAtom } from '../../types/global_types'
import { useAtom } from 'jotai'
import { SecurityTab } from './SecurityTab'
import { PreferencesTab } from './PreferencesTab'

const ACCOUNT_SUBTABS = [
  {display: 'Profile', value: 'profile'},
  {display: 'Preferences', value: 'preferences'},
  {display: 'Security', value: 'security'},
  {display: 'Subscription', value: 'subscription'},
  {display: 'Documents', value: 'documents'},
  {display: 'Disclosures', value: 'disclosures'}
]

export function AccountTab() {
  const [user, ] = useAtom(loggedInUserAtom)
  const [ subTab, setSubTab ] = useState('profile')
  const [appTab, setAppTab] = useAtom(appTabAtom)
  

  let subTabContent: any = null
  switch (subTab) {
    case 'profile':
      subTabContent = <ProfileTab />
      break
    case 'preferences':
      subTabContent = <PreferencesTab />
      break
    case 'security':
      subTabContent = <SecurityTab />
      break
    case 'subscription':
      subTabContent = <SubscriptionTab />
      break
    case 'documents':
      subTabContent = <DocumentsTab />
      break
    case 'disclosures':
      subTabContent = <DisclosuresTab />
      break 
  }

  const className = user?.leftPaneClosed ? 'tab-parent no-cols' : 'tab-parent'
  return (
    <div className={className}>
      <div className='tab-header'>
        <div className='row'>
          <Icon
            icon='arrow-left'
            set='sharp-solid'
            size={16}
            style={{marginTop: 0}}
            className='margin-right-10'
            onClick={() => {
              // db.collection('users').doc(user?.uid).update({leftPaneClosed: false})
              setAppTab('home')
            }}
          />
          <div>
            Account
          </div>        
        </div>
      </div>
      <div className='subtab-header'>
        {ACCOUNT_SUBTABS.map((s) => {
          let className = 'subtab'
          if (s.value === subTab) {
            className += ' selected'
          }
          return (
            <div
              className={className}
              onClick={() => setSubTab(s.value)}
            >
              {s.display}
            </div>
          )
        })}
      </div>
      {subTabContent}
    </div>
  )
}
