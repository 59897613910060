import './LeftPane.scss'
import Split from 'react-split'
import { useAtom } from 'jotai'
import { db } from "../../firebase"
import React, { useState, useEffect, useRef } from 'react';
import {
  consoleOutputAtom,
  deploymentsAtom,
  loggedInUserAtom,
  openModalAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { Icon, Input } from '../reusable';
import { CreateTransferModal } from '../modals/CreateTransferModal';
import { CreateOrderModal } from '../modals/CreateOrderModal';
import { RunCodeModal } from '../modals/RunCodeModal';
import { AddTickersModal } from '../modals/AddTickersModal';
import { TickersList } from './left_pane/TickersList';
import { StateList } from './left_pane/StateList';
import { BalancesList } from './left_pane/BalancesList';
import { StrategiesList } from './left_pane/StrategiesList';
import { WatchlistsList } from './left_pane/WatchlistsList';
import { BacktestsList } from './left_pane/BacktestsList';
import { TablesList } from './left_pane/TablesList';
import { CreateStrategyModal } from '../modals/CreateStrategyModal';
import { CreateWatchlistModal } from '../modals/CreateWatchlistModal';
import { CreateBacktestModal } from '../modals/CreateBacktestModal';
import { WatchlistSelectorDropdown } from '../dropdowns/WatchlistsSelectorDropdown';
import { AccountDropdown } from '../dropdowns/AccountDropdown';
import { LayoutDropdown } from '../dropdowns/LayoutDropdown';
import { TEXT_GRAY, formatDateForTable, timeAgo, valueForTable } from '../../logic/u';
import { DeploymentModal } from '../modals/DeploymentModal';
import { TakeDownDeploymentModal } from '../modals/TakeDownDeploymentModal';
import { DeploymentDetailsModal } from '../modals/DeploymentDetailsModal';
import { OnboardingChecklistModal } from '../modals/OnboardingCheckListModal';
import { onboardingChecklistSteps } from '../../types/user_types';

export const LeftPane = () => {
  const [user] = useAtom(loggedInUserAtom);
  const [uld] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [openDropdown, setOpenDropdown] = useState<any>(null)
  const deploymentDropdownRef = useRef<HTMLDivElement>(null);
  const filterDropdownRef = useRef<HTMLDivElement>(null);
  const accountDropdownRef = useRef<HTMLDivElement>(null);
  const ellipsesDropdownRef = useRef<HTMLDivElement>(null);
  const [, setConsoleOutput] = useAtom(consoleOutputAtom)
  const [ indicatorState, setIndicatorState ] = useState<number>(0)
  const [deployments, ] = useAtom(deploymentsAtom)
  const liveDeployment = deployments.find(d => d.id === user?.liveDeploymentId)

  const completedOnboardingSteps = Object.values(user?.onboardingChecklist || {}).filter(val => val === true).length
  const totalOnboardingSteps = onboardingChecklistSteps.length

  const SECTIONS = [
    {
      display: 'Strategies',
      value: 'strategies',
      section: <StrategiesList />,
      plusModal: <CreateStrategyModal />,
    },
    {
      display: 'Tickers',
      value: 'tickers',
      section: <TickersList />,
      showFilterDropdown: (left, top) => (
        <WatchlistSelectorDropdown
          left={left}
          top={top + 18}
          onClose={() => setOpenDropdown(null)}
        />
      ),
      plusModal: user?.watchlists?.length ? <AddTickersModal watchlistId={user.selectedWatchlistIdHomeTab} /> : <CreateWatchlistModal />
    },
    {
      display: 'Balances',
      value: 'balances',
      section: <BalancesList />,
    },
    {
      display: 'Overall return',
      value: 'overall_return',
      section: <BalancesList category='overall_return' />,
    },
    {
      display: 'Unrealized return',
      value: 'unreallized_return',
      section: <BalancesList category='unrealized_return' />,
    },
    {
      display: 'Realized return',
      value: 'realized_return',
      section: <BalancesList category='realized_return' />,
    },
    {
      display: 'Misc',
      value: 'misc',
      section: <BalancesList category='misc' />,
    },
    {
      display: 'State',
      value: 'state',
      section: <StateList />,
    },
    {
      display: 'Watchlists',
      value: 'watchlists',
      section: <WatchlistsList />,
      plusModal: <CreateWatchlistModal />,
    },
    {
      display: 'Tables',
      value: 'tables',
      section: <TablesList />,
    },
  ];

  if (!user) return null

  const balances = uld?.liveData?.BALANCES

  const getIndicatorStateVal = (position: any) => {
    const indicatorStateArr = [
      {
        title: 'pl [pl_pc]',
        value: `${valueForTable('pl', balances?.pl, true)} [${valueForTable('pl_pc', balances?.pl_pc, true)}]`,
        greenDriver: balances?.pl,
      },
      {
        title: 'day_pl [day_pl_pc]',
        value: `${valueForTable('day_pl', balances?.day_pl, true)} [${valueForTable('day_pl_pc', balances?.day_pl_pc)}]`,
        greenDriver: balances?.day_pl,
      },
    ]
    return {...indicatorStateArr[indicatorState], length: indicatorStateArr.length}
  }

  return (
    <div className={'new-left-pane'}>
      {openDropdown}
      <div className='header'>
        <div className='row width-full' style={{marginBottom: 0}}>
          <div
            className='title-div'
            ref={accountDropdownRef}
            onClick={(e) => {
              e.stopPropagation()
              if (accountDropdownRef.current) {
                const rect = accountDropdownRef.current.getBoundingClientRect(); // Get the position of the element
                setOpenDropdown(
                  <AccountDropdown
                    left={rect.left}
                    top={rect.bottom}
                    onClose={() => setOpenDropdown(null)}
                  />
                );
              }
            }}
          >
            <Icon
              icon={'circle-' + user.alpacaObject.identity.given_name[0]}
              set='sharp-solid'
              size={15}
              style={{marginTop: 1, marginRight: 5}}
            />
            <div className='white-text bold'>Tickerbot</div>
            <Icon
              icon={'chevron-down'}
              set='sharp-solid'
              size={7}
              style={{marginLeft: 5, marginTop: -1}}
              />
          </div>
        </div>
        <div className='header-row'>
          <div>equity</div>
          <div>{valueForTable('equity', balances?.equity)}</div>
        </div>
        <div
          className='header-row hoverable'
          onClick={(e) => {
            e.stopPropagation()
            setIndicatorState((prevState) => {
              const newState = prevState + 1
              if (newState > getIndicatorStateVal({}).length - 1) {
                return 0
              }
              return newState
            })
          }}
        >
          <div>{getIndicatorStateVal({}).title}</div>
          <div className={getIndicatorStateVal({}).greenDriver > 0 ? ' pos' : ' neg'}>{getIndicatorStateVal({}).value}</div>
        </div>
      </div>
      {!uld ? <div className='new-left-pane-main-container'>
        <div style={{marginLeft: 10, fontSize: 11, color: TEXT_GRAY}}>Loading...</div>
      </div> : <div className='new-left-pane-main-container'>
        {!user.preferences?.hideOnboardingChecklist && <div
          className='lp-tag onboarding-version'
          onClick={() => {
            setOpenModal(<OnboardingChecklistModal />)
          }}
        >
          <div className='lp-tag-top'>
            <div>Onboarding...</div>
          </div>
          <div className='lp-tag-bottom'>
            <div className='lp-tag-name'>
              {`${completedOnboardingSteps} / ${totalOnboardingSteps}`}
            </div>
          </div>
        </div>}
        <div className='search-container'>
          <div
            className='search-bar'
            onClick={() => {
              setOpenModal(<AddTickersModal />)
            }}
          >
            <div className='row'>
              <Icon
                icon='magnifying-glass'
                size={16}
                style={{marginRight: 11, marginTop: 0}}
                className='search-icon'
                onClick={() => {
                  //
                }}
              />
              <div>Search ticker</div>
            </div>
            <div className='row'>
              <Icon
                icon='command'
                size={7}
                className='search-icon'
                style={{marginRight: 5}}
              />
              <div>K</div>
            </div>
          </div>
        </div>
        <div className='actions-container'>
          <div
            className='action-row'
            onClick={() => {
              setOpenModal(<CreateTransferModal />)
            }}
          >
            <div className='row'>
              <Icon
                icon='building-columns'
                set='sharp-solid'
                size={12}
                style={{marginRight: 10, marginTop: .5}}
                className='action-icon'
              />
              <div>Transfer</div>
            </div>
          </div>
          <div
            className='action-row'
            onClick={() => {
              setOpenModal(<CreateOrderModal />)
            }}
          >
            <div className='row'>
              <Icon
                icon='receipt'
                set='sharp-solid'
                size={10}
                style={{marginRight: 11, marginTop: .75, marginLeft: .75}}
                className='action-icon'
              />
              <div>Order</div>
            </div>
          </div>
          <div
            className='action-row'
            onClick={() => {
              setConsoleOutput([])            // single-console-output
              setOpenModal(<RunCodeModal />)
            }}
          >
            <div className='row'>
              <Icon
                icon='rectangle-code'
                set='sharp-solid'
                size={12}
                style={{marginRight: 10, marginTop: 1.5}}
                className='action-icon'
              />
              <div>Run some code</div>
            </div>
          </div>
          <div
            ref={deploymentDropdownRef}
            className='action-row'
            onClick={(e) => {
              e.stopPropagation()
              setOpenModal(<DeploymentModal />)
            }}
          >
            <div className='row'>
              <Icon
                icon='square-bolt'
                set='sharp-solid'
                size={12}
                style={{marginRight: 10, marginTop: 1.25}}
                className='action-icon'
              />
              <div>Deploy strategies</div>
            </div>
          </div>
          {<div
            className='lp-tag'
            onClick={() => {
              if (liveDeployment) {
                setOpenModal(<DeploymentDetailsModal deployment={liveDeployment}  />)
              } else {
                setOpenModal(<DeploymentModal />)
              }
            }}
          >
            <div className='lp-tag-top'>
              <div>Live deployment</div>
              <div title={liveDeployment?.createdAt.toDate()} className='lp-tag-date'>{user.liveDeploymentId ? timeAgo(liveDeployment?.createdAt.toDate()) : 'none'}</div>
            </div>
            <div className='lp-tag-bottom'>
              <div className='lp-tag-name'>
                {user.liveDeploymentId ? liveDeployment?.name : 'Deploy strategies...'}
              </div>
              {user.liveDeploymentId && <div
                className='lp-tag-btn'
                onClick={(e) => {
                  e.stopPropagation()
                  setOpenModal(<TakeDownDeploymentModal />)
                }}
              >
                {'TAKE DOWN'}
              </div>}
              {!user.liveDeploymentId && <Icon
                icon='turn-up'
                set='sharp-solid'
                size={7}
                className='key-icon'
              />}
            </div>
          </div>}
        </div>
        <div className='main-list-container'>
          {SECTIONS.map((s, i) => {
            const hiddenLeftPaneLists = user.hiddenLeftPaneLists ?? [];
            const updatedLists = hiddenLeftPaneLists.includes(s.value)
              ? hiddenLeftPaneLists.filter(item => item !== s.value)
              : [...hiddenLeftPaneLists, s.value];

            let sectionContainerClass = 'section-container'
            if (hiddenLeftPaneLists.includes(s.value)) {
              sectionContainerClass += ' closed'
            }
            if (i === 0) {
              sectionContainerClass += ' first'
            }
            return <div key={i} className={sectionContainerClass}>
              <div
                className={'section-header'}
                onClick={() => {
                  db.collection('users').doc(user.uid).update({ hiddenLeftPaneLists: updatedLists });
                }}
              >
                <div className='row'>
                  <div>{s.display}</div>
                  <Icon
                    icon={hiddenLeftPaneLists.includes(s.value) ? 'caret-right' : 'caret-down'}
                    set='sharp-solid'
                    size={hiddenLeftPaneLists.includes(s.value) ? 6 : 8}
                    style={{marginLeft: 7, marginTop: hiddenLeftPaneLists.includes(s.value) ? 2 : .5}}
                    className='section-icon'
                  />
                </div>
                <div className='row'>
                  {s.value === 'tickers' ? <div className='temp-text-container'>{'Watchlist: ' + user.watchlists.find(w => w.id === user.selectedWatchlistIdHomeTab)?.name}</div> : null}
                  {s.showFilterDropdown &&
                    <div
                      ref={filterDropdownRef}
                      className='section-icon-container'
                      style={{marginRight: 5}}
                      onClick={(e) => {
                        e.stopPropagation()
                        if (filterDropdownRef.current) {
                          const rect = filterDropdownRef.current.getBoundingClientRect(); // Get the position of the element
                          setOpenDropdown(s.showFilterDropdown(rect.left, rect.top))
                        }
                      }}

                    >
                      <Icon
                        icon='bars-filter'
                        set='sharp-solid'
                        size={10}
                        style={{marginTop: -.5}}
                        className='section-icon'
                      />
                    </div>
                  }
                  {s.plusModal &&
                    <div
                      className='section-icon-container'
                      style={{marginRight: -3}}
                      onClick={(e) => {
                        e.stopPropagation()
                        setOpenModal(s.plusModal)
                      }}
                    >
                      <Icon
                        icon='plus'
                        set='sharp-solid'
                        size={10}
                        style={{marginTop: -.5}}
                        className='section-icon'
                      />
                    </div>
                  }
                </div>
              </div>
              <div className={hiddenLeftPaneLists.includes(s.value)? 'section-body collapsed' : 'section-body expanded'}>{s.section}</div>
            </div>

          })}
        </div>

      </div>}
      <div className='header bottom'>
        <div className='row'>
          <div className='orange-tag' style={{marginLeft: 0}}>Plan info</div>
        </div>
        <div className='row'>
          <div
            ref={ellipsesDropdownRef}
            className='pointer'
            onClick={(e) => {
              e.stopPropagation()
              if (ellipsesDropdownRef.current) {
                const rect = ellipsesDropdownRef.current.getBoundingClientRect(); // Get the position of the element
                setOpenDropdown(
                  <LayoutDropdown
                    left={rect.left}
                    top={rect.top - 10}
                    onClose={() => setOpenDropdown(null)}
                  />
                );
              }
            }}

          >
            <Icon
              icon='ellipsis'
              set='sharp-solid'
              size={14}
              style={{marginTop: -.5}}
              className='section-icon'
              onClick={() => {
                // for hover
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}