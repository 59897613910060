
import React, { useState } from 'react';
import { formatStockPrice } from '../../logic/u';
// import { PositionDetailsModal } from '../modals/PositionDetailsModal';
import { ORANGE, ORANGE_FAINT } from '../../logic/colors';
import { Icon2 } from '../reusable/Icon2';
import './Indicators.scss';



interface OrderFullfillmentIndicatorProps {
  orders: any[]
  get_y_position_pixel: any;
  get_x_position_pixel: any;
  chartHeight: number;
  chartWidth: number;
  setOpenModal: (modal: any) => void;

}

export const OrderFullfillmentIndicator = (props: OrderFullfillmentIndicatorProps) => {
  const { orders, get_y_position_pixel, get_x_position_pixel, chartHeight, chartWidth, setOpenModal } = props;

  const order = orders[0]
  const x_position_pixel = get_x_position_pixel(order);

  // Dot, onthe basis of first order
  const y_position_pixel = get_y_position_pixel(orders[0])

  // Where does the callout go?
  let callout_on_left = false
  if (x_position_pixel > chartWidth / 2) {
    callout_on_left = true
  }

  // Assemble rows

  // Make callout rows from the first five orders
  const callout_rows: any = []
  for (let i = 0; i < Math.min(5, orders.length); i++) {
    callout_rows.push(
      <div className='r' key={`order-callout-${i}`}>
        {orders[i]._string}
      </div>
    )
  }
  if (orders.length > 5) {
    callout_rows.push(
      <div className='r' key={`order-callout-more`}>
        {`...${orders.length - 5} more orders`}
      </div>
    )
  }

  const dots: any[] = []
  dots.push(
    <div
      className='order-fill-ind-circle'
      key={`dot-circle`}
      style={{
        top: y_position_pixel
      }}
    >
      <Icon2
        icon={order.side === 'buy' ? 'caret-up' : 'caret-down'}
        set={'sharp-solid'}
        size={10}
        style={{
          marginLeft: 1,
          marginTop: 1,
          position: 'absolute',
        }}
        onClick={() => {
          // delete_indicator(indicator.id, user);
        }}
      />

      <div
        className='order-callout'
        style={{
          left: callout_on_left ? undefined : 23,
          right: callout_on_left ? 23 : undefined,
        }}
      >
        {callout_rows}
      </div>
    </div>
  )

  dots.push(
    <div
      className='order-fill-ind-dot'
      key={`dot-dot`}
      style={{
        top: y_position_pixel,
      }}
    />
  )

  // Color class
  let is_buy = order.side === 'buy';
  let color_class = ''
  if (orders.length === 1) {
    color_class = is_buy ? 'green' : ''
  } else {
    let all_red = true;
    let all_green = true;
    orders.forEach(order => {
      if (order.side === 'buy') {
        all_red = false;
      } else {
        all_green = false;
      }
    })
    if (all_green) color_class = 'green'
    if (!all_red && !all_green) color_class = 'gray'
  }






  return (
    <div
      className={'order-fill-ind-hitbox'}
      style={{
        width: 20,
        left: x_position_pixel - 10,
        height: chartHeight,
      }}
    >
      <div
        className={`order-fill-ind ${color_class}`}
      >
        {dots}
      </div>
    </div>
  );
};
