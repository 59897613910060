import '../SignUp.scss'
import React, { useState, useEffect } from 'react'
import { Input, Button, DropdownSelect } from '../../reusable'
import { db } from '../../../firebase'
import { loggedInUserAtom, openModalAtom } from '../../../types/global_types'
import {
  SignUpStage,
  AlpacaContextType,
} from '../../../types/user_types'
import { useAtom } from 'jotai'
import { ErrorModal } from '../../modals/ErrorModal'

export const DisclosuresImmediateFamilyExposedStep = () => {
  const [loggedInUser] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [buttonIsLoading, setButtonIsLoading] = useState<boolean>(false)
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false)

  // This signup stage
  const context_type: AlpacaContextType = 'IMMEDIATE_FAMILY_EXPOSED'
  const [isImmediateFamilyExposed, setIsImmediateFamilyExposed] = useState<boolean | undefined>(loggedInUser?.alpacaObject?.disclosures?.immediate_family_exposed)
  const [relativesFirstName, setRelativesFirstName] = useState<string>(loggedInUser?.alpacaObject?.disclosures?.context?.find(context => context?.context_type === context_type)?.given_name || '')
  const [relativesLastName, setRelativesLastName] = useState<string>(loggedInUser?.alpacaObject?.disclosures?.context?.find(context => context?.context_type === context_type)?.family_name || '')

  const previousSignUpStage: SignUpStage = 'DisclosuresPoliticallyExposed'
  const nextSignUpStage: SignUpStage = 'TrustedContact'

  useEffect(() => {
    if (isImmediateFamilyExposed) {
      setButtonEnabled(!!relativesFirstName && !!relativesLastName);
    }
  }, [relativesFirstName, relativesLastName, isImmediateFamilyExposed]);

  useEffect(() => {
    if (!isImmediateFamilyExposed && isImmediateFamilyExposed !== null && isImmediateFamilyExposed !== undefined) {
      setButtonEnabled(true);
    }
  }, [isImmediateFamilyExposed]);

  if (!loggedInUser) return null

  return (
    <div className='signup-container'>
      <div className='signup-form-container'>
        <div className='signup-context-title'>
          Disclosures (4 of 4)
        </div>
        <DropdownSelect
          label={'Are you a family member or relative of a senior political figure?'}
          options={[
            {display: 'Yes', value: true},
            {display: 'No', value: false},
          ]}
          className={'margin-top-15'}
          width={482}
          height={30}
          value={isImmediateFamilyExposed}
          onChange={(val) => {setIsImmediateFamilyExposed(val)}}
        />
        {!isImmediateFamilyExposed ? null : (
          <div className='column width-full'>
            <Input
              label={`Relative's first name`}
              type={'text'}
              className={'margin-top-20'}
              style={{width: '100%'}}
              initialValue={relativesFirstName}
              onChange={(val) => {setRelativesFirstName(val)}}
            />
            <Input
              label={`Relative's last name`}
              type={'text'}
              className={'margin-top-20'}
              style={{width: '100%'}}
              initialValue={relativesLastName}
              onChange={(val) => {setRelativesLastName(val)}}
            />
          </div>
        )}
        <Button
          display={'Next'}
          className={'margin-top-25'}
          style={{width: '100%', paddingTop: '10px', paddingBottom: '10px'}}
          isDisabled={!buttonEnabled || buttonIsLoading}
          isLoading={buttonIsLoading}
          onClick= {async () => {
            try {
              setButtonIsLoading(true)
              const contextArray = loggedInUser.alpacaObject?.disclosures?.context || []
              const newContextObj = {
                context_type: context_type,
                given_name: relativesFirstName,
                family_name: relativesLastName
              }

              const foundIndex = contextArray.findIndex(contextObj => contextObj.context_type === context_type)
              if (foundIndex !== -1) {
                contextArray.splice(foundIndex, 1, newContextObj)
              } else {
                contextArray.push(newContextObj)
              }
              await db.collection('users').doc(loggedInUser.uid).update({
                'alpacaObject.disclosures.immediate_family_exposed': isImmediateFamilyExposed,
                'alpacaObject.disclosures.context': isImmediateFamilyExposed ? contextArray : null,
                signUpStage: nextSignUpStage,
              })
              setButtonIsLoading(false)
            } catch (err) {
              setButtonIsLoading(false)
              setOpenModal( <ErrorModal errorMessage={err.message}/> )
            }
          }}
        />
        <Button
          display={'Back'}
          className={'margin-top-15'}
          variant={'secondary'}
          style={{width: '100%', paddingTop: '10px', paddingBottom: '10px'}}
          onClick= {async () => {await db.collection('users').doc(loggedInUser.uid).update({signUpStage: previousSignUpStage})}}
        />
      </div>
    </div>
  )

}