import { useAtom } from 'jotai'
import {
  loggedInUserAtom,
  openModalAtom,
  showDiscardOrSaveModalAtom,
  strategiesWithUnsavedChangesAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import {
  Icon,
  DropdownMenu,
} from '../reusable'
import React, { useState, useEffect } from 'react'
import { CreateWatchlistModal } from '../modals/CreateWatchlistModal'
import { DuplicateWatchlistModal } from '../modals/DuplicateWatchlistModal'
import { DeleteWatchlistModal } from '../modals/DeleteWatchlistModal'
import { TEXT_GRAY, returnPaneContextObj, set_open_pane } from '../../logic/u'
import { db } from '../../firebase'

interface WatchlistDropdownProps {
  watchlistId: string
  left: number
  top: number
  width: number
  onClose: () => void
  fromWatchlistList?: boolean
  paneIndex?: number
}

export const WatchlistDropdown = (props: WatchlistDropdownProps) => {
  const [user ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const {watchlistId, left, top, width, onClose, fromWatchlistList, paneIndex} = props
  const [uld] = useAtom(userLiveDocAtom)
  const [, setShowDiscardOrSaveModal] = useAtom(showDiscardOrSaveModalAtom)
  const [strategiesWithUnsavedChanges, setStrategiesWithUnsavedChanges] = useAtom(strategiesWithUnsavedChangesAtom)


  if (!user) return null

  const watchlist = user.watchlists.find(w => w.id === watchlistId)

  interface DropdownItem {
    display: JSX.Element
    isDisabled?: boolean
    onClick?: () => void
  }
  let contents: DropdownItem[] = []
  contents.push(
    {
      display: (
        <div className='font-size-10 gray-text'>
          {watchlist?.name}
        </div>
      )
    },
  )
  contents.push(
    {
      display: (
        <div className='left'>
          <Icon
            icon='copy'
            set='sharp-solid'
            size={10}
            style={{marginRight: 10}}
          />
          <div>Duplicate</div>
        </div>
      ),
      onClick: async () => {
        setOpenModal(<DuplicateWatchlistModal watchlistId={watchlistId} />)
      }
    },
    {
      display: (
        <div className='left'>
          <Icon
            icon='trash'
            set='sharp-solid'
            size={9}
            style={{marginRight: 10, marginTop: .5}}
            className={user?.watchlists.find(w => w.id === watchlistId)?.isDefault ? 'menu-item-icon-disabled' : ''}
          />
          <div>Delete</div>
        </div>
      ),
      onClick: () => {
        setOpenModal(<DeleteWatchlistModal watchlistId={watchlistId} />)
      }
    }
  )

  if (!props.fromWatchlistList && user) {
    if (returnPaneContextObj(user, 'watchlist.' + watchlistId)?.paneThingIsVisible) {
      contents.push(
        {
          display: (
            <div
              style={{width: '100%', height: 1, backgroundColor: TEXT_GRAY, marginTop: 3, marginBottom: 5}}
            ></div>
          )
        },
        {
          display: (
            <div className='left'>
              <Icon
                icon='circle-x'
                set='sharp-solid'
                size={9}
                style={{marginRight: 10}}
              />
              <div>Clear pane</div>
            </div>
          ),
          onClick: () => {
            if (paneIndex) {
              set_open_pane(user, uld, paneIndex, null, strategiesWithUnsavedChanges, setShowDiscardOrSaveModal)
            }
          }
        },
      )
    }
  }
  
  return (
    <DropdownMenu
      //@ts-ignore
      posLeft={left}
      posTop={top}
      width={width}
      height={500}
      onClose={() => onClose()}
      noArrowKeys={true}
      contents={contents}
    />
  )
}