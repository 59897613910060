import { useAtom } from 'jotai';
import { loggedInUserAtom, userLiveDocAtom, openModalAtom, backtestsAtom, selectedEmptyPaneAtom, appTabAtom } from '../../../types/global_types';
import { Icon, Modal, ProgressBar } from '../../reusable'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { db } from '../../../firebase';
import { GREEN_PARTIAL, RED_PARTIAL, BACKGROUND, uuid, formatDateForTable, timeAgo, set_open_pane, returnPaneContextObj } from '../../../logic/u';
import { get_contextmenu_off } from '../../../logic/u';
import { Backtest } from '../../../types/backtest_types';
import { CreateBacktestModal } from '../../modals/CreateBacktestModal';
import { BacktestDropdown } from '../../dropdowns/BacktestDropdown';


const TABLES = [
  {display: 'Positions', value: 'positions'},
  {display: 'Orders', value: 'orders'},
  {display: 'Transfers', value: 'transfers'},
  {display: 'Transactions', value: 'transactions'},
  {display: 'Balances', value: 'balances'},
  {display: 'Logs', value: 'logs'},
  {display: 'Deployments', value: 'deployments'},
  {display: 'State', value: 'state'},
]


export const TablesList = () => {
  // General helpers
  const [user] = useAtom(loggedInUserAtom);
  const [userLiveDoc] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [backtests, setBacktests] = useAtom(backtestsAtom)
  const [validDrop, setValidDrop] = useState<boolean>(false)
  const [openDropdown, setOpenDropdown] = useState<any>(null)
  const [uld] = useAtom(userLiveDocAtom)
  const [selectedEmptyPane] = useAtom(selectedEmptyPaneAtom)
  const [appTab, setAppTab] = useAtom(appTabAtom)




  const onDragUpdate = (update) => {
    const { source, destination } = update
    console.log(update)
    if (!destination) {
      setValidDrop(false)
      return
    }
    if (source.droppableId === destination.droppableId) {
      setValidDrop(true)
    }
  };

  const onDragEnd = async (result) => {
   //
  }

  // NOTE: if liveData hasn't loaded, sidebar will be missing
  if (!user) return null
  if (!userLiveDoc || !userLiveDoc.liveData) return null

  return (
    <div className='section-body'>
      {TABLES.map((table, i) => {

        const paneThing = 'table.' + table.value
        const paneContextObj = returnPaneContextObj(user, paneThing)
        const paneIsVisible = paneContextObj?.paneThingIsVisible

        let parentClass = 'entry'
        if (paneIsVisible) {
          parentClass += ' selected'
        }
        return (
          <div
            key={i}
            className={parentClass}
            onClick={() => {
              setAppTab('home')
              set_open_pane(user, uld, selectedEmptyPane, paneThing)
            }}
          >
            <div className='row'>
              <Icon
                icon={'table'}
                set='sharp-solid'
                size={12}
                className={'key-icon'}
                style={{marginTop: 1.5, marginRight: 10, marginLeft: 0}}
              />
              <div>
                {table.display}
              </div>            
            </div>            
          </div>
        )
      })}
    </div>
  )
}