import { useAtom } from 'jotai'
import {
  deploymentsAtom,
  loggedInUserAtom,
  openModalAtom,
  selectedCodeEditorSlotAtom,
  showDiscardOrSaveModalAtom,
  strategiesWithUnsavedChangesAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import { db, currentUser } from '../../firebase'
import {
  Icon,
  DropdownMenu,
} from '../reusable'
import React, { useState, useEffect } from 'react'
import { DividerPaneIcon } from '../reusable/DividerPaneIcon'
import { CreateStrategyModal } from '../modals/CreateStrategyModal'
import { DuplicateStrategyModal } from '../modals/DuplicateStrategyModal'
import { DeleteStrategyModal } from '../modals/DeleteStrategyModal'
import { Strategy } from '../../types/user_types'
import { TEXT_GRAY, returnPaneContextObj, set_open_pane } from '../../logic/u'

interface StrategyDropdownProps {
  strategyObj: Strategy
  paneIndex?: number
  left: number
  top: number
  width: number
  onClose: () => void
  fromStrategiesList?: boolean
}

export const StrategyDropdown = (props: StrategyDropdownProps) => {
  const [user ] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const [deployments, setDeployments] = useAtom(deploymentsAtom)
  const {strategyObj, paneIndex, left, top, width, onClose, fromStrategiesList} = props
  const liveCode = deployments.find(d => d.id === user?.liveDeploymentId)?.liveCode || {}
  const includedInLiveCode = Object.keys(liveCode).includes(strategyObj.id)
  const [, setShowDiscardOrSaveModal] = useAtom(showDiscardOrSaveModalAtom)
  const [strategiesWithUnsavedChanges, setStrategiesWithUnsavedChanges] = useAtom(strategiesWithUnsavedChangesAtom)

  interface DropdownItem {
    display: JSX.Element
    isDisabled?: boolean
    onClick?: () => void
  }
  let contents: DropdownItem[] = []

  contents.push(
    {
      display: (
        <div className='font-size-10 gray-text'>
          {strategyObj.name}
        </div>
      )
    },
    {
      display: (
        <div className='left'>
          <Icon
            icon='copy'
            set='sharp-solid'
            size={10}
            style={{marginRight: 10}}
          />
          <div>Duplicate</div>
        </div>
      ),
      onClick: async () => {
        setOpenModal(<DuplicateStrategyModal strategy={strategyObj} />)
      }
    },
    {
      display: (
        <div className='left'>
          <Icon
            icon='trash'
            set='sharp-solid'
            size={9}
            style={{marginRight: 10, marginTop: .5}}
            className={includedInLiveCode ? 'menu-item-icon-disabled' : ''}
          />
          <div>Delete {includedInLiveCode ? '(live)' : null}</div>
        </div>
      ),
      isDisabled: includedInLiveCode,
      onClick: () => {
        setOpenModal(<DeleteStrategyModal strategy={strategyObj} />)
      }
    },
  )

  if (user && !fromStrategiesList) {
    if (returnPaneContextObj(user, 'strategy.' + props.strategyObj.id)?.paneThingIsVisible) {
      contents.push(  
        {
          display: (
            <div
              style={{width: '100%', height: 1, backgroundColor: TEXT_GRAY, marginTop: 3, marginBottom: 5}}
            ></div>
          )
        },      
        {
          display: (
            <div className='left'>
              <Icon
                icon='circle-x'
                set='sharp-solid'
                size={9}
                style={{marginRight: 10}}
              />
              <div>Clear pane</div>
            </div>
          ),
          onClick: () => {
            if (paneIndex) {
              set_open_pane(user, uld, paneIndex, null, strategiesWithUnsavedChanges, setShowDiscardOrSaveModal);
            }
          }
        },
      )
    }
  }

  return (
    <DropdownMenu
      //@ts-ignore
      posLeft={left}
      posTop={top}
      width={width}
      height={500}
      onClose={() => onClose()}
      noArrowKeys={true}
      contents={contents}
    />
  )
}