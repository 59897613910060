import "./TBChart.scss"
import React, { useEffect, useState, useRef, useLayoutEffect } from "react"
import { useAtom } from "jotai"
import {
  loggedInUserAtom, userLiveDocAtom, streamingQuotesAtom
} from "../../types/global_types"
import { Indicator } from '../../types/user_types'
import {
  format_price,
  INTERVALS,
  is_internal,
  ORANGE,
  uuid,
} from "../../logic/u"
import { formatNumber } from './chart_utils'
import { get_y_label_prices } from './chart_utils/get_y_label_prices'
import { make_x_axis_labels } from './chart_utils/make_x_axis_labels'


import { add_indicators_if_missing, get_indicators_for_chart } from '../../logic/indicator_helpers'
import { isDraggingAtom } from '../../types/global_types'
import { OscillatorPlot } from "./OscillatorPlot"
import { OscillatorPlotTabBar } from "./OscillatorPlotTabBar"
import { IndicatorSeriesData } from './OscillatorPlot'
import { CANDLE_GREEN, CANDLE_RED } from "../../logic/colors"

import { ChartOverlay } from './ChartOverlay'
import { set } from "lodash"


const MARKET_CLOSED_COLOR = '#3a004c80'   // midnight purple
const LIVE_STREAMING_DATA_ENABLED = true     // FOR TESTING PURPOSES
const VOLUME_HEIGHT = 60

interface TBChartInnerProps {
  symbol: string
  bars: any[]
  interval: string
  isInternal: boolean
  xStartIndex: number
  xEndIndex: number
  yStartPrice: number
  yRange: number
  zoom: (deltaX: number) => void          // anchors right, zooms number of candles
  pan: (deltaX: number) => void           // slides L/R
  panVertical: (deltaY: number) => void   // slide up/down
  zoomVertical: (deltaY: number) => void  // increases/decreases price magnification
  stepBack: number
  chartMode: 'candles' | 'line'
  setStepBack: (stepBack: number) => void
  // showStepBack: boolean
  isLoadingNewTimeframe: boolean
  isLoading: boolean
}

export const TBChartInner = (props: TBChartInnerProps) => {
  const chartRef = useRef<HTMLDivElement>(null) // Reference to the chart div element
  const [measurements, setMeasurements] = useState({ width: 1000, height: 500 });
  const [hasOscillatorPlot, setHasOscillatorPlot] = useState<boolean>(false)
  const [isDragging, setIsDragging] = useAtom(isDraggingAtom)
  const [endOfDataVisible, setEndOfDataVisible] = useState(false);
  const [oscillatorIndicator, setOscillatorIndicator] = useState<Indicator | null>(null)
  const [demarcateDays, setDemarcateDays] = useState(false)
  const [user] = useAtom(loggedInUserAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [streamingQuotes] = useAtom(streamingQuotesAtom)  // universal {symbol: {price: number, timestamp: string}}
  const [streamingData, setStreamingData] = useState<Array<{price: number, timestamp: string}>>([]);


  const [chartParams, setChartParams] = useState<any>({
    candles: [],
    xLabels: [],
    range: 100,
    yStartPrice: 0,
    yRange: 100,
    maxVolume: 100,
    isFlatLineAtZero: false,
    futureCandlesCount: 0,
    lastCandlePrice: 0,
    lastCandleIsRed: false,
    presentInView: false

  });

  if (!user) return null
  if (!uld) return null


  // Record all streaming price data as it comes in
  useEffect(() => {
    if (streamingQuotes && streamingQuotes[props.symbol]) {
      const newQuote = streamingQuotes[props.symbol];
      setStreamingData(prevData => {
        const updatedData = [
          ...prevData,
          {
            price: newQuote.price,
            timestamp: newQuote.timestamp
          }
        ];
        return updatedData;
      });
    }
  }, [streamingQuotes, props.symbol]);

  // Measurement of dimensions
  useLayoutEffect(() => {
    const updateMeasurements = () => {
      if (chartRef.current) {
        const { width, height } = chartRef.current.getBoundingClientRect();
        setMeasurements({ width, height });
      }
    };
    updateMeasurements();

    setTimeout(updateMeasurements, 200); // HACK: Delayed update to ensure correct measurements

    // Add resize observer
    const resizeObserver = new ResizeObserver(updateMeasurements);
    if (chartRef.current) {
      resizeObserver.observe(chartRef.current);
    }
    return () => {
      if (chartRef.current) {
        resizeObserver.unobserve(chartRef.current);
      }
    };
  }, [chartRef.current]);

  // Demarcate days, load from user
  useEffect(() => {
    if (is_internal(props.symbol)) return
    const demarcate_days = !!user.charts[props.symbol]?.demarcateDays
    if (demarcate_days !== demarcateDays) setDemarcateDays(demarcate_days)
  }, [user])

  const startOfDataLabelRef = useRef(null);

  // Crosshair position
  const chartOverlayRef = useRef(null);
  const updateCrosshairPosition = () => {
    if (chartOverlayRef.current) {
      //@ts-ignore
      chartOverlayRef.current.updateCrosshair();
    }
  };


  const marketClosedLines: JSX.Element[] = [];
  const calculateCandleGap = (current: any, previous: any) => {
    const currentDate = new Date(current.t);
    const previousDate = new Date(previous.t);
    const gap = currentDate.getTime() - previousDate.getTime();
    const intervalMs = INTERVALS[props.interval];
    return Math.floor(gap / intervalMs) - 1;
  };

  const createLinePath = () => {
    let pathD = ""
    let lastActualCandleIndex = chartParams.candles.findIndex(candle => candle.isFutureCandle) - 1;
    if (lastActualCandleIndex === -2) lastActualCandleIndex = chartParams.candles.length - 1;

    chartParams.candles.forEach((candle, index) => {
      if (candle.c !== null && index <= lastActualCandleIndex) {
        const x = get_x_for_candle_index(candle.relative_index)
        const y = get_y_for_price(candle.c)
        if (index === 0) {
          pathD = `M${x},${y}`
        } else {
          pathD += ` L${x},${y}`
        }
      }
    })
    return pathD
  }

  const createFillPath = () => {
    let lastActualCandleIndex = chartParams.candles.findIndex(candle => candle.isFutureCandle) - 1;
    if (lastActualCandleIndex === -2) lastActualCandleIndex = chartParams.candles.length - 1;

    let pathD = `M${get_x_for_candle_index(chartParams.candles[0].relative_index)},${measurements.height}`
    chartParams.candles.forEach((candle, index) => {
      if (index <= lastActualCandleIndex) {
        const x = get_x_for_candle_index(candle.relative_index)
        const y = get_y_for_price(candle.c)
        pathD += ` L${x},${y}`
      }
    });
    const lastX = get_x_for_candle_index(chartParams.candles[lastActualCandleIndex].relative_index)
    pathD += ` L${lastX},${measurements.height} Z`
    return pathD
  }

  // Check if the end of data is within view
  useEffect(() => {
    const is_all = props.interval === '1w'    // HACK, fix this at some point
    const end_in_sight = props.bars.length > 0 && props.xStartIndex <= props.bars[0].relative_index
    if (is_all && end_in_sight) {
      //@ts-ignore
      const labelWidth = startOfDataLabelRef?.current?.offsetWidth;
      const labelPosition = get_x_for_candle_index(props.bars[0].relative_index);
      const spaceAvailable = measurements.width - labelPosition;
      let label_visible = (spaceAvailable >= labelWidth) && (labelPosition > 75)
      setEndOfDataVisible(label_visible);
    } else {
      setEndOfDataVisible(false);
    }
  }, [props.xStartIndex, props.bars]);

  /* MAIN - this calculates everything from which we draw candles */
  useEffect(() => {
    let _chartCandles = add_indicators_if_missing(user, props.symbol, props.interval, props.bars, uld)

    // Combine real candles with streaming candles
    if (LIVE_STREAMING_DATA_ENABLED && streamingData.length && _chartCandles.length) {
      const lastRealCandle = _chartCandles[_chartCandles.length - 1];
      const lastRealCandleTime = getCandleTimestamp(lastRealCandle.t, props.interval);
      const currentTime = Date.now();
      const intervalMs = INTERVALS[props.interval];

      // Check if the last preloaded candle's period is still open
      const lastCandlePeriodIsOpen = currentTime < (lastRealCandleTime + intervalMs);

      // Group streaming data by candle periods
      const streamingCandles = streamingData.reduce((acc, quote) => {
        const candleTime = getCandleTimestamp(quote.timestamp, props.interval);
        if (!acc[candleTime]) {
          acc[candleTime] = {
            prices: [quote.price],
            timestamp: candleTime,
            t: new Date(candleTime).toISOString(),
            firstTimestamp: quote.timestamp
          };
        } else {
          acc[candleTime].prices.push(quote.price);
        }
        return acc;
      }, {});

      // Sort timestamps for proper indexing
      const sortedTimestamps = Object.keys(streamingCandles)
        .map(Number)
        .sort((a, b) => a - b);

      // Handle the merging of the last preloaded candle with streaming data
      if (lastCandlePeriodIsOpen && sortedTimestamps.length > 0) {
        const firstStreamingTime = sortedTimestamps[0];
        const firstStreamingData = streamingCandles[firstStreamingTime];

        if (getCandleTimestamp(firstStreamingData.firstTimestamp, props.interval) === lastRealCandleTime) {
          _chartCandles[_chartCandles.length - 1] = {
            ...lastRealCandle,
            c: firstStreamingData.prices[firstStreamingData.prices.length - 1],
            h: Math.max(lastRealCandle.h, ...firstStreamingData.prices),
            l: Math.min(lastRealCandle.l, ...firstStreamingData.prices),
            v: lastRealCandle.v + firstStreamingData.prices.length
          };
          delete streamingCandles[firstStreamingTime];
          sortedTimestamps.shift();
        }
      }

      // Convert remaining grouped data to candles with correct relative indices
      const newCandles = sortedTimestamps.reduce((acc: any, timestamp) => {
        const data = streamingCandles[timestamp];
        const prices = data.prices;

        // Calculate the correct relative index based on time difference
        const timeDiff = timestamp - lastRealCandleTime;
        const indexOffset = Math.floor(timeDiff / intervalMs);

        // Get the previous candle's close price for this candle's open
        const previousCandle = acc.length > 0 ? acc[acc.length - 1] :
                             lastCandlePeriodIsOpen ? _chartCandles[_chartCandles.length - 1] : lastRealCandle;

        const candle = {
          t: data.t,
          o: previousCandle.c, // Use previous candle's close as this candle's open
          h: Math.max(previousCandle.c, ...prices), // Include previous close in high calculation
          l: Math.min(previousCandle.c, ...prices), // Include previous close in low calculation
          c: prices[prices.length - 1],
          v: prices.length,
          relative_index: lastRealCandle.relative_index + indexOffset
        };

        acc.push(candle);
        return acc;
      }, []);

      // Add remaining streaming candles
      _chartCandles = [..._chartCandles, ...newCandles];
    }


    // Calculate futureCandlesCount
    const _futureCandlesCount = Math.max(0, props.xEndIndex - _chartCandles[_chartCandles.length - 1].relative_index);

    let _minPrice = Math.min(..._chartCandles.map(candle => candle.l || candle.c));
    let _maxPrice = Math.max(..._chartCandles.map(candle => candle.h || candle.c));

    // Calculate range and padding
    let _range = _maxPrice - _minPrice;
    const paddingPercentage = 0.1;

    // Special case for when all values are the same or very close
    if (_range < Number.EPSILON) {
      const value = (_minPrice + _maxPrice) / 2;
      _range = Math.abs(value) * 0.1;
      _minPrice = value - _range / 2;
      _maxPrice = value + _range / 2;
    }

    const padding = _range * paddingPercentage;

    // Apply padding
    _minPrice -= padding;
    _maxPrice += padding;

    // Ensure zero is visible if it's close to the range
    if (_minPrice > 0 && _minPrice / _range < 0.2) {
      _minPrice = -padding;
    }

    // Recalculate range after adjustments
    _range = _maxPrice - _minPrice;

    // Use these values for yStartPrice and yRange
    const _yStartPrice = props.yStartPrice || _minPrice;
    const _yRange = props.yRange || _range;

    // Find the tallest volume bar
    const _maxVolume = Math.max(..._chartCandles.map((candle: any) => candle.v || 0))

    const isFlatLineAtZero = _chartCandles.every(candle => candle.c === 0)
    if (props.isInternal && isFlatLineAtZero) {
      _maxPrice = 100
      _minPrice = -5
    }

    // Calculate X axis labels
    const totalCandles = _chartCandles.length + _futureCandlesCount
    let candleTimestamps = _chartCandles.map(candle => candle.t)
    let _x_labels = make_x_axis_labels(candleTimestamps, props.interval, totalCandles, measurements.width)
    if (_x_labels.length > 32) _x_labels = []

    // Find most recent price
    const lastNonFutureCandle = _chartCandles[_chartCandles.length - 1];
    const _lastCandlePrice = lastNonFutureCandle?.c || 0;
    const _lastCandleIsRed = lastNonFutureCandle?.c < lastNonFutureCandle?.o;

    setChartParams({
      candles: _chartCandles,
      xLabels: _x_labels,
      range: _range,
      yStartPrice: _yStartPrice,
      yRange: _yRange,
      maxVolume: _maxVolume,
      futureCandlesCount: _futureCandlesCount,
      lastCandlePrice: _lastCandlePrice,
      lastCandleIsRed: _lastCandleIsRed,
      presentInView: _futureCandlesCount > 0
    })
  }, [props.bars, props.xStartIndex, props.xEndIndex, props.yStartPrice, props.yRange, streamingData, props.interval, user, uld]);


  // We can have one oscillator indicator visible at a time. Find it, setOscillatorIndicator, setHasOscillatorPlot
  const indicators_present = get_indicators_for_chart(user, props.symbol, props.interval)
    useEffect(() => {
      let oscillator: any = null
      const indicator_keys = Object.keys(indicators_present)
      for (let i = 0; i < indicator_keys.length; i++) {
        const indicator_key = indicator_keys[i]
        const indicator = indicators_present[indicator_key]
        if (indicator.oscillator_settings && !indicator.isHidden) {
          oscillator = indicator
          break
        }
      }
      setOscillatorIndicator(oscillator || null)
      setHasOscillatorPlot(!!oscillator)
    }, [indicators_present])

  // Set up oscillator_data to receive datapoints
  let oscillator_title = ''
  const oscillator_data: any = {}     // map, will be made into array later
  const indicators_present_keys = Object.keys(indicators_present)
  for (let i = 0; i < indicators_present_keys.length; i++) {
    const indicator_key = indicators_present_keys[i]
    const indicator = indicators_present[indicator_key]

    if (!indicator.oscillator_settings) continue
    oscillator_title = indicator.value // indicator.display
    const plot_keys = Object.keys(indicator.plots)
    for (let j = 0; j < plot_keys.length; j++) {
      const plot_key = plot_keys[j]
      const plot = indicator.plots[plot_key]

      if (indicator.oscillator_settings && !indicator.isHidden) {
        const series_data: IndicatorSeriesData = {
          data: [],
          color: plot.color,
          weight: plot.weight,
          style: plot.style,
          name: plot_key
        }
        oscillator_data[plot_key] = series_data
      }
    }
  }


  const get_price_for_y = (y: number) => {
    const yDollarsPerPixel = chartParams.yRange / measurements.height;
    const pixels_reversed = measurements.height - y;
    const price = chartParams.yStartPrice + (pixels_reversed * yDollarsPerPixel);
    let ret = price.toFixed(2);

    // Handle Y value within oscillator chart
    if (hasOscillatorPlot) {
      let is_in_oscillator_chart = y - measurements.height > 0;
      if (is_in_oscillator_chart) {
        const y_within_plot = 100 - (y - measurements.height);      // accommodating tab bar
        if (y_within_plot < 0) return 0;
        //@ts-ignore
        const oscMin = window.oscillatorChartMin;
        //@ts-ignore
        const oscMax = window.oscillatorChartMax;
        const oscRange = oscMax - oscMin;
        const oscPixelsPerPixel = oscRange / 80;
        const oscPrice = oscMin + (y_within_plot * oscPixelsPerPixel);
        ret = oscPrice.toFixed(2);
      }
    }

    if (ret === '-0.00') {    // tiny number rounding issue
      ret = '0.00';
    }
    return ret;
  };


  const get_y_for_price = (price: number) => {
    const yDollarsPerPixel = chartParams.yRange / measurements.height;
    const pixels_reversed = (price - chartParams.yStartPrice) / yDollarsPerPixel;
    return measurements.height - pixels_reversed;
  };


  const total_candles = props.xEndIndex - props.xStartIndex
  const candle_width = measurements.width / total_candles

  const get_x_for_candle_index = (abs_index: number) => {
    const rel_index = abs_index - props.xStartIndex
    const x = candle_width * rel_index
    return x
  }

  // Returns absolute index of the candle with the relevant date
  const get_candle_index_for_timestamp = (timestamp: number) => {
    const interval = props.interval;
    const interval_ms = INTERVALS[interval];
    const half_interval = interval_ms / 2;

    if (props.bars.length === 0) {
      return null;
    }

    // Filter bars to get visible candles
    let visibleCandles = props.bars.filter(
      (bar) => bar.relative_index >= props.xStartIndex && bar.relative_index <= props.xEndIndex
    );

    // 7 December 2024 - I think this is fixed now. Bug was apparently caused by
    // a race condition between two separate load operations
    if (visibleCandles.length === 0 || !visibleCandles[0].t) {
      console.log(`INTERNAL ERROR`)
      debugger
    }

    const firstVisibleCandleTimestamp = new Date(visibleCandles[0].t).getTime();
    const lastVisibleCandleTimestamp = new Date(visibleCandles[visibleCandles.length - 1].t).getTime();

    if (timestamp < firstVisibleCandleTimestamp) {
      // Timestamp is before the first visible candle
      const diff = firstVisibleCandleTimestamp - timestamp;
      const steps = Math.ceil(diff / interval_ms);
      return visibleCandles[0].relative_index - steps;
    } else if (timestamp > lastVisibleCandleTimestamp) {
      // Timestamp is after the last visible candle
      const diff = timestamp - lastVisibleCandleTimestamp;
      const steps = Math.ceil(diff / interval_ms);
      return visibleCandles[visibleCandles.length - 1].relative_index + steps;
    } else {
      // Timestamp is within the range of the visible bars
      for (let i = 0; i < visibleCandles.length; i++) {
        const candle = visibleCandles[i];
        const candleTimestamp = new Date(candle.t).getTime();
        if (Math.abs(candleTimestamp - timestamp) < half_interval) {
          return candle.relative_index;
        }
      }
    }

    return null;
  };

  const snap_tos: number[] = []
  const x_to_timestamp: any = {}

  const make_candle = (data: any, width: number) => {
    const o = get_y_for_price(data.o)
    const h = get_y_for_price(data.h)
    const l = get_y_for_price(data.l)
    const c = get_y_for_price(data.c)

    const wick_x = get_x_for_candle_index(data.relative_index)
    const candle_x = wick_x - (width / 2)
    snap_tos.push(wick_x)
    x_to_timestamp[wick_x] = data.t

    let color: string
    let height: number
    let wick_height: number
    let candle_y: number
    let wick_y: number

    // RED CANDLE
    if (data.o > data.c) {
      color = CANDLE_RED
      height = c-o
      wick_height = l-h
      candle_y = o
      wick_y = h
    }

    // GREEN CANDLE
    else if (data.o < data.c) {
      color = CANDLE_GREEN
      height = o-c
      wick_height = l-h
      candle_y = c
      wick_y = h
    }

    // FLAT CANDLE
    else {
      color = 'gray'
      wick_height = l-h
      height = 1
      candle_y = c
      wick_y = c
    }

    return [
      <rect x={candle_x} y={candle_y} width={width} height={height} fill={color} key={'c-' + candle_x} />,
      <rect x={wick_x} y={wick_y} width="1" height={wick_height} fill={color} key={'w-' + candle_y} />
    ]
  }

  const make_volume_bar = (data: any, width: number) => {
    const volume = data.v;
    let volume_height = (volume / chartParams.maxVolume) * VOLUME_HEIGHT;
    const volume_x = get_x_for_candle_index(data.relative_index);
    const volume_y = measurements.height - volume_height;
    let color = CANDLE_GREEN;
    if (data.o >= data.c) {
      color = CANDLE_RED;
    }

    if (isNaN(volume_height)) return null

    return (
      <rect
        x={volume_x}
        y={volume_y}
        width={width}
        height={volume_height}
        fill={color}
        // key={`v-${data.relative_index}-${data.t}-${volume_x}-${volume_y}`} // Ensure unique key
        key={uuid()} // Ensure unique key
        style={{ opacity: 0.6 }}
      />
    );
  };

  const handleScroll = (e: any) => {
    if (Math.abs(e.deltaY) > Math.abs(e.deltaX)) {
      if (e.deltaY !== 0) {
        if (e.shiftKey) {
          props.zoomVertical(e.deltaY);
        } else {
          props.zoom(e.deltaY);
        }
      }
    } else {
      if (e.deltaX !== 0) {
        props.pan(e.deltaX);
      }
    }
    updateCrosshairPosition();
  };

  const handleDrag = (e: any) => {
    if (e.movementX) {
      const numCandlesVisible = props.xEndIndex - props.xStartIndex;
      const candleWidth = measurements.width / numCandlesVisible;

      // Calculate a zoom-adjusted multiplier
      const baseNumCandles = 250;
      const zoomFactor = Math.log(numCandlesVisible / baseNumCandles) / Math.log(2);
      const zoomMultiplier = Math.pow(1.5, -zoomFactor); // Adjusted base for faster movement

      // Calculate deltaX in candles, adjusted for zoom level
      let deltaXInCandles = (-e.movementX / candleWidth) * zoomMultiplier;

      // Apply a base speed multiplier
      const baseSpeedMultiplier = 5; // Adjust this value to increase/decrease overall speed
      deltaXInCandles *= baseSpeedMultiplier;

      // Ensure a minimum movement to prevent "sticking" when zoomed in
      const minMovement = 0.2; // Slightly increased for more responsiveness
      if (Math.abs(deltaXInCandles) < minMovement) {
        deltaXInCandles = Math.sign(deltaXInCandles) * minMovement;
      }

      // Apply the movement
      props.pan(deltaXInCandles);
    }

    if (e.movementY) {
      const priceRange = props.yRange || 100;
      const pricePerPixel = priceRange / measurements.height;
      let deltaYInPrice = e.movementY * pricePerPixel;
      deltaYInPrice = deltaYInPrice * 1.4;
      props.panVertical(deltaYInPrice);
    }
  };

  // END HELPERS





  /* DRAWING THE SVG CHART */
  let volume_bars: any[] = []
  let inline_indicator_point_data: any = {}   // indicator series plotted by price


  // MAKE CANDLES
  const candles_svg: any[] = []
  for (let i = 0; i < chartParams.candles.length; i++) {
    const candle = chartParams.candles[i]
    if (candle.isGap) {
      continue
    }
    candles_svg.push(
      make_candle({
        o: candle.o,
        h: candle.h,
        l: candle.l,
        c: candle.c,
        t: candle.t,
        relative_index: candle.relative_index
      }, candle_width * 0.9)
    )

    // Add volume bars
    let volumeHidden = user.charts[props.symbol]?.volumeHidden
    if (volumeHidden === undefined) volumeHidden = false
    if (!volumeHidden) {
      volume_bars.push(make_volume_bar({
        v: candle.v,
        t: candle.t,
        o: candle.o,
        c: candle.c,
        relative_index: candle.relative_index
      }, candle_width * 0.3))
    }

    // For overlay indicators, add x and y coordinates to set data for plotting
    if (candle.overlays) {
      const overlay_keys = Object.keys(candle.overlays)
      for (let i = 0; i < overlay_keys.length; i ++) {
        const overlay_key = overlay_keys[i]
        const datapoint = candle.overlays[overlay_key]

        // Simple series, like SMA, EMA, or VWAP
        if (typeof datapoint === 'number') {
          if (!inline_indicator_point_data[overlay_key]) {
            inline_indicator_point_data[overlay_key] = []
          }
          inline_indicator_point_data[overlay_key].push({
            x: get_x_for_candle_index(candle.relative_index),
            y: get_y_for_price(datapoint)
          })
        }

        // Multi-plot series, like BOLL
        else if (typeof datapoint === 'object') {
          const indicator = indicators_present[overlay_key]
          const plots = Object.keys(indicator?.plots || {})
          for (let j = 0; j < plots.length; j++) {
            const plot = plots[j]
            if (!inline_indicator_point_data[overlay_key + '_' + plot]) {
              inline_indicator_point_data[overlay_key + '_' + plot] = []
            }
            inline_indicator_point_data[overlay_key + '_' + plot].push({
              x: get_x_for_candle_index(candle.relative_index),
              y: get_y_for_price(datapoint[plot])
            })
          }
        }
      }
    }

    // Add market closed lines, if demarcateDays is specified
    if (demarcateDays && i > 0) {
      const previousCandle = chartParams.candles[i - 1];
      const gap = calculateCandleGap(candle, previousCandle);
      if (gap > 3) {
        const x = get_x_for_candle_index(candle.relative_index) - candle_width / 2;
        marketClosedLines.push(
          <line
            key={`market-closed-${i}`}
            x1={x}
            y1={0}
            x2={x}
            y2={measurements.height}
            stroke={MARKET_CLOSED_COLOR}
            // stroke={'red'}
            strokeWidth={4}
          />
        );
      }
    }

    // Oscillator plot data: assemble if applicable
    if (candle.oscillators && !candle.isFutureCandle) {
      const oscillator_keys = Object.keys(candle.oscillators)
      for (let i = 0; i < oscillator_keys.length; i ++) {
        const oscillator_key = oscillator_keys[i]
        const datapoint = candle.oscillators[oscillator_key]

        if (typeof datapoint === 'number') {
          oscillator_data[oscillator_key]?.data?.push({
            x: get_x_for_candle_index(candle.relative_index),
            val: datapoint,
            relative_index: candle.relative_index
          })

        } else if (typeof datapoint === 'object') {
          const plots = Object.keys(datapoint)
          for (let j = 0; j < plots.length; j++) {
            const plot = plots[j]
            oscillator_data[plot]?.data?.push({
              x: get_x_for_candle_index(candle.relative_index),
              val: datapoint[plot],
              relative_index: candle.relative_index
            })
          }
        }
      }
    }
  }

  const filteredIndicators = filterIndicatorData(chartParams, indicators_present);

  // SVGs plotted on the main chart
  const svgPaths: any[] = []

  const indicator_keys = Object.keys(inline_indicator_point_data)

  // Plot overlay indicators
  for (let i = 0; i < indicator_keys.length; i++) {
    const indicator_key = indicator_keys[i]
    const indicator_key_root = indicator_key.split('_')[0]
    const indicator = indicators_present[indicator_key_root]
    let plot_key = indicator_key.split('_')[1]

    if (!plot_key) {
      plot_key = Object.keys(indicator?.plots || {})[0]
    }
    let color = indicator?.plots[plot_key]?.color || 'neongreen'
    let stroke_weight = indicator?.plots[plot_key]?.weight || 2
    const plot_not_visible = indicator?.plots[plot_key]?.visible === false

    if (!indicator || indicator.isHidden || plot_not_visible) {
      continue
    }

    const points = inline_indicator_point_data[indicator_key]
    let pathData = ''
    let lastValidIndex = -1

    for (let j = 0; j < chartParams.candles.length; j++) {
      if (chartParams.candles[j].isFutureCandle) {
        break
      }
      if (points[j] && points[j].y !== undefined) {
        if (pathData === '') {
          pathData = `M ${points[j].x} ${points[j].y}`
        } else {
          pathData += ` L ${points[j].x} ${points[j].y}`
        }
        lastValidIndex = j
      }
    }

    // Trim the pathData to the last valid index
    if (lastValidIndex !== -1) {
      const pathParts = pathData.split(' ')
      pathData = pathParts.slice(0, (lastValidIndex + 1) * 3).join(' ')
    }

    if (pathData) {
      svgPaths.push(
        <path
          d={pathData}
          stroke={color}
          strokeWidth={stroke_weight}
          fill="none"
          key={indicator_key + '-' + i}
        />
      )
    }
  }



  // We need this to handle height correctly around the oscillator plot
  if (Object.keys(oscillator_data).length) {
    if (!hasOscillatorPlot) setHasOscillatorPlot(true)
  } else {
    if (hasOscillatorPlot) setHasOscillatorPlot(false)
  }

  if (chartParams.candles.length === 0) {
    return null
    // throw new Error('EMPTY SET, WE SHOULD NOT BE HERE')
  }

  // Make y axis labels
  const lower = props.yStartPrice
  const upper = props.yStartPrice + props.yRange
  const y_label_prices = get_y_label_prices(lower, upper)   // array of prices
  const y_label_data: any[] = [] // {price: number, y: number, label: string}[]
  const y_rules: any[] = []
  const y_labels: any[] = []
  for (let i = 0; i < y_label_prices.length; i++) {
    const price = y_label_prices[i]
    const y = get_y_for_price(Number(price))
    const label = formatNumber(price)
    y_label_data.push({price, y, label})

    // Create an SVG line for each
    if (props.chartMode === 'candles') {
      y_rules.push(
        <line
          x1={0}
          y1={y}
          x2={measurements.width}
          y2={y}
          stroke="#20242f"
          strokeWidth="1"
          key={'y-rule-' + i}
        />
      )
    }

    // Add a label for each except the last
    y_labels.push(
      <div
        className='label'
        style={{top: y - 8}}
        key={'y-label-' + i}
      >
        {label}
      </div>
    )
  }


// Add x labels
const x_labels: any[] = []
const x_rules: any[] = []
for (let i = 0; i < chartParams.xLabels.length; i++) {
  const timestamp = new Date(chartParams.xLabels[i].timestamp).getTime()
  // const bold = x_label_data[i].bold
  const bold = false
  const candleIndex = get_candle_index_for_timestamp(timestamp)
  const x = get_x_for_candle_index(candleIndex)

  // Create a label for each
  if (i === 0) continue
  x_labels.push(
    <div
      className={`label ${bold ? 'bold' : ''}`}
      style={{ left: x - 30 }}
      key={'x-label-' + i}
    >
      {chartParams.xLabels[i].label}
    </div>
  )

  // Create an SVG line for each
  if (props.chartMode === 'candles') {
    x_rules.push(
      <line
        x1={x}
        y1={0}
        x2={x}
        y2={measurements.height}
        stroke="#20242f"
        strokeWidth="1"
        key={'x-rule-' + i}
      />
    )
  }
}

  // Make current price indicator
  const current_price = chartParams.lastCandlePrice
  const current_price_y = get_y_for_price(current_price)
  const current_price_formatted = formatNumber(current_price)

  let current_price_label: any = (
    <div
      className={!chartParams.lastCandleIsRed ? 'current-price' : 'current-price negative'}
      style={{top: current_price_y,}}
    >
      <div className={'cp-label'}>
        <div className={'cp-line'} style={{
          width: measurements.width,
          left: -measurements.width,
          }}/>

        {current_price_formatted}
      </div>
    </div>
  )
  if (current_price_y < 0) {
    current_price_label = null
  } else if (current_price_y > measurements.height) {
    current_price_label = null
  }

  // Make space for indicator chart if necessary
  const op_toggled_shut = user.charts[props.symbol]?.oscillatorsHidden
  const have_oscillators = !!oscillatorIndicator
  const oscillator_plot_visible = have_oscillators && !op_toggled_shut
  let chartInnerClassName = 'chart-inner'
  if (oscillator_plot_visible) {
    chartInnerClassName += ' has-oscillator-chart'
  }

  // Format oscillator data as an array
  const oscillator_series_data_array = Object.keys(oscillator_data).map(key => oscillator_data[key])

  return (
    <div className='chart-wrapper'>
      <div
        className={chartInnerClassName}
        ref={chartRef}
        onWheel={handleScroll}
        onMouseDown={() => {setIsDragging(true)}}
        onMouseUp={() => {setIsDragging(false)}}
        onMouseMove={(e) => {if (isDragging) handleDrag(e)}}
      >
        {/* Crosshairs */}
        <ChartOverlay
          ref={chartOverlayRef}
          symbol={props.symbol}
          interval={props.interval}
          get_price_for_y={get_price_for_y}
          snap_tos={snap_tos}
          data={chartParams.candles}
          get_candle_index_for_timestamp={get_candle_index_for_timestamp}
          get_x_for_candle_index={get_x_for_candle_index}
          get_y_for_price={get_y_for_price}
          width={measurements.width}
          height={measurements.height}
          stepBack={props.stepBack}
          setStepBack={props.setStepBack}
          chartMode={props.chartMode}
          futureCandlesCount={chartParams.futureCandlesCount}
        />

        {/* Chart proper */}
        <svg width={measurements.width} height={measurements.height}>
          {y_rules}
          {x_rules}

          {/* Line at y=0 if isInternal is true */}
          {props.isInternal && (
            <line
              x1={0}
              y1={get_y_for_price(0)}
              x2={measurements.width}
              y2={get_y_for_price(0)}
              stroke={'#444444'}
              // stroke={ORANGE}
              strokeWidth="1"
              // strokeDasharray="5,5"
            />
          )}

          {props.chartMode === 'candles' && chartParams.candles.map((candle, index) => {
            if (candle.isFutureCandle) {
              // Render a thin line for future candles
              const x = get_x_for_candle_index(candle.relative_index);
              return (
                <line
                  key={`future-candle-${candle.relative_index}`}
                  x1={x}
                  y1={get_y_for_price(candle.c)}
                  x2={x}
                  y2={get_y_for_price(candle.c) + 1}
                  stroke="#333"
                  strokeWidth={1}
                />
              );
            } else {

              // Render normal candles
              return make_candle(candle, candle_width * 0.9);
            }
          })}
          {props.chartMode === 'line' ?
            <>
              <path d={createFillPath()} fill="#FF8F0E11" />
              <path d={createLinePath()} stroke="#FF8F0E" strokeWidth="2" fill="none" />
            </>
            : null}
          {volume_bars}
          {svgPaths}
          {demarcateDays ? marketClosedLines : null}
        </svg>

        {current_price_label}
      </div>

      {oscillator_plot_visible ? <OscillatorPlotTabBar
        symbol={props.symbol}
        user={user}
      /> : null}

      {/* Oscillator plot */}
      {oscillator_plot_visible ? (
        <div style={{display: 'flex', flexDirection: 'column', height: 120}}>
          <OscillatorPlot
            title={oscillator_title}
            width={measurements.width}
            series_data={oscillator_series_data_array}
            // plot_parameters={oscillator_plot_parameters}
            indicator={oscillatorIndicator}
            symbol={props.symbol}
          />
        </div>
      ) : null}

      {/* X axis labels */}
      <div className='x-axis-labels'>
        {x_labels}
      </div>

      {/* Y axis labels */}
      <div
        className='y-axis-labels'
        onMouseDown={() => {setIsDragging(true)}}
        onMouseUp={() => {setIsDragging(false)}}
        onMouseMove={(e) => {
          if (isDragging) {
            props.zoomVertical(e.movementY)
          }
        }}
      >
        {y_labels}
      </div>

      {/* Start of data */}
      <div
        ref={startOfDataLabelRef}
        style={{
          position: 'absolute',
          bottom: oscillator_plot_visible ? 140 : 20,
          left: `${get_x_for_candle_index(props.bars[0]?.relative_index)}px`,
          border: '1px solid #FF8F0E',
          color: '#FF8F0E',
          backgroundColor: '#0D0A0A',
          padding: '5px 10px',
          borderRadius: 3,
          fontSize: '12px',
          zIndex: 1,
          pointerEvents: 'none',
          whiteSpace: 'nowrap',
          transform: 'translateX(-50%)',
          opacity: endOfDataVisible ? 1 : 0,  // Hide it when there's not enough space
          transition: 'opacity 0.3s ease',
        }}
      >
        Start of Data
      </div>

    </div>
  )
}


// Output format is [{o, h, l, c, t: "2024-07-09T13:00:00Z"}]


function filterIndicatorData(chartParams, indicators) {
  const lastActualCandleIndex = chartParams.candles.findIndex(candle => candle.isFutureCandle) - 1;
  const lastActualTimestamp = lastActualCandleIndex >= 0 ? chartParams.candles[lastActualCandleIndex].t : null;

  Object.keys(indicators).forEach(indicatorKey => {
    if (indicators[indicatorKey].data) {
      indicators[indicatorKey].data = indicators[indicatorKey].data.filter(point => {
        const pointTimestamp = new Date(point.timestamp).getTime();
        return pointTimestamp <= new Date(lastActualTimestamp).getTime();
      });
    }
  });

  return indicators;
}

const getCandleTimestamp = (timestamp: string, interval: string) => {
  const date = new Date(timestamp);
  switch(interval) {
    case '1m':
      return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()).getTime();
    case '5m':
      return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), Math.floor(date.getMinutes() / 5) * 5).getTime();
    case '15m':
      return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), Math.floor(date.getMinutes() / 15) * 15).getTime();
    case '1h':
      return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours()).getTime();
    default:
      return date.getTime();
  }
}