import React, { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import {
  DataGridPro,
  GridSortModel,
  GridSortDirection,
  GridColumns,
} from '@mui/x-data-grid-pro';
import {
  tableSX,
  formatDateForTable,
  valueForTable,
  uuid,
  tableSX_Modal,
} from '../../logic/u';
import { db } from '../../firebase';
import {
  loggedInUserAtom,
  openModalAtom,
} from '../../types/global_types';
import { LogRow } from '../reusable/LogRow';
import { CustomGridOverlay } from '../reusable/CustomGridOverlay';
import { LogModal } from '../modals/LogModal';
import { TableDropdown } from '../dropdowns/TableDropdown';
import { Icon } from '../reusable';
import firebase from 'firebase/app';
import 'firebase/firestore';

interface LogsTableProps {
  backtest?: any | null;
  width?: string;
  height?: string;
  paneIndex?: number;
}

export const LogsTable: React.FC<LogsTableProps> = (props) => {
  const [user] = useAtom(loggedInUserAtom);
  const [logs, setLogs] = useState<any[]>([]);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [, setOpenModal] = useAtom(openModalAtom);
  const [openDropdown, setOpenDropdown] = useState<React.ReactNode>(null);
  const [totalLogs, setTotalLogs] = useState(0);

  const defaultSortField = props.backtest ? 'time_iso' : 'time';

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: defaultSortField,
      sort: 'desc' as GridSortDirection,
    },
  ]);

  const [pageSize] = useState(100);
  const [pageCursors, setPageCursors] = useState<
    firebase.firestore.QueryDocumentSnapshot[]
  >([]);
  const [currentPage, setCurrentPage] = useState(0);

  // Fetch total logs count
  useEffect(() => {
    if (!user) return;
    if (props.backtest) {
      const backtestId = props.backtest.id;
      db.collection('users')
        .doc(user.uid)
        .collection('backtests')
        .doc(backtestId)
        .collection('BacktestLogs')
        .get()
        .then((snapshot) => {
          setTotalLogs(snapshot.size);
        })
        .catch((error) => {
          console.error('Error getting total logs count:', error);
        });
    } else {
      db.collection('users')
        .doc(user.uid)
        .collection('EELogs')
        .get()
        .then((snapshot) => {
          setTotalLogs(snapshot.size);
        })
        .catch((error) => {
          console.error('Error getting total logs count:', error);
        });
    }
  }, [user, props.backtest]);

  // Fetch logs based on pagination and sorting
  useEffect(() => {
    if (!user) return;
    setIsInitialLoading(true);

    let query: firebase.firestore.Query;

    if (props.backtest) {
      const backtestId = props.backtest.id;
      query = db
        .collection('users')
        .doc(user.uid)
        .collection('backtests')
        .doc(backtestId)
        .collection('BacktestLogs');
    } else {
      query = db
        .collection('users')
        .doc(user.uid)
        .collection('EELogs');
    }

    // Apply sorting
    if (sortModel.length > 0) {
      sortModel.forEach((sortOption) => {
        if (sortOption.sort) {
          query = query.orderBy(sortOption.field, sortOption.sort);
        }
      });
    } else {
      query = query.orderBy(defaultSortField, 'desc');
    }

    // Apply pagination only if not in backtest mode
    if (!props.backtest) {
      // Apply cursor-based pagination
      if (currentPage > 0 && pageCursors[currentPage - 1]) {
        query = query.startAfter(pageCursors[currentPage - 1]);
      }

      query = query.limit(pageSize);
    }

    query
      .get()
      .then((snapshot) => {
        const fetchedLogs = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log('Fetched logs:', fetchedLogs);

        const processedLogs = fetchedLogs.map((log: any) => ({
          ...log,
          date: log.time ? new Date(log.time) : new Date(log.time_iso),
          id: log.id || uuid(),
        }));
        console.log('Processed logs:', processedLogs);

        setLogs(processedLogs);
        setIsInitialLoading(false);

        if (!props.backtest && snapshot.docs.length > 0) {
          const newPageCursors = [...pageCursors];
          newPageCursors[currentPage] = snapshot.docs[snapshot.docs.length - 1];
          setPageCursors(newPageCursors);
        }
      })
      .catch((error) => {
        console.error('Error fetching logs:', error);
        setIsInitialLoading(false);
      });
  }, [user, props.backtest, currentPage, pageSize, sortModel]);

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
    setCurrentPage(0);
    setPageCursors([]);
  };

  const handleNextPage = () => {
    if ((currentPage + 1) * pageSize < totalLogs) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  if (!user) return null;

  const logRows = logs || [];

  const forModal = !!props.backtest
  let parentClassName = 'table-tab'
  let tableClassName = 'table-container'
  if (forModal) {
    parentClassName += ' for-modal'
    tableClassName += ' for-modal'
  }

  const columns: GridColumns = [
    ...(props.backtest
      ? []
      : [
          {
            field: 'deployment_id',
            headerName: 'Deployment ID',
            width: 155,
            type: 'string',
            sortable: true,
            valueGetter: () => '-',
          },
        ]),
    {
      field: 'date',
      headerName: 'Date',
      width: 155,
      type: 'date',
      sortable: true,
      valueGetter: (params) => {
        const d = new Date(params.row.time_iso);
        return formatDateForTable(d)
        // return formatDateForTable(params.row.date)
      },
    },
    {
      field: 'message',
      headerName: 'Message',
      width: !props.backtest ? 500 : undefined,
      flex: props.backtest ? 1 : undefined,
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      sortable: false,
      renderCell: (params) => <LogRow log={params.row} forTable={true} forModal={true} />,
    },
    {
      field: 'equity',
      headerName: 'Equity',
      width: 120,
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      sortable: true,
      valueGetter: (params) => valueForTable('equity', params.row.equity),
    },
    {
      field: 'pl',
      headerName: 'P/L',
      width: 120,
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      sortable: true,
      valueGetter: (params) => valueForTable('pl', params.row.pl),
    },
    {
      field: 'pl_pc',
      headerName: 'P/L (%)',
      width: 120,
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      sortable: true,
      valueGetter: (params) => valueForTable('pl_pc', params.row.pl_pc),
    },
  ];

  return (
    <div
      className={parentClassName}
      style={{ width: props.width, height: props.height, minHeight: 0, position: 'relative' }}
    >
      {openDropdown}
      {!forModal && (
        <div className="tab-header">
          <div>Logs table</div>
          <Icon
            icon="ellipsis"
            set="sharp-solid"
            size={14}
            style={{ marginLeft: 12, marginTop: 0.5 }}
            onClick={(e) => {
              const rect = (e.currentTarget as HTMLElement).getBoundingClientRect();
              if (rect) {
                const width = 175;
                const left = rect.left - width + 10;
                const top = rect.bottom;
                setOpenDropdown(
                  <TableDropdown
                    left={left}
                    top={top}
                    width={width}
                    onClose={() => setOpenDropdown(null)}
                    paneIndex={props.paneIndex}
                    table="logs"
                  />
                );
              }
            }}
          />
        </div>
      )}
      <div className={tableClassName}>
        <DataGridPro
          className="clickable-grid"
          rows={logRows}
          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 36}
          columns={columns}
          disableColumnResize
          disableColumnMenu
          disableColumnPinning
          disableSelectionOnClick
          density="compact"
          pagination={!props.backtest}
          paginationMode={!props.backtest ? 'server' : undefined}
          page={!props.backtest ? currentPage : undefined}
          onPageChange={
            !props.backtest
              ? (newPage) => {
                  if (newPage > currentPage) {
                    handleNextPage();
                  } else {
                    handlePrevPage();
                  }
                }
              : undefined
          }
          pageSize={!props.backtest ? pageSize : undefined}
          rowsPerPageOptions={!props.backtest ? [pageSize] : undefined}
          rowCount={!props.backtest ? totalLogs : undefined}
          components={{
            NoRowsOverlay: () => (
              <CustomGridOverlay text={isInitialLoading ? 'Loading logs...' : 'No logs.'} />
            ),
          }}
          sx={{
            ...tableSX,
            ...(forModal ? tableSX_Modal : null),
          }}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          onRowClick={(params) => {
            setOpenModal(<LogModal log={params.row} backtest={props.backtest} />);
          }}
        />
      </div>
    </div>
  );
};
