import { useAtom } from 'jotai'
import {
  loggedInUserAtom,
  openModalAtom,
  ordersAtom,
  transfersAtom,
  userLiveDocAtom,
} from '../../types/global_types'
import {
  Icon,
  DropdownMenu,
} from '../reusable'
import { updateLiveData } from '../../logic/u'
import React, { useState, useEffect } from 'react'
import { CloseAllPositionsModal } from '../modals/CloseAllPositionsModal'
import { CancelAllOpenOrdersModal } from '../modals/CancelAllOpenOrdersModal'
import { CancelPendingTransferModal } from '../modals/CancelPendingTransferModal'
import { CancelAllPendingTransfersModal } from '../modals/CancelAllPendingTransfersModal'

interface RightPaneDropdownProps {
  left: number
  top: number
  width: number
  onClose: () => void
  pane?: string
}

export const RightPaneDropdown = (props: RightPaneDropdownProps) => {
  const [user ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const {left, top, width, onClose, pane} = props
  const [orders] = useAtom(ordersAtom)
  const [uld] = useAtom(userLiveDocAtom)
  const [transfers] = useAtom(transfersAtom)

  if (!user || !uld) return null

  const hasPositions = !!Object.values(uld?.liveData?.POSITIONS || {})?.length
  const hasOpenOrders = orders?.working?.length
  const hasPendingTransfers = transfers?.filter(t => ['QUEUED', 'APPROVAL_PENDING', 'PENDING'].includes(t.status)).length

  interface DropdownItem {
    display: JSX.Element
    isDisabled?: boolean
    onClick?: () => void
  }
  let contents: DropdownItem[] = []

  if (pane === 'positions') {
    contents.push(
      {
        display: (
          <div className='left'>
            <Icon
              icon='folder-closed'
              set='sharp-solid'
              size={9}
              style={{marginRight: 10}}
              className={!hasPositions ? 'menu-item-icon-disabled' : ''}
            />
            <div>Close all positions</div>
          </div>
        ),
        isDisabled: !hasPositions,
        onClick: () => {
          setOpenModal(<CloseAllPositionsModal />)
        }
      },
    )
  } else {
    contents.push(
      {
        display: (
          <div className='left'>
            <Icon
              icon='octagon-minus'
              set='sharp-solid'
              size={9}
              style={{marginRight: 10}}
              className={!hasOpenOrders ? 'menu-item-icon-disabled' : ''}
            />
            <div>Cancel open orders</div>
          </div>
        ),
        isDisabled: !hasOpenOrders,
        onClick: () => {
          setOpenModal(<CancelAllOpenOrdersModal />)
        }
      },
      {
        display: (
          <div className='left'>
            <Icon
              icon='octagon-minus'
              set='sharp-solid'
              size={9}
              style={{marginRight: 10}}
              className={!hasPendingTransfers ? 'menu-item-icon-disabled' : ''}

            />
            <div>Cancel pending transfers</div>
          </div>
        ),
        isDisabled: !hasPendingTransfers,
        onClick: () => {
          setOpenModal(<CancelAllPendingTransfersModal />)
        }
      },
      {
        display: (
          <div className='left'>
            <Icon
              icon='arrows-rotate'
              set='sharp-solid'
              size={9}
              style={{marginRight: 10}}
            />
            <div>Refresh</div>
          </div>
        ),
        onClick: () => {
          updateLiveData(user)
        }
      },
    )
    // the case of orders/transfers pane
  }

  return (
    <DropdownMenu
      //@ts-ignore
      posLeft={left}
      posTop={top}
      width={width}
      height={500}
      onClose={() => onClose()}
      noArrowKeys={true}
      contents={contents}
    />
  )
}