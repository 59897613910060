/*
Button-shaped button
*/

import './Button.scss'
import React, { useState, useEffect } from 'react'
import { Icon } from './Icon'

export interface ButtonProps {
  display: string
  onClick: (e: any) => void
  className?: string
  value?: any
  variant?: 'primary' | 'secondary' | 'danger' | 'success' | 'outline'
  style?: any
  isDisabled?: boolean
  isLoading?: boolean
  twoStepConfirmation?: boolean
  tabIndex?: number
}

export function Button(props: ButtonProps) {
  const [display] = useState<string>(props.display)
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(props.isDisabled || false)

  useEffect(() => {
    setIsButtonDisabled(props.isDisabled || false)
  }, [props.isDisabled])

  let classList = 'button'
  if (props.variant === 'secondary') {
    classList += ' secondary'
  } else if (props.variant === 'danger') {
    classList += ' danger'
  } else if (props.variant === 'success') {
    classList += ' success'
  } else if (props.variant === 'outline') {
    classList += ' outline'
  }

  if (props.className) {
    classList += ' ' + props.className
  }

  if (isButtonDisabled) {
    classList += ' disabled'
  }

  return (
    <button
      className={classList}
      style={props.style}
      disabled={isButtonDisabled}
      tabIndex={props.tabIndex || 0}
      onClick={() => {
        if (isButtonDisabled) {
          return
        }
        props.onClick(props.value)
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {!props.isLoading ? display : 
          <Icon
            icon='spinner'
            set='sharp-solid'
            size={18}
            spin={props.isLoading}
          />
        }
      </div>
    </button>
  )
}
