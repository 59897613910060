import { useAtom } from 'jotai'
import React, { useRef, useState } from 'react'
import { Checkbox, Icon, Modal } from '../reusable'
import {
  openModalAtom,
  userLiveDocAtom,
  loggedInUserAtom,
} from '../../types/global_types'
import { ErrorModal } from './ErrorModal'
import axios from 'axios'
import { BORDER_INPUT } from '../../logic/u'
import { onboardingChecklistSteps } from '../../types/user_types'
import { db } from '../../firebase'

export const OnboardingChecklistModal = () => {
  const [ user ] = useAtom(loggedInUserAtom)
  const [ uld ] = useAtom(userLiveDocAtom)
  const [ , setOpenModal] = useAtom(openModalAtom)
  const [ onboardingChecklistChexboxVal, setOnboardingChecklistCheckboxVal] = useState<boolean>(user ? user.preferences?.hideOnboardingChecklist : false)

  if (!user || !uld) return null

  return (
    <Modal
      title={`Onboarding checklist`}
      contents={[
        <div className='column width-full font-size-13 orange-text'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: 225,
              overflowY: 'scroll',
              border: `1px solid ${BORDER_INPUT}`,
              borderRadius: 3,
              padding: 8
            }}
          >
            {onboardingChecklistSteps.map((step, i) => {
              let icon = 'circle-' + (i + 1)
              let iconClass = 'lp-icon'
              let className = 'row bold'              
              if (user.onboardingChecklist) {
                if (user.onboardingChecklist[step.value]) {
                  icon = 'badge-check'
                  className += ' gray-text'
                  iconClass = 'lp-icon-complete'
                } else {
                  className += ' white-text'
                }
              }
              return <div className='row space-between' style={{marginTop: i === 0 ? 0 : 10}}>
                <div className={className}>
                  <Icon 
                    icon={icon}
                    set='sharp-solid'
                    size={13}
                    className={iconClass}
                  />
                  <div
                    style={{
                      marginLeft: 7,
                      fontSize: 11
                    }}
                    className='add-ellipses-cutoff'
                  >
                    {step.display}
                  </div>
                </div>
              </div>
            })}
          </div>
          {<div className='row' style={{marginBottom: -10, marginTop: 15}}>
            <Checkbox 
              value={onboardingChecklistChexboxVal}
              onChange={(val) => {
                setOnboardingChecklistCheckboxVal(val)                
              }}
              checkMarginTop={-2}
            />
            <div 
              className='font-size-11 bold white-text margin-left-5 pointer'
              onClick={() => {
                setOnboardingChecklistCheckboxVal(!onboardingChecklistChexboxVal)                
              }}
            >
              Don't show me this again
            </div>
          </div>}
        </div>
      ]}
      yesButtonText={'Close'}
      onYes={async () => {
        setOpenModal(null)
        if (onboardingChecklistChexboxVal) {
          db.collection('users').doc(user.uid).update({ preferences: { hideOnboardingChecklist: true }})
        }
      }}
    />
  )
}
