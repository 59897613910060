import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faChevronDown, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { DropdownMenu } from './DropdownMenu';
import './DropdownSelect.scss';

interface DropdownSelectOption {
  display: string;
  display_control?: React.ReactNode;
  value: any;
  onClick?: () => void;
  noSelect?: boolean;
  isDisabled?: boolean;
  buttons?: any[];
}

interface DropdownSelectProps {
  options: DropdownSelectOption[];
  onChange?: (val: any) => void;
  className?: string;
  isAutocomplete?: boolean;
  variant?: 'default' | 'compact' | 'ce-ds' | 'borderless';
  label?: string | React.ReactNode;
  placeholder?: string;
  value?: any;
  nullValue?: any;
  hoverText?: string;
  errorText?: string;
  width?: number | 'auto';
  height?: number;
  menuHeight?: number;
  style?: React.CSSProperties;
  noLabel?: boolean;
  isDriven?: boolean;
  chevronMarginTop?: number;
  fontSize?: number;
  dropDownMenuWidth?: number;
  top?: number;
  getValue?: (val: any) => any;
  noArrowKeys?: boolean;
  autoFocus?: boolean;
}

export const DropdownSelect = ({
  options = [],
  onChange,
  className = '',
  isAutocomplete = false,
  variant = 'default',
  label,
  value,
  nullValue,
  hoverText,
  errorText,
  width = 200,
  height,
  menuHeight = 220,
  style,
  noLabel = false,
  isDriven = false,
  chevronMarginTop,
  fontSize,
  dropDownMenuWidth,
  noArrowKeys = false,
  autoFocus = false
}: DropdownSelectProps) => {
  // Find initial selected option
  const initialOption = options.find(opt => opt.value === value);
  const initialDisplay = initialOption ?
    (initialOption.display_control || initialOption.display) :
    nullValue;

  const [display, setDisplay] = useState(initialDisplay);
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState<{ left: number; top: number } | null>(null);
  const controlRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<InstanceType<typeof DropdownMenu>>(null);
  const labelContent: JSX.Element[] = [];

  const dropdownWidth = dropDownMenuWidth || (width !== 'auto' ? width + 4 : 200);

  // Calculate and update dropdown position when opening
  const updateDropdownPosition = () => {
    if (controlRef.current) {
      const rect = controlRef.current.getBoundingClientRect();
      setDropdownPosition({
        left: rect.left,
        top: rect.top + (variant === 'borderless' ? 22 : 36)
      });
    }
  };

  // Handle opening the dropdown
  const handleOpen = () => {
    updateDropdownPosition();
    setIsOpen(true);
  };

  // Focus management
  useEffect(() => {
    if (autoFocus && controlRef.current) {
      controlRef.current.focus();
    }
  }, [autoFocus]);

  const handleSelect = (option: DropdownSelectOption) => {
    const displayValue = option.display_control || option.display;
    setDisplay(displayValue);
    setIsOpen(false);
    setDropdownPosition(null);
    if (onChange) {
      onChange(option.value);
    }
    controlRef.current?.blur();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    // Open dropdown for arrow down or single letter/number keys
    if (!isOpen && (e.key === 'ArrowDown' || (e.key.length === 1 && /[a-zA-Z0-9]/.test(e.key)))) {
      e.preventDefault();
      handleOpen();

      // If it's a letter/number key, also trigger filtering
      if (e.key.length === 1) {
        if (dropdownRef.current) {
          dropdownRef.current.filterOptions(e.key);
        }
      }
      return;
    }

    if (isOpen && dropdownRef.current) {
      if (['ArrowUp', 'ArrowDown', 'Enter', 'Escape', 'Tab'].includes(e.key)) {
        e.preventDefault();

        if (e.key === 'Escape') {
          setIsOpen(false);
          setDropdownPosition(null);
          return;
        }

        dropdownRef.current.onKeyDown?.(e);
      } else if (e.key.length === 1 && /[a-zA-Z0-9]/.test(e.key)) {
        // Handle typing when menu is already open
        if (dropdownRef.current) {
          dropdownRef.current.filterOptions(e.key);
        }
      }
    }
  };

  const handleAutocompleteInput = (e: React.FormEvent<HTMLDivElement>) => {
    if (dropdownRef.current) {
      dropdownRef.current.filterOptions((e.target as HTMLDivElement).innerText);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setDropdownPosition(null);
  };

  // Render label section if needed
  const renderLabel = () => {
    if (noLabel) return null;

    if (label) {
      const labelClass = `label ${variant === 'compact' ? 'compact' : ''}`;
      labelContent.push(
        <div key="label" className={labelClass}>{label}</div>
      );

      if (hoverText) {
        labelContent.push(
          <FontAwesomeIcon
            key="info"
            className="info-icon"
            icon={faInfoCircle}
            title={hoverText}
            style={{ marginTop: 1 }}
          />
        );
      }

      if (errorText) {
        labelContent.push(
          <FontAwesomeIcon
            key="error"
            className="error-icon"
            icon={faExclamationCircle}
            title={errorText}
            style={{ marginTop: 1 }}
          />
        );
      }
    }

    return labelContent.length ? (
      <div className="label-row">{labelContent}</div>
    ) : null;
  };

  const dropdownControlClass = [
    'dropdown-control',
    isOpen && 'menu-open',
    variant === 'ce-ds' ? 'ce-ds' : '',
    variant === 'borderless' ? 'borderless' : ''
  ].filter(Boolean).join(' ');

  return (
    <div className={`dropdown-select ${className}`} style={style}>
      {renderLabel()}
      <div
        ref={controlRef}
        className={dropdownControlClass}
        tabIndex={0}
        style={{
          width,
          height,
          fontSize: fontSize || undefined
        }}
        onClick={() => isOpen ? handleClose() : handleOpen()}
        onKeyDown={handleKeyDown}
      >
        <div
          className="dd-ctrl-inner"
          contentEditable={isAutocomplete}
          suppressContentEditableWarning={true}
          style={{ marginRight: 7 }}
          onInput={handleAutocompleteInput}
        >
          <div>
            {isDriven ? initialDisplay : (display || initialDisplay)}
          </div>

          {variant !== 'ce-ds' && (
            <FontAwesomeIcon
              className="dropdown-btn"
              style={{
                marginTop: chevronMarginTop || value ? 4 : 1,
              }}
              icon={faChevronDown}
            />
          )}
        </div>
      </div>

      {isOpen && dropdownPosition && (
        <DropdownMenu
          ref={dropdownRef}
          contents={options.map(opt => ({
            ...opt,
            onClick: () => handleSelect(opt)
          }))}
          onSelect={handleSelect}
          posLeft={dropdownPosition.left}
          posTop={dropdownPosition.top}
          width={dropdownWidth}
          height={menuHeight}
          onClose={handleClose}
          noArrowKeys={noArrowKeys}
        />
      )}
    </div>
  );
};