import { useAtom } from 'jotai'
import {
  loggedInUserAtom,
  openModalAtom,
} from '../../types/global_types'
import {
  Icon,
  DropdownMenu,
} from '../reusable'
import React, { useState, useEffect } from 'react'

interface TickersListSortDropdownProps {
  sortKey: string
  setSortKey: (sv: string) => void
  left: number
  top: number
  width: number
  onClose: () => void
}

export const TickersListSortDropdown = (props: TickersListSortDropdownProps) => {
  const [user ] = useAtom(loggedInUserAtom)
  const [, setOpenModal] = useAtom(openModalAtom)
  const {sortKey, setSortKey, left, top, width, onClose} = props

  if (!user) return null

  const sortOptions = [
    'price',
    'day_price_change',
    'day_price_change_pc',    
  ]

  interface DropdownItem {
    display: JSX.Element
    isDisabled?: boolean
    onClick?: () => void
  }
  let contents: DropdownItem[] = []
  contents.push(
    {
      display: (
        <div className='font-size-10 gray-text'>
          {'Sort positions'}
        </div>
      )
    },
  )
  sortOptions?.forEach(sKey => {    

    contents.push(
      {
        display: (
          <div className='left space-between'>
            <div className='row width-full'>
              <div style={{width: 15, marginRight: 15}}>
                {sKey === sortKey ? <Icon
                  icon={'check'}
                  set='sharp-solid'
                  size={13}
                  style={{marginRight: 20, marginTop: 1.5}}
                  className={''}
                /> : null}
              </div>
              <div style={{width: 215}} className='add-ellipses-cutoff'>{sKey}</div>
            </div>            
          </div>
        ),
        onClick: async () => {
          setSortKey(sKey)
        }
      },
    )
  })
  
  return (
    <DropdownMenu
      //@ts-ignore
      posLeft={left}
      posTop={top}
      width={width}
      height={500}
      onClose={() => onClose()}
      noArrowKeys={true}
      contents={contents}
    />
  )
}